import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../Registration/Register.css";
import FormGroup from "../../Components/Common/FormGroup";
import InputField from "../../Components/Common/InputField";
import PasswordInput from "../../Components/Common/FormGroup/PasswordInput";
import PhoneInput from "../../Components/Common/PhoneInput";
import { useFormikContext } from "formik";
import SelectField from "../../Components/Common/AutoComplete";
import ApiService from "../../Frontend/Api/ApiServices";
import AlreadyMembership from "./AlreadyMembership";
import SuccessPage from "./SuccessPage";
import swal from "sweetalert";
const numbersOfMembersOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
];

const priceOfMembersOptions = [
  { label: "1", value: "40" },
  { label: "2", value: "70" },
  { label: "3", value: "100" },
  { label: "4", value: "130" },
  { label: "5", value: "160" },
  { label: "6", value: "190" },
  { label: "7", value: "220" },
  { label: "8", value: "250" },
  { label: "9", value: "280" },
  { label: "10", value: "310" },
];

function NewAccountRegister({ setValueinFormData, setInitialValue, onNext }) {
  const [couponCodeDetails, setCouponCodeDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const {
    setFieldValue,
    values,
    getFieldProps,
    errors,
    setFieldTouched,
    setFieldError,
    validateForm,
    setTouched,
    touched,
  } = useFormikContext();

  const paste = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setValueinFormData();
  }, [values]);

  const onBlurhandlerPromocode = async () => {
    try {
      if (values?.referralCode) {
        const { data } = await ApiService.checkCouponCode(values?.referralCode);
        console.log("data: ", data);
        if (data?.code) {
          setCouponCodeDetails(data);
        } else {
          setCouponCodeDetails({ error: "Invalid coupon code" });
        }
      }
    } catch (error) {
      console.log("error: ", error);
      setCouponCodeDetails({ error: "Invalid coupon code" });
    }
  };

  const handleBuyNewMembership = async (e, isNewMember) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
  
    try {
      // Validate form fields before proceeding
      const errors = await validateForm(); 
      setTouched(
        Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {})
      );
  
      // If errors exist, stop execution and show errors
      if (Object.keys(errors).length > 0) {
        console.log("Validation errors:", errors);
        setIsLoading(false);
        return;
      }
  
      // Check if email already exists
      const payload = { email: values.email };
      const { data } = await ApiService.checkEmailAlreadyExits(payload);
  
      if (data.err === "Email already taken") {
        setFieldError("email", data.err);
        setIsLoading(false);
        return;
      }
  
      // Update form state based on user action
      if (isNewMember) {
        setInitialValue({
          ...values,
          isBuyMembership: true,
          isAlreadyMembership: false,
        });
      } else {
        setInitialValue({
          ...values,
          isBuyMembership: false,
          isAlreadyMembership: true,
        });
      }
  
      setIsLoading(false);
      window.scrollTo({ top: 600, behavior: "smooth" });
  
    } catch (error) {
      console.error("Error: ", error);
      swal("Network Error!", error?.message || "Something went wrong!", "error");
      setIsLoading(false);
    }
  };
  
  return (
    <>
      {values?.isAlreadyMemSubmitted ? (
        <SuccessPage />
      ) : (
        <div className="container" style={{ gridTemplateColumns: "90%" }}>
          <div id="home-video-main">
            <h1>New Account Registration</h1>
            <Main>
              <Body>
                <Column>
                  <FormGroup
                    label="First Name"
                    name="firstname"
                    requiredField
                    labelClassName="margin-top"
                  >
                    <InputField
                      type="text"
                      name="firstname"
                      {...getFieldProps("firstname")}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    label="Last Name"
                    name="lastname"
                    requiredField
                    labelClassName="margin-top"
                  >
                    <InputField
                      type="text"
                      name="lastname"
                      {...getFieldProps("lastname")}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    label="Email Address"
                    name="email"
                    requiredField
                    labelClassName="margin-top"
                  >
                    <InputField
                      type="text"
                      name="email"
                      className="textarea"
                      {...getFieldProps("email")}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    label="Phone Number"
                    name="phoneNumber"
                    labelClassName="margin-top"
                  >
                    <Country>
                      <PhoneInput
                        dialCodeName="phone_code"
                        name="phoneNumber"
                        onChange={(value, country) => {
                          setFieldValue("phone_code", country.dialCode);
                        }}
                      />
                    </Country>
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    label="Password"
                    name="password"
                    requiredField
                    labelClassName="margin-top"
                  >
                    <PasswordInput {...getFieldProps("password")} />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    label="Confirm Password"
                    name="confirmpassword"
                    requiredField
                    labelClassName="margin-top"
                  >
                    <PasswordInput
                      {...getFieldProps("confirmpassword")}
                      onPaste={paste}
                      onDrop={paste}
                    />
                  </FormGroup>
                </Column>
              </Body>
              <div className="btn-footer">
                <button
                  type="button"
                  className="btn12"
                  onClick={(e) => handleBuyNewMembership(e, true)}
                >
                  {isLoading ? <Loader /> : "Buy New FootprintID® Membership(s)"}
                </button>
                <button
                  type="button"
                  className="btn12"
                  onClick={(e) => handleBuyNewMembership(e, false)}
                >
                  Register Your Account
                  <div style={{ fontSize: "10px", fontWeight: 600 }}>
                    If You Have Already Received Your FootprintID® Membership
                    Kit(s)
                  </div>
                </button>
              </div>
            </Main>
          </div>
          {values?.isBuyMembership && (
            <Main style={{ marginTop: 20 }}>
              <div className="panel panel-default member-ship-block">
                <div className="panel-heading">
                  <h3 className="panel-title ng-binding">Membership</h3>
                </div>
                <div className="panel-body">
                  {/* <form className="register-membership ng-valid ng-dirty ng-valid-parse"> */}
                  <div className="inner-container" ng-show="tagsMode === 'buy'">
                    <div className="row">
                      <div className="col-sm-12">
                        <p className="ng-binding">
                          Please select the number of memberships you would like
                          to purchase.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="control-label ng-binding"
                            htmlFor="members"
                          >
                            Please Select # of Members You Would Like:
                          </label>
                          <div className="row">
                            <div className="col-md-3 col-sm-12">
                              <FormGroup
                                label=""
                                name="numberOfMembers"
                                labelClassName="margin-top"
                              >
                                <SelectField
                                  {...getFieldProps("numberOfMembers")}
                                  name="numberOfMembers"
                                  options={numbersOfMembersOptions}
                                  placeholder="Select Numbers of Members"
                                  onChange={(e) => {
                                    console.log("eeeee", e.target.value);
                                    setFieldValue(
                                      "numberOfMembers",
                                      e.target.value
                                    );
                                    const filteredPrice =
                                      priceOfMembersOptions?.filter(
                                        (item) =>
                                          item.label?.toString() ===
                                          e.target.value?.toString()
                                      );
                                    setFieldValue(
                                      "totalAmmount",
                                      filteredPrice?.[0]?.value
                                    );
                                  }}
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div
                          className="form-group success"
                          ng-class="{'success': validation.couponCode, 'error': !validation.couponCode}"
                        >
                          <label
                            className="control-label ng-binding"
                            htmlFor="referral-code"
                          >
                            Referral or Promotional Code (Optional)
                          </label>
                          <FormGroup
                            label=""
                            name="referralCode"
                            labelClassName="margin-top"
                          >
                            <InputField
                              type="text"
                              name="referralCode"
                              {...getFieldProps("referralCode")}
                              onBlur={onBlurhandlerPromocode}
                            />
                          </FormGroup>
                          {console.log(
                            "values?.referralCode: ",
                            values?.referralCode
                          )}
                          {values?.referralCode && couponCodeDetails && (
                            <div
                              className={`${
                                couponCodeDetails?.error
                                  ? "has-error"
                                  : "has-success"
                              }`}
                            >
                              <label
                                className="control-label ng-binding"
                                htmlFor="referral-code"
                              >
                                {couponCodeDetails?.error
                                  ? "Invalid Referral Code"
                                  : "Referral Code is successfully applied."}
                              </label>
                            </div>
                          )}
                          <hr />
                        </div>
                      </div>
                    </div>
                    {/* ngIf: !account.amount.discounted */}
                    <div
                      ng-if="!account.amount.discounted"
                      className="row ng-scope"
                    >
                      <div className="col-sm-12">
                        <h4 className="ng-binding">
                          Total Amount:
                          <span className="pull-right ng-binding">
                            ${values?.totalAmmount}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <button
                          type={"button"}
                          style={{
                            color: "#fff",
                            borderRadius: "5px",
                            backgroundColor: "var(--radium-info-600)",
                          }}
                          onClick={onNext}
                        >
                          Save &amp; Continue
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </Main>
          )}
          {values?.isAlreadyMembership && <AlreadyMembership />}
        </div>
      )}
    </>
  );
}

export default NewAccountRegister;

const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  text-align: center;
  border-radius: 0px 0px 5px 5px;

  h2 {
    color: rgb(31, 85, 165);
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
  }
`;
const Column = styled.div`
  display: grid;
  text-align: left;
  gap: 14px;

  label {
    padding: 0;
    color: #000;
    font-weight: 700;
  }

  input {
    display: block;
    width: 100%;
    padding: 14px 0 14px 14px;
    margin: 5px 0;
    border-radius: 20px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    background-color: rgba(237, 239, 241, 0.932);
    border-color: var(--text-primary-800) !important;
    input:-internal-autofill-selected {
      appearance: menulist-button;
      background-image: none !important;
      background-color: -internal-light-dark(
        rgb(232, 240, 254),
        rgba(70, 90, 126, 0.4)
      ) !important;
      color: fieldtext !important;
    }

    :focus-visible {
      border-color: var(--text-primary-800) !important;
      outline: none !important;
    }
  }

  p {
    color: red;
    font-size: 14px;
    padding: 0px;
  }
`;

const Main = styled.div`
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;

  .btn-footer {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  @media (max-width: 650px) {
    // margin: 0 12px;
    padding: 10px;

    .btn-footer {
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }
  }

  .form-group {
    text-align: left;
  }
  select {
    display: block;
    width: 100%;
    height: 38px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #000;
    background-color: var(--white-color-600);
    border: 1px solid var(--radium-info-600) !important;
    border-radius: 6px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .member-ship-block .userIcon input {
    display: block;
    width: 100%;
    height: 38px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #000;
    background-color: var(--white-color-600);
    border: 1px solid var(--radium-info-600) !important;
    border-radius: 6px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .save-btn {
    display: flex;
    justify-content: end;
  }
  .save-btn button {
    width: fit-content;
    color: #fff;
    padding: 6px 12px;
    border-radius: 5px;
    background-color: #1175bb;
  }
`;

const Country = styled.div`
  .react-tel-input .form-control {
    width: 100%;
    margin: 5px 0;
    height: 48px !important;
    border-radius: 20px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    background-color: rgba(237, 239, 241, 0.932);
    border-color: #3ca899 !important;
  }

  .react-tel-input .flag-dropdown {
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: transparent !important;
    border-radius: 3px 0 0 3px;
  }

  input:-internal-autofill-selected {
    background-image: none !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: fieldtext !important;
  }
`;


const Loader = styled.div`
  justify-self: center;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import closeIcon from "../../Assets/Images/Sidebar/closeIcon.svg";
import { ADMIN_ROLES } from "../../config";
import { ADMIN_ROLE, LOGIN_USER_ROLE_KEY } from "../../Constant";
import DetailsModal from "../Common/DetailsModal";
import { useTranslation } from "react-i18next";
import emergencyIcon from "../../Assets/Images/Sidebar/emergency.svg";
import vetIcon from "../../Assets/Images/Sidebar/vet.svg";
import medication from "../../Assets/Images/Sidebar/medication.svg";
import insuranceIcon from "../../Assets/Images/Sidebar/insurance.svg";
import allergyIcon from "../../Assets/Images/Sidebar/Vector.svg";
import ConditionIcon from "../../Assets/Images/Sidebar/Condition.svg";
import physicianIcon from "../../Assets/Images/Sidebar/physician.svg";
import immunizationIcon from "../../Assets/Images/Sidebar/Imm.svg";
import pet_infoIcon from "../../Assets/Images/Sidebar/profile_info.svg";
import documentIcon from "../../Assets/Images/Sidebar/docs.svg";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { ReactComponent as CloseIcon } from "../../Assets/Images/Sidebar/closeIcon.svg";
import { useTheme } from "@mui/material/styles";
import useAuth from "../../ContextProvider/AuthProvider";
import { getCategoryDocument } from "../../../api/axios";
import downArrow from "../../Assets/Images/Sidebar/down.svg";

export default function Sidebar() {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const loginUserRole = localStorage.getItem(LOGIN_USER_ROLE_KEY);
  const { t } = useTranslation();

  let SidebarItems;
  const theme = useTheme();
  const { partner } = useAuth();  
  const [categoryData, setCategoryData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  

  const searchParams = new URLSearchParams(location.search);
  const savedDropdown = searchParams.get("category");    
  const [openDropdown, setOpenDropdown] = useState(savedDropdown ? "Documents" : null);

  const getCategoryDocumentSelect = async () => {
    try {
      const { data: selectData } = await getCategoryDocument();
      console.log("selectData: ", selectData);
      setCategoryData(selectData?.data);
    } catch (error) {
      setCategoryData([]);
      console.log("error: ", error);
    }
  };
  useEffect(() => {
    getCategoryDocumentSelect();
  }, []);

  const sidebar = [
    {
      title: `${t("screens.personalInformation.title")}`,
      link: "/user-information",
      icon: pet_infoIcon,
      roles: ADMIN_ROLES,
    },
    {
      title: `${t("screens.profile.emergencyContacts")}`,
      link: "/emergency-contact",
      icon: emergencyIcon,
      roles: ADMIN_ROLES,
    },
    {
      title: `${t("screens.profile.physiciansTherapists")}`,
      link: "/physician-therapist",
      icon: vetIcon,
      roles: ADMIN_ROLES,
    },
    {
      title: `${t("screens.profile.medicalInsurance")}`,
      link: "/medical-insurance",
      icon: insuranceIcon,
      roles: ADMIN_ROLES,
    },
    {
      title: `${t("screens.profile.prescriptionandMedication")}`,
      link: "/prescription-medications",
      icon: medication,
      roles: ADMIN_ROLES,
    },
    {
      title: `${t("screens.profile.allergies")}`,
      link: "/allergy",
      icon: allergyIcon,
      roles: ADMIN_ROLES,
    },
    {
      title: `${t("screens.profile.conditions")}`,
      link: "/conditions",
      icon: ConditionIcon,
      roles: ADMIN_ROLES,
    },
    {
      title: `${t("screens.profile.surgeriesTreatments")}`,
      link: "/surgeries-treatments",
      icon: physicianIcon,
      roles: ADMIN_ROLES,
    },
    {
      title: `${t("screens.profile.immunizations")}`,
      link: "/immunizations",
      icon: immunizationIcon,
      roles: ADMIN_ROLES,
    },
    {
      title: `${t("screens.profile.familyHistory")}`,
      link: "/family-history",
      muiIcon: <PeopleAltIcon sx={{ height: "23px", width: "21px" }} />,
      roles: ADMIN_ROLES,
    },
    {
      title: `${t("screens.profile.documents")}`,
      link: "/document",
      icon: documentIcon,
      roles: ADMIN_ROLES,
      sub_category:[
        {
          title: "All", 
          link: "/document?category=All", 
        },
        ... categoryData.map((cat) => ({
          title: cat?.category,
          link: `/document?category=${encodeURIComponent(cat?.category)}`,
        })),
      ] 
    },
  ];

  const myProfileRoutes = [
    {
      title: "",
      link: "/myFootprintID",
      isHide: true,
      role: "ALL",
    },
    {
      title: `${t("screens.rightSidebar.text.changePassword")}`,
      link: "/settings/changePassword",
      role: "ALL",
    },
    {
      title: `${t("screens.rightSidebar.text.updateShippingInfo")}`,
      link: "/settings/shippingInfo",
      role: ADMIN_ROLE,
    },
    {
      title: `${t("screens.rightSidebar.text.updateBillingInfo")}`,
      link: "/settings/billingInfo",
      role: ADMIN_ROLE,
    },
    {
      title: `${t("screens.rightSidebar.text.buyAdditionalMemberships")}`,
      link: "/settings/upgradeMembers",
      role: ADMIN_ROLE,
    },
  ];

  const currentPath = window.location.pathname;
  const isMyProfileRoute = myProfileRoutes.some((item) =>
    item?.link.includes(currentPath)
  );
  if (isMyProfileRoute) {
    SidebarItems =
      loginUserRole === ADMIN_ROLE
        ? myProfileRoutes
        : myProfileRoutes.filter((item) => item?.role !== ADMIN_ROLE);
  } else {
    SidebarItems = sidebar;
  }

  const ButtonItem = [
    {
      name: `${t("screens.familyHistory.yes")}`,
      onClick: () => {
        setShowSuccessMessage(false);
        // doLogout();
        localStorage.clear();
        window.location.href = `${window?.location?.origin}/members`;
      },
    },
    {
      name: `${t("screens.familyHistory.no")}`,
      onClick: () => {
        setOpen(false);
      },
    },
  ];

  const handleClose = () => {
    setOpen(true);
    setShowSuccessMessage(true);
  };


  const toggleDropdown = (title, event) => {
    event.stopPropagation(); // Prevent event bubbling to submenus
  
    setOpenDropdown((prevTitle) => 
      prevTitle === title ? null : title
    );
  };

  return (
    <>
      <Wrapper className="scroll3">
        {/* <MainDiv> */}
        <Top>
          <NavLink>
            <Image>
              <img
                src={
                  partner?.logo
                    ? partner?.logo
                    : require("../../Assets/Images/Sidebar/footprintLogo.png")
                }
                alt="logo"
                style={
                  {
                    maxBlockSize: "70px",
                    padding: 0
                    // borderBottom: "1px solid white",
                    // paddingLeft: "20px",
                  }
                }
              />
            </Image>
          </NavLink>
        </Top>
        {/* <div className="sidebar-header-section">
          <div className="row">
            <div className="col-sm-3">
              <label className="ng-binding">Name:</label>
            </div>
            <div className="col-sm-9">
              <span className="ng-binding">
                {user?.user_fname} {user?.user_lname}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <label className="ng-binding">Role:</label>
            </div>
            <div className="col-sm-9">
              <span className="ng-binding">{role}</span>
            </div>
          </div>
          <Image className="text-center">
            {user?.avatar ? (
              <img src={user?.avatar} alt="logo" />
            ) : (
              <img
                src="/assets/images/profile.jpg"
                alt="logo"
                style={{ width: "auto", maxHeight: "150px", maxWidth: "250px" }}
              />
            )}
          </Image>
        </div> */}
        <MenuWrapper>
          <div className="user-seeting-label ng-binding">{`${t(
            "screens.profile.healthHistory"
          )}`}</div>
          {SidebarItems.map((item, index) =>
            item?.isHide ? (
              ""
            ) : (
             <>
              <NavLink
               to ={item.link}
                key={index}
                onClick={(event) => {
                  if (item.sub_category) {
                    console.log('====', item, event.target);
                    event.preventDefault(); 
                    toggleDropdown(item.title, event);
                  }
                }}
              >
                <MenuItem
                  style={
                  item?.icon
                      ? { ":hover": { background: "transparent" },position:'relative' }
                      : {}
                  }
                 
                >
                  {item?.icon && (
                    <img alt="" src={item.icon} height="23px" width="21px" />
                  )}
                  {item?.muiIcon && item?.muiIcon}
                  <h1 style={item?.icon ? { margin: "0px" } : {}}>
                    {item.title}  
                  </h1>
                  {item.sub_category ? <div className={`${openDropdown === item.title ? 'active-icon-deg active-icon' : 'active-icon'}`}><img alt="" src={downArrow}  width="20px"/></div>: ''}
                </MenuItem>
              </NavLink>
              {item.sub_category && openDropdown === item.title && (
                <div className="sub-item-data">
                    {item?.sub_category?.map((sub,index) => {
                      let subLinkUrl = sub.link;
                      let currentPath = location.pathname;
                      const urlParams = new URLSearchParams(location.search);
                      const sub_category = urlParams.get('category');
                      if(sub_category) {
                        currentPath +=`?category=${encodeURIComponent(sub_category)}`;
                      }
                      const isActive = currentPath === subLinkUrl;
                        return(
                       <div key={index} className="accordion-submenu-item">
                          <span className="accordion-item-sub-item">
                            <div className="accordion-item-border"></div>
                          </span>
                         <NavLink to={sub.link}  onClick={(event) => event.stopPropagation()} style={{marginTop:'10px',padding: '5px',paddingLeft: '15px',width:'100%',justifyContent: 'left'}} className={isActive ? 'submenu-active accordion-item' : 'submenu-item accordion-item'}>
                         <li className="subitem-sidebar" key={sub.id} style={{margin: 0, paddingLeft:0,paddingRight:0,}}> 
                           <h1 style={item?.icon ? { margin: "0px",textTransform: "capitalize"} : {}}>
                           {sub.title}
                           </h1>
                         </li>
                     </NavLink>
                      </div>
                        )
                  })}
                </div>)}
             </>
            )
          )}
          {!isMyProfileRoute && (
            <>
              <div className="user-seeting-label ng-binding">
                {`${t("screens.profile.formsHistory")}`}
              </div>
              <NavLink
                to={"/history"}
                key={"history"}
                // style={item?.icon ? { justifyContent: "start" } : {}}
              >
                <MenuItem>
                  <ManageHistoryIcon sx={{ height: "23px", width: "21px" }} />
                  <h1>{`${t("screens.history.history")}`}</h1>
                </MenuItem>
              </NavLink>
            </>
          )}
        </MenuWrapper>
        <Close>
          <Box sx={{ position: "absolute", bottom: "10px", right: "-17px" }}>
            <CloseIcon
              style={{
                stroke: `${theme.palette.info.main}`,
                cursor: "pointer",
              }}
              alt="close icon"
              onClick={() => handleClose()}
            />
          </Box>
        </Close>
        {showSuccessMessage && (
          <DetailsModal
            label={`${t("screens.logOut.message")}`}
            open={open}
            buttonList={ButtonItem}
            severity="warning"
            handleClose={() => setOpen(!open)}
          />
        )}
        {/* </MainDiv> */}
      </Wrapper>

      {/* <Header SidebarItems={SidebarItems} /> */}
    </>
  );
}
const Top = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  // height: 150px;
`;
const Image = styled.div`
  img {
    width: 100%;
    padding-bottom: 10px;
    margin-top: 10px;
    max-height: 150px;
    max-width: 220px;
    @media (max-width: 1130px) {
      width: 180px;
    }
  }
`;

const Close = styled.div`
  position: relative;
  padding: 10px;
  img {
    width: 40px;
    height: 40px;
    transition: 0.8 cubic-bezier(0.075, 0.82, 0.165, 1);
    :hover {
      transform: scale(1.05);
    }
  }
`;

const Wrapper = styled.div`
  ${"" /* margin: 5px 0px 5px 5px; */}
  // padding: 0px 20px;
  width: 330px;
  height: 100vh;
  // position: absolute;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #ebf1f6;
  // margin: 30px 0;
  // grid-template-rows: 1.5fr 10fr;
  background: #ffffff;
  // border-radius: 9px;
  position: sticky;
  top: 0;
  left: 0;
  @media (max-width: 1130px) {
    width: 330px;
  }
  @media (max-width: 890px) {
    display: none;
  }
`;
const MenuWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  // grid-template-rows: auto;
  // text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2px 0px;
  text-align: center;
  padding: 0px 20px;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    // border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff;
    background-color: var(--radium-info-600);
  }

  h1 {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: rgb(42 53 71);
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    display: grid;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  a {
    text-decoration: none;
    transition: 0.5s ease-out;
    display: grid;
    justify-content: center;
    :hover {
      border-radius: 7px;
      background: var(--radium-info-600);
      h1 {
        color: #fff;
      }
    }
  }
  a.active h1 {
    color: White;
  }
  a.active img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
      brightness(100%) contrast(100%);
  }
  .active-icon-deg{
    rotate: 180deg;
  }
    .active-icon{
     position:absolute;
    right:10px
    }

  a.active svg {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
      brightness(100%) contrast(100%);
  }
  a.active.submenu-item h1{
    color: rgb(42, 53, 71); 
  }
  a.active.submenu-item{
    background: transparent;
  }
    .accordion-item-sub-item{
    position: relative;
    width: 15px;
    height: .25rem;
    margin-top:10px;
    }
  .sub-item-data{
    position:relative;
    margin-left: 20px;
    margin-top: -1px;
    }
  .sub-item-data::after{
    position:absolute;
    width:1px;
    background:rgb(231, 231, 232);
    content: '';
    left:0;
    height:calc(100% - 18px);
    top:0;
    }
    .accordion-submenu-item{
    display: flex;
    align-items: center;
    }
   .accordion-item{
       position: relative;
       border:1px solid rgb(231, 231, 232);
   } 
      .accordion-item-border{
        width: 15px;
            border-bottom: 1px solid rgb(231, 231, 232);
        position: absolute;
      }
  .active {
    // height: 48px;
    // width: 267px;
    position: relative;
    background: var(--radium-info-600);
    text-align: center;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    // padding: 5px;

    ::before,
    ::after {
      content: "";
      // width: 20px;
      // height: 20px;
      right: 0;
      position: absolute;
    }
    ::before {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><path fill="white" fill-opacity="1" d="M0 0 Q20 0 20 20 L20 0Z" /></svg>');
      bottom: -20px;
    }
    ::after {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><path fill="rgb(255, 255, 255)" d="M0 20 Q20 20 20 0 L20 20Z" /></svg>');
      top: -20px;
    }
  }
`;
const MenuItem = styled.div`
  width: 240px;
  color: #000;
  align-items: center;
  padding: 5px 8px;
  transition: 0.5s ease-out;
  border-radius: 10px;
  justify-items: center;
  border: 1px solid transparent;
  border-radius: 50px;
  display: flex;
  gap: 8px;
  :hover {
    color: #fff;
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%);
    }
  }
`;

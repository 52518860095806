import React, { useEffect } from "react";
import { Link } from "react-router-dom";
function Forindividuals() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container-fluid ng-scope">
      <div className="individual-main-sections">
        <section ng-controller="lifeItemsDetails" className="ng-scope">
          <div>
            <h3 className="individuals-section-title">
              For <span className="text-orange">Individuals</span>
            </h3>
            <p className="individual-sub-title">Your FootprintID®,Your Life.</p>
          </div>
          <div className="life-inner-block">
            <div ng-repeat="(key,data ) in LifeItems" className="ng-scope">
              <img
                ng-src="assets/images/claire.jpg"
                className="img-fluid life-inner-content-img"
                src="assets/images/claire.jpg"
                alt=""
              />
            </div>
            <div
              ng-repeat="(key,data ) in LifeItems"
              className="ng-scope css-class-for-non-first-elements"
            >
              <img
                ng-src="assets/images/anne.jpg"
                className="img-fluid life-inner-content-img"
                src="assets/images/anne.jpg"
                alt=""
              />
            </div>
            <div
              ng-repeat="(key,data ) in LifeItems"
              className="ng-scope css-class-for-non-first-elements"
            >
              <img
                ng-src="assets/images/octavia.jpg"
                className="img-fluid life-inner-content-img"
                src="assets/images/octavia.jpg"
                alt=""
              />
            </div>
            <div
              ng-repeat="(key,data ) in LifeItems"
              className="ng-scope css-class-for-non-first-elements"
            >
              <img
                ng-src="assets/images/ava.jpg"
                className="img-fluid life-inner-content-img"
                src="assets/images/ava.jpg"
                alt=""
              />
            </div>
            <div
              ng-repeat="(key,data ) in LifeItems"
              className="ng-scope css-class-for-non-first-elements"
            >
              <img
                ng-src="assets/images/sally.jpg"
                className="img-fluid life-inner-content-img"
                src="assets/images/sally.jpg"
                alt=""
              />
            </div>
            <div
              ng-repeat="(key,data ) in LifeItems"
              className="ng-scope css-class-for-non-first-elements"
            >
              <img
                ng-src="assets/images/sophie.jpg"
                className="img-fluid life-inner-content-img"
                src="assets/images/sophie.jpg"
                alt=""
              />
            </div>
            <div
              ng-repeat="(key,data ) in LifeItems"
              className="ng-scope css-class-for-non-first-elements"
            >
              <img
                ng-src="assets/images/lisa.jpg"
                className="img-fluid life-inner-content-img"
                src="assets/images/lisa.jpg"
                alt=""
              />
            </div>
            <div
              ng-repeat="(key,data ) in LifeItems"
              className="ng-scope css-class-for-non-first-elements"
            >
              <img
                ng-src="assets/images/timothy.jpg"
                className="img-fluid life-inner-content-img"
                src="assets/images/timothy.jpg"
                alt=""
              />
            </div>
            <div
              ng-repeat="(key,data ) in LifeItems"
              className="ng-scope css-class-for-non-first-elements"
            >
              <img
                ng-src="assets/images/Kay.jpg"
                className="img-fluid life-inner-content-img"
                src="assets/images/Kay.jpg"
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="individual-first-sections">
          <div className="row flex-row">
            <div className="col-md-8 ng-scope" ng-controller="ourDetails">
              <div className="row flex-row">
                <div
                  className="col-md-6 ng-scope"
                  ng-repeat="(key,data ) in ourDetailsItems"
                >
                  <div className="our-Details-step-section">
                    <div className="our-Details-step-left ng-binding">01</div>
                    <p className="our-Details-step-right ng-binding">
                      We make it easy for you to keep your medical information
                      safe-and access it when you need it.,
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-6 ng-scope"
                  ng-repeat="(key,data ) in ourDetailsItems"
                >
                  <div className="our-Details-step-section">
                    <div className="our-Details-step-left ng-binding">03</div>
                    <p className="our-Details-step-right ng-binding">
                      No more stressful emergency room visits-just tap into your
                      personal health record, available in real time.
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-6 ng-scope"
                  ng-repeat="(key,data ) in ourDetailsItems"
                >
                  <div className="our-Details-step-section">
                    <div className="our-Details-step-left ng-binding">02</div>
                    <p className="our-Details-step-right ng-binding">
                      It's the premier medical ID that lets you share your
                      information with anyone you choose, and it's so easy to
                      use.
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-6 ng-scope"
                  ng-repeat="(key,data ) in ourDetailsItems"
                >
                  <div className="our-Details-step-section">
                    <div className="our-Details-step-left ng-binding">04</div>
                    <p className="our-Details-step-right ng-binding">
                      Our trusted health platform will give you access to your
                      medical records in a matter of seconds.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <p className="take-control-sections">
                Join FootprintID® today to create your personal health record
                and take control of your medical information.
              </p>
              <div className="text-center">
                <Link target="_self" to={"/register"}>
                  <button className="btn-deep-orange">Create an account</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section
          className="second-sections ng-scope"
          ng-controller="ourMembersData"
        >
          <h3 className="clients-say-businesses">
            <span>What Our Members Say</span>
          </h3>
          <div className="row flex-row clients-works-section">
            <div
              className="col-md-6 clients-inner-block ng-scope"
              ng-repeat="(key,data ) in ourMembersItems"
            >
              <div className="clients-details ng-binding">
                My husband and I were out of town at a wedding and my in-laws
                were watching my young children. One of my daughters suffers
                from several conditions which require multiple medications that
                have contraindications with some over the counter medications. I
                was able to leave my in-laws access to their FootprintID®
                information, which was very helpful when that daughter did not
                feel well. It's so much easier to have it in a digital, sharable
                format than having to write everything out each time someone may
                need it.
              </div>
              <p className="clients-user-name text-center ng-binding">
                Karen K.
              </p>
            </div>
            <div
              className="col-md-6 clients-inner-block ng-scope"
              ng-repeat="(key,data ) in ourMembersItems"
            >
              <div className="clients-details ng-binding">
                I was traveling and had chest pains. I was nowhere near my home
                doctor, and it was late evening. The first thing the emergency
                room did was take an EKG. Luckily, I had my FootprintID® with a
                prior EKG in my portal. I have normal (for me) abnormalities and
                had the emergency room not been able to see by comparing the new
                and prior EKG I would have been subjected to many more tests.
                This would have been costly and possibly taken days. Having my
                prior test result easily accessible with my FootprintID®
                membership, made it easy for them to determine that my pains
                were not heart related and treat me accordingly. I was back to
                my vacation the next day.
              </div>
              <p className="clients-user-name text-center ng-binding">
                Staci F.
              </p>
            </div>
            <div
              className="col-md-6 clients-inner-block ng-scope"
              ng-repeat="(key,data ) in ourMembersItems"
            >
              <div className="clients-details ng-binding">
                My son who has Gilberts disease was studying abroad in Prague.
                While this is common and doesn't cause any issues it shows up as
                elevated bilirubin in blood tests. He went to the doctor because
                he wasn't feeling well, who did blood work. The doctor initially
                became alarmed at his bilirubin levels and had my son not been
                able to show/identify to him his condition and prior blood work
                being similar, the doctor would have gone on to do more tests
                and wanting to send him to a specialist which would have not
                only been unnecessary, but costly, and taken him away from him
                schoolwork and planned travel during his time abroad. I would
                never travel or have my family travel without medical and health
                information on hand-thank you FootprintID®!
              </div>
              <p className="clients-user-name text-center ng-binding">
                Gina R.
              </p>
            </div>
            <div
              className="col-md-6 clients-inner-block ng-scope"
              ng-repeat="(key,data ) in ourMembersItems"
            >
              <div className="clients-details ng-binding">
                My husband was diagnosed with a lesion on his kidney which led
                us to multiple specialists to determine the best course of
                treatment. I made sure I always had his new and updated medical
                information in his FootprintID® portal so that I could easily
                show it to whatever doctor, hospital, treatment center we were
                going to next. I felt like the nurses wanted to hug me, for
                making it so much easier for them to have the current
                information as related to my husband's illness. These doctors
                are all in different medical systems that don't talk to each
                other, and there is always a delay in them getting information
                to each other and has to be at my request. Having the ability to
                keep and share the information as needed as we worked through
                his diagnosis and treatment plan was beneficial beyond words.
                Reduced stress, saved time, and allowed for better results.
                Luckily my husband is fine, but we have all his records in our
                portal and should he ever have another issue, they will be right
                there for any doctor who needs. Such a great tool! Erica G.
              </div>
              <p className="clients-user-name text-center ng-binding">
                Erica G.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Forindividuals;

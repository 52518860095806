import React, { useState } from "react";
import styled from "styled-components";
import FormGroup from "../../../Components/Common/FormGroup";
import InputField from "../../../Components/Common/InputField";
import { useFormikContext } from "formik";
import SelectField from "../../../Components/Common/AutoComplete";
import ApiService from "../../../Api/ApiServices";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

const numbersOfMembersOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
];

const priceOfMembersOptions = [
  { label: "1", value: "40" },
  { label: "2", value: "70" },
  { label: "3", value: "100" },
  { label: "4", value: "130" },
  { label: "5", value: "160" },
  { label: "6", value: "190" },
  { label: "7", value: "220" },
  { label: "8", value: "250" },
  { label: "9", value: "280" },
  { label: "10", value: "310" },
];

function NumberOfMembers() {
  const { setFieldValue, values, getFieldProps } = useFormikContext();
  const [couponCodeDetails, setCouponCodeDetails] = useState();
  const { t } = useTranslation();
  const theme = useTheme();

  const onBlurhandlerPromocode = async () => {
    try {
      if (values?.referralCode) {
        const { data } = await ApiService.checkCouponCode(values?.referralCode);
        if (data?.code) {
          setCouponCodeDetails(data);
        } else {
          setCouponCodeDetails({ error: "Invalid coupon code" });
        }
      }
    } catch (error) {
      console.log("error: ", error);
      setCouponCodeDetails({ error: "Invalid coupon code" });
    }
  };

  return (
    <div className="container" style={{ gridTemplateColumns: "90%" }}>
      <Main style={{ marginTop: 20 }}>
        <div className="panel panel-default member-ship-block">
          <div className="panel-heading">
            <h3 className="panel-title ng-binding">{`${t(
              "screens.upgradeMembership.membershipInfo"
            )}`}</h3>
          </div>
          <div className="panel-body">
            {/* <form className="register-membership ng-valid ng-dirty ng-valid-parse"> */}
            <div className="inner-container" ng-show="tagsMode === 'buy'">
              <div className="row">
                <div className="col-sm-12"></div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label
                      className="control-label ng-binding"
                      htmlFor="members"
                    >
                      {`${t("screens.upgradeMembership.selectMembers")}`}
                    </label>
                    <div className="row">
                      <div className="col-md-3 col-sm-12">
                        <FormGroup
                          label=""
                          name="numberOfMembers"
                          labelClassName="margin-top"
                        >
                          <SelectField
                            {...getFieldProps("numberOfMembers")}
                            name="numberOfMembers"
                            options={numbersOfMembersOptions}
                            placeholder="Select Numbers of Members"
                            isOnChange
                            onChange={(e, data) => {
                              const filteredPrice =
                                priceOfMembersOptions?.filter(
                                  (item) =>
                                    item.label?.toString() ===
                                    data.value?.toString()
                                );
                              console.log("filteredPrice: ", filteredPrice);
                              setFieldValue(
                                "totalAmmount",
                                filteredPrice?.[0]?.value
                              );
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="form-group success"
                    ng-class="{'success': validation.couponCode, 'error': !validation.couponCode}"
                  >
                    <label
                      className="control-label ng-binding"
                      htmlFor="referral-code"
                    >
                      {`${t("screens.upgradeMembership.referralCode")}`}
                    </label>
                    <FormGroup
                      label=""
                      name="referralCode"
                      labelClassName="margin-top"
                    >
                      <InputField
                        type="text"
                        name="referralCode"
                        {...getFieldProps("referralCode")}
                        onBlur={onBlurhandlerPromocode}
                      />
                    </FormGroup>
                    {values?.referralCode && couponCodeDetails && (
                      <label
                        htmlFor="referral-code"
                        ng-bind="message.coupon_err"
                        className={
                          couponCodeDetails?.error
                            ? "error ng-binding"
                            : "success-message ng-binding"
                        }
                      >
                        {couponCodeDetails?.error
                          ? `${t(
                              "screens.upgradeMembership.invalidReferralCode"
                            )}`
                          : `${t(
                              "screens.upgradeMembership.referralCodeApplied"
                            )}`}
                      </label>
                    )}
                    <hr />
                  </div>
                </div>
              </div>
              {/* ngIf: !account.amount.discounted */}
              <div ng-if="!account.amount.discounted" className="row ng-scope">
                <div className="col-sm-12">
                  <h4 className="ng-binding">
                    {`${t("screens.upgradeMembership.totalAmount")}`}:
                    <span className="pull-right ng-binding">
                      ${values?.totalAmmount}
                    </span>
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <button
                    type={"submit"}
                    style={{
                      color: "#fff",
                      borderRadius: "5px",
                      backgroundColor: `${theme.palette.info.main}`,
                    }}
                  >
                    {`${t("screens.upgradeMembership.saveAndContinue")}`}
                  </button>
                </div>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </Main>
    </div>
  );
}

export default NumberOfMembers;

const Main = styled.div`
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;

  .btn-footer {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  @media (max-width: 650px) {
    // margin: 0 12px;
    padding: 10px;
  }

  .form-group {
    text-align: left;
  }
  select {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #000;
    background-color: var(--white-color-600);
    border: 1px solid #1175bb !important;
    border-radius: 6px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .member-ship-block .userIcon input {
    display: block;
    width: 100%;
    height: 38px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #000;
    background-color: var(--white-color-600);
    border: 1px solid var(--radium-info-600) !important;
    border-radius: 6px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .save-btn {
    display: flex;
    justify-content: end;
  }
  .save-btn button {
    width: fit-content;
    color: #fff;
    padding: 6px 12px;
    border-radius: 5px;
    background-color: #1175bb;
  }
`;

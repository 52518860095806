import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';

export const FullPageWrapper = styled('div')(() => ({
    // paddingTop: '3rem', 
    // padding: '3rem 3rem 0 0',
    padding: "20px",

    '@media (max-width: 1024px)': {
        paddingTop: '1rem',
        padding: "10px",
    }
}));
export const MainPageWrapper = styled("div")(() => ({
    maxWidth: "1200px",
    margin: "auto",
  }));
  
export const Container = styled('div')(() => ({
    overflow: 'hidden',
    padding: '3rem 0 1rem 1rem',
    margin: '0 auto',  
}));

export const Wrapper = styled('div')(() => ({
    margin: '0 auto',
    width: '50vw',
}));

export const BodyWrapper = styled('div')(() => ({  
    // margin: '0 auto',
}));

export const RowWrapper = styled('div')(() => ({
    // backgroundColor: 'rgba(255, 255, 255, 0.3)',
    backgroundColor: "#1175BB",
    maxWidth: '50vw',
    width: 'auto',   
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    // gap: '1rem',    
}));

export const Image = styled('img')(() => ({
    width: '4vw',
    height: '4vw',
    borderRadius: '50%',
    objectFit: 'cover',
    margin: '0 auto',    
}));

export const HeaderWrapper = styled('div')(() => ({
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',  
    gap: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '0.5rem',
}));

export const HeaderRowWrapper = styled('div')(() => ({
    // margin: '0 auto',
    // backgroundColor: 'rgba(255, 255, 255, 0.3)',
    backgroundColor: "#1175BB",
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '1rem', 
    width: '50vw',
}));

export const Title = styled('p')(({ theme }) => ({
    // padding: theme.spacing(0,4,0,21),
    margin: '0 auto',
    color: '#000',
    fontSize: '20px',         
    width: '12vw',     
}));

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    // backgroundColor: 'rgba(255, 255, 255, 0.3)',
    backgroundColor: "#1175BB !important",
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    }, 
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',   
    margin: '0 auto',
    display: 'flex',  
    borderRadius: '20rem',  
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: '21vw',
    },
    padding: theme.spacing(0,0,0,4),       
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    pointerEvents: 'none',
    padding: '0.5rem 1rem 0 0',
    alignItems: 'center',
    justifyContent: 'center',  
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
        width: '12vw',
        '&:focus': {
            width: '20vw',
        },
        },
    },
}));

export const StyledMessage = styled("h6")({
    color: "#ffffff",
    fontSize: "20px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center", 
    height: "40vh",   
});

export const SearchMessage = styled("h6")({
    color: "#ffffff",
    fontSize: "20px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center", 
    height: "40vh", 
    padding: "0 0 0 12rem",  
});
import React from "react";
import styled from "styled-components";
import "./Slider1.css";
import { Box } from "@mui/material";

const menuData = [0, 1, 2, 3];
const delay = 5000;

function Slider1() {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === menuData.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <>
      <Wrapper>
        <Slideshow>
          <SlideshowSlider
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          >
            <Slide>
              <SlideDetails>
                <Box component="div" sx={{width:'100%'}}> 
                   <Image src={"assets/images/nursing.jpg"} alt="image" />
                </Box>
                <Box>
                <Text>
                  Are you tired of the hassle of carrying paper copies of your
                  medical records? Now there's FootprintID®, a membership
                  service that makes it easy to safely and securely store your
                  personal health information for when you're at home or on the
                  go.
                </Text>
                </Box>
              </SlideDetails>
            </Slide>
            <Slide>
              <SlideDetails>
                <Box component="div">
                <Image src={"assets/images/nurses.jpg"} alt="image" />
                </Box>
                <Box>
                <Text>
                  A personal portable health record gives you and your family
                  the peace of mind that comes from knowing you have access to
                  your most import health information anytime and anywhere.
                </Text>
                </Box>
              </SlideDetails>
            </Slide>
            <Slide>
              <SlideDetails>
                <Box>
                <Image src={"assets/images/nurse.jpg"} alt="image" />
                </Box>
                <Box>
                <Text>
                A personal portable health record gives you and your family
                  the peace of mind that comes from knowing you have access to
                  your most import health information anytime and anywhere.
                </Text>
                </Box>
              </SlideDetails>
            </Slide>
            <Slide>
              <SlideDetails>
                <Box>
                <Image src={"assets/images/nurses-us.jpg"} alt="image" />
                </Box>
                <Box>
                <Text>
                  Our unique member service and proprietary technology is
                  designed to help you and your loved ones prepare for travel,
                  obtain better care, and communicate with emergency responders.
                </Text>
                </Box>
              </SlideDetails>
            </Slide>
          </SlideshowSlider>
        </Slideshow>
      </Wrapper>

      <SlideshowDots>
        {menuData?.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </SlideshowDots>
    </>
  );
}
const Wrapper = styled.div`
  height: 337px;
  margin: 0 auto;
  background: rgba(217, 217, 217, 0.17);
  border: 1px solid #06476c;
  border-radius: 30px;
  overflow: hidden;

  @media (max-width: 915px) {
    padding-bottom: 30px;
    height: 100%;
  }
  @media (max-width: 435px) {
    padding-left: 0;
    height: 100%;
  }
`;
const Slideshow = styled.div`
  margin: 0 auto;
  overflow: hidden;
`;
const SlideshowSlider = styled.div`
  white-space: nowrap;
  transition: ease 1000ms;
`;
const Slide = styled.div`
  display: inline-block;
  vertical-align: baseline !important;
`;
const SlideDetails = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2.2fr;
  // grid-template-columns: 360px auto;
  // gap: 200px;
  align-items: center;

  @media (max-width: 1165px) {
    grid-template-columns: 379px auto;
  }
  @media (max-width: 1040px) {
   grid-template-columns: 362px auto;
        gap: 0;
  }
  @media (max-width: 980px) {
    grid-template-columns: 354px auto;
  }
  @media (max-width: 940px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 915px) {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 337px;
  object-fit: center;
  border-radius: 30px;
  @media (max-width: 915px) {
    margin: 0 auto;
  }
  @media (max-width: 475px) {
    margin: 0 auto;
  }
  @media (max-width: 380px) {
    height: 300px;
  }
`;
const Text = styled.p`
  /* color: rgb(31, 85, 165);  */
  color: #000000;
  line-height: 47px;
  font-size: 28px;
  font-weight: 450;
  padding: 0 39px 0 39px;
  white-space: pre-line;
  margin: 0 auto;
  max-width: 926px;

  @media (max-width: 1280px) {
    font-size: 25px;
    line-height: 40px;
  }
  @media (max-width: 1175px) {
    font-size: 23px;
  }
  @media (max-width: 1080px) {
    font-size: 21px;
    line-height: 35px;
  }
  @media (max-width: 915px) {
    padding: 0px 100px;
    text-align: justify;
  }
  @media (max-width: 700px) {
    padding: 0px 50px;
    text-align: justify;
  }
  @media (max-width: 435px) {
    font-size: 18px;
    line-height: 27px;
    padding: 0px 20px;
    text-align: center;
  }
`;
const SlideshowDots = styled.div`
  text-align: center;
  padding-top: 40px;
  @media (max-width: 435px) {
    padding-top: 20px;  
    }
`;

export default Slider1;

import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  Column,
  Body,
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
  newSmallFieldBoxStyle,
} from "../../Css";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { personalInfoFormValidation } from "./Validation";
import ImageUpload from "../Common/ImageUpload";
import { useTranslation } from "react-i18next";
import { getCountryAsOption, getStateAsOptions } from "../../Utils";

const defaultValue = {
  id: 0,
  user_id: 0,
  avatar: "",
  sex: "",
  title: "",
  fname: "",
  lname: "",
  height_ft: 0,
  height_in: 0,
  weight_lb: 0,
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  homephone: "",
  workphone: "",
  cellphone: "",
  email: "",
  primarylanguage: "",
  primarylanguage_other: "",
  dob: null,
  haircolor: "",
  haircolor_other: "",
  eyecolor: "",
  eyecolor_other: "",
  bloodtype: "",
  identifying_marks: "",
  identifying_marks_other: "",
  prosthetics: "",
  religious: "",
  religious_other: "",
  ethnicity: "",
  ethnicity_other: "",
  marital_status: "",
  lenses: 0,
  dentures: 0,
  modified_by: 0,
  created: "",
  modified: "",
  country: "",
  height_cm: 0,
  weight_kg: 0,
};

const titleOptions = [
  {
    label: "Mr",
    value: "Mr",
  },
  {
    label: "Mrs",
    value: "Mrs",
  },
  {
    label: "Ms",
    value: "Ms",
  },
  {
    label: "Master",
    value: "Master",
  },
  {
    label: "Dr",
    value: "Dr",
  },
];

const feetOptions = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
];

const inchesOptions = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
];

const YesNoOption = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "0",
  },
];

export default function PersonalInformationForm({
  isEdit,
  data,
  setIsAdd,
  fetchAPIData,
  setIsEdit,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const [image, setImage] = useState(null);
  const [primaryLanguageOptions, setPrimaryLanguageOptions] = useState([]);
  const [hairColorOptions, setHairColorOptions] = useState([]);
  const [eyeColorOptions, setEyeColorOptions] = useState([]);
  const [bloodTypeOptions, setBloodTypeOptions] = useState([]);
  const [identifyingMarkOptions, setIdentifyingMarkOptions] = useState([]);
  const [prostheticsOptions, setProstheticsOptions] = useState([]);
  const [religiousOptions, setReligiousOptions] = useState([]);
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const { t } = useTranslation();

  const GenderOption = [
    {
      label: `${t("screens.personalInformation.text.male")}`,
      value: "Male",
    },
    {
      label: `${t("screens.personalInformation.text.female")}`,
      value: "Female",
    },
    {
      label: `${t("screens.formData.other")}`,
      value: "Other",
    },
  ];

  const getPrimaryLanguageList = async () => {
    try {
      const { data } = await ApiService.primaryLanguageList();
      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setPrimaryLanguageOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getHairColorList = async () => {
    try {
      const { data } = await ApiService.hairColorList();
      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setHairColorOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getEyeColorList = async () => {
    try {
      const { data } = await ApiService.eyeColorList();
      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setEyeColorOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getBloodTypeList = async () => {
    try {
      const { data } = await ApiService.bloodTypeList();
      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setBloodTypeOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getIdentifyingMarksList = async () => {
    try {
      const { data } = await ApiService.identifyingMarksList();
      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setIdentifyingMarkOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getProstheticsList = async () => {
    try {
      const { data } = await ApiService.prostheticsList();
      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setProstheticsOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getReligiousList = async () => {
    try {
      const { data } = await ApiService.religiousList();
      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setReligiousOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getEthnicityList = async () => {
    try {
      const { data } = await ApiService.ethnicityList();
      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setEthnicityOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getMaritalStatusList = async () => {
    try {
      const { data } = await ApiService.maritalStatusList();
      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setMaritalStatusOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  useEffect(() => {
    const country = getCountryAsOption();
    setCountryOptions(country);
    if (isEdit) {
      setInitialValues({
        ...initialValues,
        ...data,
        dentures: data?.dentures?.toString(),
        lenses: data?.lenses?.toString(),
        height_ft: data?.height_ft?.toString(),
        height_in: data?.height_in?.toString(),
      });
      const countryCode = country?.find(
        (country) => country.label === data?.country
      );
      handleCountryChange("", countryCode);
    } else {
      setInitialValues(defaultValue);
    }
  }, [isEdit, data]);

  useEffect(() => {
    // getState();
    getPrimaryLanguageList();
    getHairColorList();
    getEyeColorList();
    getBloodTypeList();
    getIdentifyingMarksList();
    getProstheticsList();
    getReligiousList();
    getEthnicityList();
    getMaritalStatusList();
  }, []);

  const uploadDocument = async (image) => {
    try {
      const formData = new FormData();
      formData.append("image", image);
      const uploadDocumentResponse = await ApiService.uploadDocument(formData);
      return uploadDocumentResponse?.status;
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const onSubmit = async (values) => {
    try {
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data?.user_id,
        };
        if (image) {
          const uploadDocumentResponse = await uploadDocument(image);
          if (uploadDocumentResponse === 200) {
            payload.avatar = image?.name;
            payload.uploadedFile = true;
          }
        }
        const res = await ApiService.editPersonalInfo(userId, payload);
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      } else {
        let payload = {
          ...values,
        };
        if (image) {
          const uploadDocumentResponse = await uploadDocument(image);
          if (uploadDocumentResponse === 200) {
            payload.avatar = image?.name;
            payload.uploadedFile = true;
          }
        }
        const { data } = await ApiService.addPersonalInfo(userId, payload);
        if (data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleCountryChange = (e, newValue) => {
    const state = getStateAsOptions(newValue?.isoCode);
    const filteredState = state?.map((item) => {
      return {
        value: item.label,
        label: item.label,
      };
    });
    filteredState.push({ value: "Other", label: "Other" });
    setStateOptions(filteredState);
    formikRef?.current?.setFieldValue("state", "");
  };

  const styles = {
    radio: {
      "& .MuiRadio-root": {
        color: `var(--radium-info-600)`,
        "&.Mui-checked": {
          color: `var(--radium-info-600)`,
        },
      },
    },
  };

  const scrollToError = () => {
    if (Object.keys(formikRef.current.errors).length > 0) {
      const firstErrorField = Object.keys(formikRef.current.errors)[0];
      const firstErrorFieldRef = document.getElementsByName(firstErrorField)[0];
      if (firstErrorFieldRef) {
        const topPosition =
          firstErrorFieldRef.getBoundingClientRect().top +
          window.pageYOffset -
          30;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  };

  return (
    <Box  sx={(theme) => ({
      [theme.breakpoints.up('md')]: { padding: '20px' },
      [theme.breakpoints.down('md')]: { padding: '10px' }
    })}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={personalInfoFormValidation}
        validateOnMount={true}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            {console.log("values: ", values)}
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid var(--radium-info-600)", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? `${t(
                        "screens.personalInformation.editPersonalInformation"
                      )}`
                    : `${t(
                        "screens.personalInformation.addPersonalInformation"
                      )}`}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    marginBottom: "14px",
                  }}
                >
                  <FormGroup
                    name="photo"
                    label={`${t(
                      "screens.personalInformation.text.uploadPhoto"
                    )}`}
                  >
                    <ImageUpload
                      alt="Uploaded_Image"
                      id="imageInput"
                      image={image}
                      setImage={setImage}
                      accept="image/*"
                      boxSx={{
                        width: {
                          sm: 120,
                          md: 120,
                          xs: 93,
                          lg: 130,
                          xl: 130,
                        },
                        height: {
                          sm: 120,
                          md: 120,
                          xs: 105,
                          lg: 130,
                          xl: 130,
                        },
                      }}
                    />
                  </FormGroup>
                </Box>
                <Grid container spacing={{
                   sm: 3,
                   xs: 1,
                }} columns={12}>
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="fname"
                      label={`${t(
                        "screens.personalInformation.text.firstName"
                      )}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("fname")} />
                    </FormGroup>{" "}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="lname"
                      label={`${t(
                        "screens.personalInformation.text.lastName"
                      )}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("lname")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container spacing={{
                   sm: 3,
                   xs: 1,
                }} columns={12} 
                sx={(theme) => ({
                  [theme.breakpoints.up('md')]: { marginTop: '-11px !important' },
                  [theme.breakpoints.down('md')]: { marginTop: '3px !important' }
                })}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="title"
                      label={`${t("screens.personalInformation.text.title")}`}
                    >
                      <AutoComplete
                        {...getFieldProps("title")}
                        name="title"
                        options={titleOptions}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectTitle"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="dob"
                      label={`${t(
                        "screens.personalInformation.text.dateOfBirth"
                      )}`}
                    >
                      <InputField
                        type="date"
                        inputProps={{ max: "9999-12-12" }}
                        {...getFieldProps("dob")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="email"
                      label={`${t("screens.personalInformation.text.email")}`}
                    >
                      <InputField {...getFieldProps("email")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    {" "}
                    <Box
                      sx={{
                        ...newSmallFieldBoxStyle,
                        paddingBottom: "0px",
                        alignItems: "end",
                      }}
                    >
                      <Box style={{ width: "30%" }}>
                        <FormGroup
                          name="height_ft"
                          label={`${t(
                            "screens.personalInformation.text.height"
                          )}`}
                        >
                          <AutoComplete
                            {...getFieldProps("height_ft")}
                            name="height_ft"
                            options={feetOptions}
                            placeholder={`${t(
                              "screens.personalInformation.text.selectHeight"
                            )}`}
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ width: "15%" }}>
                        <Typography>{`${t(
                          "screens.personalInformation.text.feet"
                        )}`}</Typography>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "30%" }}>
                        <FormGroup name="height_in" label={""} requiredField>
                          <AutoComplete
                            {...getFieldProps("height_in")}
                            name="height_in"
                            options={feetOptions}
                            placeholder={`${t(
                              "screens.personalInformation.text.selectRelationship"
                            )}`}
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ width: "15%" }}>
                        <Typography>{`${t(
                          "screens.personalInformation.text.inches"
                        )}`}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box
                      sx={{
                        ...newSmallFieldBoxStyle,
                        paddingBottom: "0px",
                        alignItems: "end",
                      }}
                    >
                      <Box style={{ width: "30%" }}>
                        <FormGroup
                          name="weight_kg"
                          label={`${t(
                            "screens.personalInformation.text.Weight"
                          )}`}
                        >
                          <InputField {...getFieldProps("weight_kg")} />
                        </FormGroup>
                      </Box>
                      <Box style={{ width: "15%" }}>
                        <Typography>{`${t(
                          "screens.personalInformation.text.lbs"
                        )}`}</Typography>
                      </Box>
                      <Box>
                        <FormGroup
                          name="sex"
                          label={`${t(
                            "screens.personalInformation.text.gender"
                          )}`}
                          requiredField
                        >
                          <RadioGroup
                            name="sex"
                            {...getFieldProps("sex")}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: "white",
                            }}
                            sx={styles.radio}
                          >
                            {GenderOption?.map((item) => {
                              return (
                                <FormControlLabel
                                  value={item?.value}
                                  control={<Radio />}
                                  label={item?.label}
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "0.9rem",
                                      color: "black",
                                    },
                                  }}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormGroup>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="address1"
                      label={`${t("screens.formData.streetAddress")}`}
                    >
                      <InputField {...getFieldProps("address1")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="address2"
                      label={`${t("screens.formData.apt/Ste")}`}
                    >
                      <InputField {...getFieldProps("address2")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="country"
                      label={`${t("screens.formData.country")}`}
                    >
                      {/* <InputField {...getFieldProps("country")} /> */}
                      <AutoComplete
                        {...getFieldProps("country")}
                        name="country"
                        options={countryOptions}
                        placeholder={t("screens.formData.selectCountry")}
                        onChange={handleCountryChange}
                        isOnChange
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="state"
                      label={`${t("screens.formData.stateProvinceRegion")}`}
                    >
                      <AutoComplete
                        {...getFieldProps("state")}
                        name="state"
                        otherType="Other"
                        options={stateOptions}
                        placeholder={`${t("screens.formData.selectState")}`}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="city"
                      label={`${t("screens.formData.city")}`}
                    >
                      <InputField {...getFieldProps("city")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="zipcode"
                      label={`${t("screens.formData.zipCode")}`}
                    >
                      <InputField {...getFieldProps("zipcode")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="homephone"
                      label={`${t(
                        "screens.personalInformation.text.homePhone"
                      )}`}
                    >
                      <CustomPhoneInput {...getFieldProps("homephone")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="workphone"
                      label={`${t(
                        "screens.personalInformation.text.workPhone"
                      )}`}
                    >
                      <CustomPhoneInput {...getFieldProps("workphone")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="cellphone"
                      label={`${t(
                        "screens.personalInformation.text.callPhone"
                      )}`}
                    >
                      <CustomPhoneInput {...getFieldProps("cellphone")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="primarylanguage"
                      label={`${t(
                        "screens.personalInformation.text.primaryLanguage"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("primarylanguage")}
                        name="primarylanguage"
                        options={primaryLanguageOptions}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectPrimaryLanguage"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  {values?.primarylanguage === "Other" && (
                    <Grid item xs={12} md={3} sm={4}>
                      <FormGroup
                        name="primarylanguage_other"
                        label={`${t(
                          "screens.personalInformation.text.primaryLanguageName"
                        )}`}
                      >
                        <InputField
                          {...getFieldProps("primarylanguage_other")}
                        />
                      </FormGroup>
                    </Grid>
                  )}
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="haircolor"
                      label={`${t(
                        "screens.personalInformation.text.hairColor"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("haircolor")}
                        name="haircolor"
                        options={hairColorOptions}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectHairColor"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  {values?.haircolor === "Other" && (
                    <Grid item xs={12} md={3} sm={4}>
                      <FormGroup
                        name="haircolor_other"
                        label={`${t(
                          "screens.personalInformation.text.hairColorName"
                        )}`}
                      >
                        <InputField {...getFieldProps("haircolor_other")} />
                      </FormGroup>
                    </Grid>
                  )}
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="eyecolor"
                      label={`${t(
                        "screens.personalInformation.text.eyeColor"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("eyecolor")}
                        name="eyecolor"
                        options={eyeColorOptions}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectEyeColor"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  {values?.eyecolor === "Other" && (
                    <Grid item xs={12} md={3} sm={4}>
                      <FormGroup
                        name="eyecolor_other"
                        label={`${t(
                          "screens.personalInformation.text.eyeColorName"
                        )}`}
                      >
                        <InputField {...getFieldProps("eyecolor_other")} />
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="lenses"
                      label={`${t(
                        "screens.personalInformation.text.contactLenses"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("lenses")}
                        name="lenses"
                        options={YesNoOption}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectContactLenses"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="bloodtype"
                      label={`${t(
                        "screens.personalInformation.text.bloodType"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("bloodtype")}
                        name="bloodtype"
                        options={bloodTypeOptions}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectBloodType"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="identifying_marks"
                      label={`${t(
                        "screens.personalInformation.text.identifyingMarks"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("identifying_marks")}
                        name="identifying_marks"
                        options={identifyingMarkOptions}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectIdentifyingMarks"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  {values?.identifying_marks === "Other" && (
                    <Grid item xs={12} md={3} sm={4}>
                      <FormGroup
                        name="identifying_marks_other"
                        label={`${t(
                          "screens.personalInformation.text.identifyingMarksName"
                        )}`}
                      >
                        <InputField
                          {...getFieldProps("identifying_marks_other")}
                        />
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="dentures"
                      label={`${t(
                        "screens.personalInformation.text.dentures"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("dentures")}
                        name="dentures"
                        options={YesNoOption}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectDentures"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="prosthetics"
                      label={`${t(
                        "screens.personalInformation.text.prosthetics"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("prosthetics")}
                        name="prosthetics"
                        options={prostheticsOptions}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectProsthetics"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="religious"
                      label={`${t(
                        "screens.personalInformation.text.religiousPreference"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("religious")}
                        name="religious"
                        options={religiousOptions}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectReligiousPreference"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  {values?.religious === "Other" && (
                    <Grid item xs={12} md={3} sm={4}>
                      <FormGroup
                        name="religious_other"
                        label={`${t(
                          "screens.personalInformation.text.religiousName"
                        )}`}
                      >
                        <InputField {...getFieldProps("religious_other")} />
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="ethnicity"
                      label={`${t(
                        "screens.personalInformation.text.ethnicity"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("ethnicity")}
                        name="ethnicity"
                        options={ethnicityOptions}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectEthnicity"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                  {values?.ethnicity === "Other" && (
                    <Grid item xs={12} md={3} sm={4}>
                      <Column>
                        <FormGroup
                          name="ethnicity_other"
                          label={`${t(
                            "screens.personalInformation.text.ethnicityName"
                          )}`}
                        >
                          <InputField {...getFieldProps("ethnicity_other")} />
                        </FormGroup>
                      </Column>
                    </Grid>
                  )}
                  <Grid item xs={12} md={3} sm={4}>
                    <FormGroup
                      name="marital_status"
                      label={`${t(
                        "screens.personalInformation.text.maritalStatus"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("marital_status")}
                        name="marital_status"
                        options={maritalStatusOptions}
                        placeholder={`${t(
                          "screens.personalInformation.text.selectMaritalStatus"
                        )}`}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
              {/* </Grid> */}
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => {
                      setIsAdd(false);
                      setIsEdit(false);
                    }}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    onClick={scrollToError}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.personalInformationUpdated")}`
              : `${t("screens.successMessage.personalInformationCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
            setIsEdit(false);
          }}
        />
      )}
    </Box>
  );
}

import React, { useCallback, useMemo, useRef, useState } from "react";

import { isEqual } from "lodash";
import useSWR from "swr";
import "./GlobalItemList.css";
import viewIcon from "../../Assets/Images/eye.png";
import useAuth from "../../ContextProvider/AuthProvider";
import { addItemtoObject, hexToRgba, jsonToQueryString } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button, Modal, Box, Typography } from "@mui/material";
import ApiService from "../../Api/ApiServices.js";
import Layout from "../Side bar/Layout.js";
import { MainPageWrapper } from "./GlobalItemList";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function History() {
  const { userId, user } = useAuth();
  const theme = useTheme();

  const [filter, setFilter] = useState({
    limit: 15,
    from: 0,
    page: 1,
  });
  const [pageCount, setPageCount] = useState(0);
  const [selectedData, setSelectedData] = useState("{}");
  const [updatedValue, setUpdatedValue] = useState();
  const fetchIdRef = useRef(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  const {
    data = [],
    mutate: fetchEmergencyData,
    isLoading,
  } = useSWR(
    `Historydata${userId}-${jsonToQueryString(filter)}`,
    async () => {
      const { page, limit } = filter;
      const { data } = await ApiService.getHistoryByUserId(userId, page);
      if (data?.length && limit) {
        setPageCount(Math.ceil(data?.[0]?.totalRows / limit));
      }
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    // if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
    setFilter(oFilter);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleView = (item, type) => {
    const propertiesToDelete = ["id"];
    const formattedValue = addItemtoObject(item, propertiesToDelete);
    setUpdatedValue(formattedValue?.data);
    const selectedValues =
      type === "new_value" ? item?.new_value : item?.old_value;
    setSelectedData(selectedValues);
    handleOpen();
  };

  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.history.formName")}`,
        textAlign: "center",
        accessor: "table_name",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.history.operation")}`,
        textAlign: "center",
        accessor: "operations",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.history.newValue")}`,
        accessor: "new_value",
        textAlign: "center",
        width: "12.5vw",
        Cell: ({ row }) => {
          if (!row.original.new_value) {
            return "N/A";
          }
          return (
            <>
              <Button
               color="info"
                sx={{
                  minWidth: "40px",
                  background:   `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                  borderRadius: "10px",
                  marginRight: "5px",
                  width: "24px",
                }}
                onClick={() => handleView(row.original, "new_value")}
              >
                <img
                  alt="view"
                  src={viewIcon}
                  style={{
                    color: "#000000",
                    cursor: "pointer",
                  }}
                />
              </Button>
            </>
          );
        },
      },
      {
        Header: `${t("screens.history.oldValue")}`,
        accessor: "old_value",
        textAlign: "center",
        width: "12.5vw",
        Cell: ({ row }) => {
          if (!row.original.old_value) {
            return "N/A";
          }
          return (
            <>
              <Button
                color="info"
                sx={{
                  minWidth: "40px",
                  background:   `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                  borderRadius: "10px",
                  marginRight: "5px",
                  width: "24px",
                }}
                onClick={() => handleView(row.original, "old_value")}
              >
                <img
                  alt="view"
                  src={viewIcon}
                  style={{
                    color: "#000000",
                    cursor: "pointer",
                  }}
                />
              </Button>
            </>
          );
        },
      },
      {
        Header: `${t("screens.history.modifiedDate")}`,
        accessor: "modified",
        textAlign: "center",
        width: "12.5vw",
        Cell: ({ row }) => {
          // const date = new Date(Number(row?.original?.created));
          const date = row?.original?.created
          const formattedDate = formatModifiedDate(date)
          // const formattedDate = `${
          //   date.getMonth() + 1
          // }/${date.getDate()}/${date.getFullYear()} ${String(
          //   date.getHours()
          // ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
          return formattedDate;
        },
      },
      {
        Header: `${t("screens.history.modifiedBy")}`,
        accessor: "user_fname",
        textAlign: "center",
        width: "12.5vw",
        Cell: ({ row }) => {
          return `${row.original.user_fname} ${row.original.user_lname}`;
        },
      },
    ];
  }, [t]);

  const defaultOptions = {
    pageSize: 15,
  };

  const props = {
    label: "",
    isLoading: isLoading,
    columns,
    columnsData: data,
    details: " ",
    fetchData,
    pageCount,
    defaultOptions,
    addButton: false,
    pagination: false,
  };

  const listData = useMemo(() => {
    const parsedValue = JSON.parse(selectedData || "{}");
    delete parsedValue?.created;
    delete parsedValue?.user_id;
    delete parsedValue?.modified_by;
    delete parsedValue?.id;
    if (parsedValue?.physiciantype_value) {
      parsedValue['physiciantype'] = parsedValue?.physiciantype_value;
      delete parsedValue?.physiciantype_value;
    } else if (parsedValue?.physiciantype) {
      parsedValue['physiciantype'] = "";
      delete parsedValue?.physiciantype_value;
    }
    if (parsedValue?.primary_physician) {
      parsedValue['primary_physician'] = "Yes";
    } else if (parsedValue?.primary_physician === "") {
      parsedValue['primary_physician'] = "No";
    }
    if (parsedValue?.ivc_filter) {
      parsedValue['ivc_filter'] = 'Yes';
    } else if (parsedValue?.ivc_filter === '') {
      parsedValue['ivc_filter'] = 'No';
    }
    if (parsedValue?.sergery_impan) {
      parsedValue['surgery_impan'] = 'Yes';
    } else if (parsedValue?.sergery_impan === '') {
      parsedValue['surgery_impan'] = 'No';
    }
    delete parsedValue?.sergery_impan;
    if (parsedValue?.sergery_chronic) {
      parsedValue['surgery_chronic'] = parsedValue?.sergery_chronic;
      delete parsedValue?.sergery_chronic;
    }
    if (parsedValue?.sergery_hxhit) {
      parsedValue['surgery_hxhit'] = parsedValue?.sergery_hxhit;
      delete parsedValue?.sergery_hxhit;
    }
    console.log('parsedValue: ', parsedValue);
    return parsedValue;
  }, [selectedData]);

  const capitalizeAndReplaceUnderscore = (str) => {
    return str
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const formatDate = (date) => {
    if (!date) return "-";
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
    const day = String(formattedDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const formatModifiedDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0].replace(/-/g, "/");
  };
  
  const dateKey = ["date_of_birth", "modified", "created", "pres_startdate"];
  return (
    <Layout>
      <MainPageWrapper>
        <PawItemList {...props} />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description">
              <ul>
                {Object.entries(listData)?.map(([key, value]) => {
                  const updated = updatedValue[key];
                  const isUpdated =
                    updated && (updated.oldValue !== value || updated.newValue);
                  return (
                    <li
                      key={key}
                      style={{
                        paddingTop: "10px",
                        color: isUpdated ? "#2294d2" : "#313131",
                      }}
                    >
                      <strong style={{ color: "#313131" }}>
                        {capitalizeAndReplaceUnderscore(key)}:{" "}
                      </strong>
                      {dateKey.includes(key) ? formatDate(value) : value}
                    </li>
                  );
                })}
              </ul>
            </Typography>
          </Box>
        </Modal>
      </MainPageWrapper>
    </Layout>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #ddd",
  borderRadius: 3,
  p: 2,
};

import { Country, State } from "country-state-city";

export const getCountryAsOption = () => {
  const countries = Country.getAllCountries();
  const options = countries?.map((country) => {
    return {
      value: country.name,
      label: country.name,
      isoCode: country.isoCode,
    };
  });
  return options;
};

export const getStateAsOptions = (country) => {
  const statesOfCountry = State.getStatesOfCountry(country);
  return statesOfCountry.map((state) => {
    return {
      value: state.isoCode,
      label: state.name,
      code: state?.isoCode,
    };
  });
};

export const formatDate = (date) => {
  let targetDateFormatted = "";
  if (date) {
    // const options = Intl.DateTimeFormat().resolvedOptions();
    // const localTimezone = options.timeZone;
    // const inputDate = new Date(date);

    // // Set the target timezone
    // const newDate = inputDate?.toLocaleString(options.locale, {
    //   timeZone: localTimezone,
    // });

    // // Calculate the next day
    // // inputDate?.setDate(inputDate?.getDate() + 1);

    // // Format the date as "YYYY-MM-DD"
    targetDateFormatted = date.split("T")?.[0]?.replace(/-/g, "/");
    // targetDateFormatted = date;
  }
  return targetDateFormatted;
};

// export const formatPhoneNumber = (phoneNumberString) => {
//   const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
//   const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     const intlCode = match[1] ? "+1 " : "";
//     return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
//   }
//   return null;
// };

export const formatPhoneNumber = (phoneNumberString) => {
  if (phoneNumberString) {
    if (phoneNumberString.match(/^\+\d{1,2} \(\d{3}\) \d{3}-\d{4}$/)) {
      return phoneNumberString;
    }
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match?.[1] ? "+1 " : "";
      return [
        intlCode,
        "(",
        match?.[2],
        ") ",
        match?.[3],
        "-",
        match?.[4],
      ].join("");
    } else if (cleaned.length) {
      return [
        "(",
        cleaned.substring(0, 3),
        ") ",
        cleaned.substring(3, 6),
        "-",
        cleaned.substring(6),
      ].join("");
    }
    return phoneNumberString;
  }
};

export function deepJSONDifferences(oldObj, newObj) {
  function compareObjects(oldObj, newObj) {
    const differences = {};

    for (const key in newObj) {
      if (typeof newObj?.[key] === "object" && !Array.isArray(newObj?.[key])) {
        // Recursively compare nested objects
        const nestedDiff = compareObjects(oldObj?.[key], newObj?.[key]);
        if (Object.keys(nestedDiff)?.length > 0) {
          differences[key] = nestedDiff;
        }
      } else if (oldObj?.[key] !== newObj?.[key]) {
        differences[key] = {
          oldValue: oldObj?.[key],
          newValue: newObj?.[key],
        };
      }
    }

    return differences;
  }

  return compareObjects(oldObj, newObj);
}

export function addItemtoObject(item, propertiesToDelete = []) {
  const newData = item?.new_value?.replace(/\n/g, " ");
  const oldData = item?.old_value?.replace(/\n/g, " ");
  const updatedNewData = newData ? JSON.parse(newData) : {};
  const updatedOldData = oldData ? JSON.parse(oldData) : {};

  propertiesToDelete.forEach((property) => {
    delete updatedNewData[property];
    delete updatedOldData[property];
  });

  return {
    ...item,
    data: deepJSONDifferences(updatedOldData, updatedNewData),
  };
}

export const jsonToQueryString = (json) => {
  if (json.sort) {
    const [sortColumn, sortDirection] = json.sort.startsWith("-")
      ? [json.sort.slice(1), "desc"]
      : [json.sort, "asc"];
    // eslint-disable-next-line no-unused-expressions
    sortDirection === "asc"
      ? ((json.field = sortColumn), (json.order = "asc"))
      : ((json.field = sortColumn), (json.order = "desc"));
  }
  return `?${Object.keys(json)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
    })
    .join("&")}`;
};

export const filterAscDescData = (data, sort, from, limit) => {
  if (sort) {
    const [sortColumn, sortDirection] = sort.startsWith("-")
      ? [sort.slice(1), "desc"]
      : [sort, "asc"];
    data = data.sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "desc" ? bValue - aValue : aValue - bValue;
      } else {
        const stringA = String(aValue);
        const stringB = String(bValue);
        return sortDirection === "desc"
          ? stringB.localeCompare(stringA)
          : stringA.localeCompare(stringB);
      }
    });
  }
  // const filteredResult = data.slice(from, from + limit);
  return data;
};
export  const hexToRgba = (hex, alpha = 1) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const fullHex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
    : null;
};
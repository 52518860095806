import React, { useEffect, useMemo } from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Form, Formik } from "formik";
import swal from "sweetalert";
import ApiService from "../../../Api/ApiServices";
import { BuyAdditionalMembershipValidation } from "../../../../From/Validation";
import Layout from "../../Side bar/Layout";
import NumberOfMembers from "./NumberOfMembers";
import BillingInformationForm from "./BillingInformationForm";
import PaymentInfoForm from "./PaymentInfoForm";
import { useNavigate } from "react-router-dom";

function BuyAdditionalMembership() {
  const formRef = useRef();
  const navigate = useNavigate();

  const [steps, setSteps] = useState(0);

  const stripe = useStripe();
  const elements = useElements();
  // const { stripe, elements } = useStripe();
  const defaultValue = {
    firstname: "",
    email: "",
    password: "",
    phoneNumber: "",
    lastname: "",
    confirmpassword: "",
    code: "",
    firstNameB: "",
    streetAddB: "",
    CityB: "",
    zipCodeB: "",
    lastNameB: "",
    stateB: "",
    card: "",
    expiry: "",
    securityCode: "",
    fName: "",
    lName: "",
    address_line_1: "",
    address_line_2: "",
    admin_area_2: "",
    billing_postal_code: "",
    admin_area_1: "",
    nfoChecked: false,
    cardnumber: "",
    isValidMembershipNumber: false,
    numberOfMembers: "1",
    isBuyMembership: false,
    isAlreadyMembership: false,
    totalAmmount: 40,
    accessCode: "",
    shipping_phone_number: "",
    billing_phone_number: "",
  };
  const [initialValue, setInitialValue] = useState(defaultValue);

  const getMember = async () => {
    try {
      const { data } = await ApiService.accountInfo();
      setInitialValue({
        ...initialValue,
        firstname: data?.account?.user_fname,
        lastname: data?.account?.user_lname,
        email: data?.account?.user_email,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getMember();
  }, []);

  const registerUser = async (values, paymentMethod) => {
    try {
      const newUser = {
        count: values?.numberOfMembers,
        amount: values?.totalAmmount,
        account: {
          couponCode: values?.referralCode,
          user_email: values?.email,
          user_fname: values.firstname,
          user_lname: values.lastname,
          members: values?.numberOfMembers,
        },
        billingInfo: {
          expDateMonth: "",
          expDateYear: "",
          address1: values?.streetAddB,
          address2: values?.addressB,
          city: values?.CityB,
          firstName: values?.firstNameB,
          lastName: values?.lastNameB,
          phoneNumber: values?.billing_phone_code,
          state: values?.stateB,
          zipCode: values?.zipCodeB,
        },
        shippingInfo: {
          firstName: values?.fName,
          lastName: values?.lName,
          address1: values?.address_line_1,
          address2: values?.address_line_2,
          city: values?.admin_area_2,
          state: values?.admin_area_1,
          zipCode: values?.billing_postal_code,
          phoneNumber: values?.shipping_phone_code,
        },
        paymentMethod: paymentMethod?.paymentMethod,
      };
      await ApiService.upgradeMember(newUser);
      navigate("/myFootprintID");
    } catch (err) {
      if (err.code === "ERR_NETWORK") {
        swal("Network Error!", "Failed to connect server.", "error");
      } else if (err?.response?.data?.code === 500) {
        swal("Network Error!", err?.response?.data?.message, "error");
      }
    }
  };
  const handleSubmit = async (values, { setFieldError }) => {
    setSteps(steps + 1);
    window.scrollTo(0, 580);
  };

  const UpdateBillingInformation = async (values) => {
    try {
      const biilingPayload = {
        firstName: values?.fName,
        lastName: values?.lName,
        address1: values?.address_line_1,
        address2: values?.address_line_2,
        zipCode: values?.billing_postal_code,
        city: values?.admin_area_2,
        state: values?.admin_area_1,
        phoneNumber: values?.billing_phone_number,
      };
      const shippingPayload = {
        firstName: values?.firstNameB,
        lastName: values?.lastNameB,
        address1: values?.streetAddB,
        address2: values?.addressB,
        zipCode: values?.zipCodeB,
        city: values?.CityB,
        state: values?.stateB,
        phoneNumber: values?.shipping_phone_number,
      };
      await ApiService.updateBillingInfo(biilingPayload);
      await ApiService.updateShippingInfo(shippingPayload);
      setSteps(steps + 1);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const onSubmit = async (values, { setFieldError }) => {
    if (steps === 0) {
      handleSubmit(values, { setFieldError });
    } else if (steps === 1) {
      UpdateBillingInformation(values);
    } else if (steps === 2) {
      try {
        if (!stripe || !elements) {
          alert("NOT LOADED");
          return;
        }
        const cardNumberElement = elements.getElement(CardNumberElement);
        const result = await stripe.createPaymentMethod({
          type: "card",
          card: cardNumberElement,
          billing_details: {
            name: "",
          },
        });
        await registerUser(values, result, { setFieldError });
      } catch (err) {
        console.error(err, err.message);
      }
    } else {
      setSteps(steps + 1);
    }
  };

  const getStatedItem = useMemo(() => {
    switch (steps) {
      case 0:
        return <NumberOfMembers setInitialValue={setInitialValue} />;
      case 1:
        return (
          <BillingInformationForm
            onPrevious={() => setSteps(steps - 1)}
            setInitialValue={setInitialValue}
          />
        );
      case 2:
        return <PaymentInfoForm onPrevious={() => setSteps(steps - 1)} />;
      default:
        return <></>;
    }
  }, [steps]);

  return (
    <Layout>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          enableReinitialize={true}
          validationSchema={BuyAdditionalMembershipValidation[steps]}
          innerRef={formRef}
        >
          <Form>{getStatedItem}</Form>
        </Formik>
      </Wrapper>
    </Layout>
  );
}

export default BuyAdditionalMembership;

const Wrapper = styled.div`
  font-family: sans-serif;
  padding: 20px 0 100px 0px;
  text-align: center;
  min-height: 63vh;
  @media (max-width: 650px) {
    // padding: 140px 0 100px 0px;
  }
`;

import React, { useEffect, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import { RadioBtn } from "../../../Css";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { surgeriesAndTreatmentsValidation } from "./Validation";
import TextArea from "../Common/TextArea";
import { useTranslation } from "react-i18next";

const defaultValue = {
  surgery_date: null,
  surgery_type: "",
  physician_name: "",
  surgey_notes: "",
  ivc_filter: 0,
  ivc_filter_cal: null,
  surgery_pos: "",
  surgery_anes: "",
  sergery_impan: 0,
  implan_cal: null,
  impan_notes: "",
  sergery_chronic: "",
  chronic_cal: null,
  chronic_notes: "",
  sergery_hxhit: "",
  hxhit_cal: null,
  hxhit_notes: "",
};

export default function SurgeryandTreatmentForm({
  isEdit,
  data,
  setIsAdd,
  fetchAPIData,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const [physicianOptions, setPhysicianOptions] = useState([]);
  const { t } = useTranslation();

  const getPhysician = async () => {
    try {
      const { data } = await ApiService.getPresPhysician();

      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: `${item?.phy_fname} ${item?.phy_lname}`,
          value: `${item?.phy_fname} ${item?.phy_lname}`,
        }));
        setPhysicianOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };
  const YesNoOptions = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        ...initialValues,
        ...data,
      });
    } else {
      setInitialValues(defaultValue);
    }
  }, [isEdit, data]);

  useEffect(() => {
    getPhysician();
  }, []);

  const onSubmit = async (values) => {
    try {
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data.user_id,
        };
        const res = await ApiService.editSurgeriesTreatments(userId, payload);
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      } else {
        const { data } = await ApiService.addSurgeriesTreatments(
          userId,
          values
        );
        if (data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const scrollToError = () => {
    if (Object.keys(formikRef.current.errors).length > 0) {
      const firstErrorField = Object.keys(formikRef.current.errors)[0];
      const firstErrorFieldRef = document.getElementsByName(firstErrorField)[0];
      if (firstErrorFieldRef) {
        const topPosition =
          firstErrorFieldRef.getBoundingClientRect().top +
          window.pageYOffset -
          30;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  };

  return (
    <Box sx={(theme) => ({
      [theme.breakpoints.up('md')]: { padding: '20px' },
      [theme.breakpoints.down('md')]: { padding: '10px' }
    })}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={surgeriesAndTreatmentsValidation}
        validateOnMount={true}
        enableReinitialize
      >
        {({
          getFieldProps,
          values,
          setValues,
          isSubmitting,
          errors,
          handleSubmit,
        }) => (
          <Form>
            {console.log("values: ", values, errors)}
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid var(--radium-info-600)", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? `${t(
                        "screens.surgeriesTreatments.editSurgeriesTreatments"
                      )}`
                    : `${t(
                        "screens.surgeriesTreatments.addSurgeriesTreatments"
                      )}`}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={4} xs={12}>
                    <FormGroup
                      name="surgery_date"
                      label={`${t("screens.surgeriesTreatments.surgeryDate")}`}
                      requiredField
                    >
                      <InputField
                        type="date"
                        {...getFieldProps("surgery_date")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormGroup
                      name="surgery_type"
                      label={`${t(
                        "screens.surgeriesTreatments.surgeryProcedureType"
                      )}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("surgery_type")} />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormGroup
                      name="physician_name"
                      label={`${t(
                        "screens.surgeriesTreatments.physicianName"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("physician_name")}
                        name="physician_name"
                        options={physicianOptions}
                        placeholder=""
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item xs={12}>
                    <FormGroup
                      name="surgey_notes"
                      label={`${t("screens.surgeriesTreatments.notes")}:`}
                    >
                      <TextArea
                        rows={5}
                        inputProps={{ maxLength: "100%" }}
                        {...getFieldProps("surgey_notes")}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="ivc_filter"
                      label={`${t("screens.surgeriesTreatments.IVCFilter")}:`}
                      className="pysician-checkbox-label"
                    >
                      <RadioBtn>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setValues({
                              ...values,
                              ivc_filter: values.ivc_filter === 0 ? 1 : 0,
                            });
                          }}
                          checked={values.ivc_filter.toString() === "1"}
                        ></input>
                      </RadioBtn>
                    </FormGroup>
                  </Grid>
                  {values.ivc_filter ? (
                    <Grid item sm={3} xs={12}>
                      <FormGroup
                        name="ivc_filter_cal"
                        label={`${t("screens.surgeriesTreatments.calendar")}`}
                      >
                        <InputField
                          type="date"
                          {...getFieldProps("ivc_filter_cal")}
                        />
                      </FormGroup>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="surgery_pos"
                      label={`${t(
                        "screens.surgeriesTreatments.postComplication"
                      )}:`}
                    >
                      <TextArea
                        rows={5}
                        inputProps={{ maxLength: "50%" }}
                        {...getFieldProps("surgery_pos")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="surgery_anes"
                      label={`${t(
                        "screens.surgeriesTreatments.anthesiaComplication"
                      )}:`}
                    >
                      <TextArea
                        rows={5}
                        inputProps={{ maxLength: "50%" }}
                        {...getFieldProps("surgery_anes")}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="sergery_impan"
                      label={`${t(
                        "screens.surgeriesTreatments.implantableDevice"
                      )}:`}
                      className="pysician-checkbox-label"
                    >
                      <RadioBtn>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setValues({
                              ...values,
                              sergery_impan: values.sergery_impan === 0 ? 1 : 0,
                            });
                          }}
                          checked={values.sergery_impan.toString() === "1"}
                        ></input>
                      </RadioBtn>
                    </FormGroup>
                  </Grid>
                  {values.sergery_impan ? (
                    <>
                      <Grid item xs={12} sm={3}>
                        <FormGroup
                          name="implan_cal"
                          label={`${t("screens.surgeriesTreatments.calendar")}`}
                        >
                          <InputField
                            type="date"
                            {...getFieldProps("implan_cal")}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup
                          name="impan_notes"
                          label={`${t("screens.surgeriesTreatments.notes")}`}
                        >
                          <TextArea
                            rows={5}
                            inputProps={{ maxLength: 200 }}
                            {...getFieldProps("impan_notes")}
                          />
                        </FormGroup>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>

                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="sergery_chronic"
                      label={`${t(
                        "screens.surgeriesTreatments.chronicAnticogulation"
                      )}:`}
                    >
                      <AutoComplete
                        {...getFieldProps("sergery_chronic")}
                        name="sergery_chronic"
                        options={YesNoOptions}
                        placeholder=""
                      />
                    </FormGroup>
                  </Grid>
                  {values?.sergery_chronic === "Yes" ? (
                    <>
                      <Grid item xs={12} sm={3}>
                        <FormGroup
                          name="chronic_cal"
                          label={`${t("screens.surgeriesTreatments.calendar")}`}
                        >
                          <InputField
                            type="date"
                            {...getFieldProps("chronic_cal")}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup
                          name="chronic_notes"
                          label={`${t("screens.surgeriesTreatments.notes")}`}
                        >
                          <TextArea
                            rows={5}
                            inputProps={{ maxLength: 200 }}
                            {...getFieldProps("chronic_notes")}
                          />
                        </FormGroup>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="sergery_hxhit"
                      label={`${t("screens.surgeriesTreatments.HxHIT")}:`}
                    >
                      <AutoComplete
                        {...getFieldProps("sergery_hxhit")}
                        name="sergery_hxhit"
                        options={YesNoOptions}
                        placeholder=""
                      />
                    </FormGroup>
                  </Grid>
                  {values?.sergery_hxhit === "Yes" ? (
                    <>
                      <Grid item xs={12} sm={3}>
                        <FormGroup
                          name="hxhit_cal"
                          label={`${t("screens.surgeriesTreatments.calendar")}`}
                        >
                          <InputField
                            type="date"
                            {...getFieldProps("hxhit_cal")}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup
                          name="hxhit_notes"
                          label={`${t("screens.surgeriesTreatments.notes")}`}
                        >
                          <TextArea
                            rows={5}
                            inputProps={{ maxLength: 200 }}
                            {...getFieldProps("hxhit_notes")}
                          />
                        </FormGroup>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
              {/* </Grid> */}
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setIsAdd(false)}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    onClick={scrollToError}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.surgeriesTreatmentsUpdated")}`
              : `${t("screens.successMessage.surgeriesTreatmentsCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
          }}
        />
      )}
    </Box>
  );
}

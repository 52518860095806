import React from "react";

import { InputLabel } from "@mui/material";
import { useFormikContext } from "formik";
import { get } from "lodash";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";

function CustomPhoneInput({
  id,
  name,
  label,
  inputRef,
  sx,
  onBlur,
  onChange,
  width,
  required,
  onKeyPressRef,
  value,
  errorMessage,
  inputSx,
  style,
  ...params
}) {
  const { errors, touched, setFieldValue, handleBlur, setFieldTouched } =
    useFormikContext();
  const error = get(errors, name);
  const fieldTouched = get(touched, name);

  const handlePhoneBlur = (value) => {
    setFieldTouched(name, true);
    handleBlur(name)(value);
    onBlur && onBlur(value);
  };

  return (
    <>
      {label && (
        <InputLabel sx={{ ...inputstyle, ...inputSx }}>
          {label} {required ? "*" : ""}
        </InputLabel>
      )}
      <Country width={width}>
        <PhoneInput
          id={id}
          name={name}
          width={width}
          inputProps={{
            onKeyDown: (event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                onKeyPressRef?.current?.focus();
              }
            },
          }}
          inputRef={inputRef}
          country={"us"}
          enableAreaCodes={true}
          value={value}
          onChange={(newPhone) => {
            setFieldValue(name, newPhone);
            onChange && onChange(newPhone);
          }}
          inputStyle={{
            // background: "rgba(255, 255, 255, 0.8)",
            // background: "none",
            border: "rgba(255, 255, 255, 0.8)",
            // paddingTop: "0px",
            // height: "16px",
            // backgroundColor: "none",
            ...sx,
          }}
          onBlur={handlePhoneBlur}
          error={fieldTouched && error}
          {...params}
          containerStyle={{ position: "relative" }}
        />
      </Country>
    </>
  );
}

export default CustomPhoneInput;

const inputstyle = {
  color: "black",
  fontSize: "14px",
  padding: "2px 0",
};

const Country = styled.div`
  .react-tel-input .form-control {
    border: 1px solid var(--radium-info-600) !important;
    border-radius: 6px !important;
    // height: 38px !important;
    width: ${(props) => props.width || "100% !important"};
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
    background: #fff !important;
  }

  .react-tel-input :disabled {
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
  }

  @media (max-width: 1220px) {
    .react-tel-input .form-control {
      width: 100% !important;
    }
  }
  @media (max-width: 900px) {
    .react-tel-input .form-control {
      width: 100% !important;
    }
  }
  @media (max-width: 600px) {
    .react-tel-input .form-control {
      width: 100% !important;
    }
  }

  .react-tel-input .flag-dropdown {
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: transparent !important;
    border-radius: 3px 0 0 3px;
  }

  input:-internal-autofill-selected {
    background-image: none !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: fieldtext !important;
  }

  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background: transparent !important;
  }

  .react-tel-input .flag-dropdown.open {
    border: 1px solid var(--radium-info-600) !important;
  }
    .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background-color: rgba(237, 239, 241, 0.933);
    }
`;

import React, { memo, useMemo } from "react";

import { useFormikContext } from "formik";
import { get } from "lodash";

function InputField({
  id,
  name,
  label,
  autoComplete = "off",
  sx,
  helperText,
  onBlur,
  onChange,
  width,
  required,
  value,
  errorMessage,
  inputSx,
  boxStyle,
  hasError,
  InputProps,
  className,
  ...params
}) {
  const { errors, touched } = useFormikContext();
  
  // Use useMemo to prevent unnecessary recalculations
  const error = useMemo(() => get(errors, name), [errors, name]);
  const fieldTouched = useMemo(() => get(touched, name), [touched, name]);
  
  // Memoize the style to prevent recreating the object on each render
  const inputStyle = useMemo(() => ({
    borderColor: error && fieldTouched ? '#DC3838' : 'var(--text-primary-800)'
  }), [error, fieldTouched]);
  
  return (
    <>
      <div>
        <input
          id={id}
          name={name}
          autoComplete={autoComplete}
          width={width}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          error={fieldTouched && error}
          className={className}
          {...params}
          style={inputStyle}
        />
      </div>
    </>
  );
}

export default memo(InputField);

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isEqual } from "lodash";
import useSWR from "swr";

import "./GlobalItemList.css";
import useAuth from "../../ContextProvider/AuthProvider";
import { filterAscDescData, hexToRgba } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button, Typography } from "@mui/material";
import PawDataView from "../Common/PawDataView.js";
import { ReactComponent as EditIcon } from "../../Assets/Images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/DeleteIcon.svg";
import ApiService from "../../Api/ApiServices.js";
import Layout from "../Side bar/Layout.js";
import AllergiesForm from "../Forms/AllergiesForm.js";
import { MainPageWrapper } from "./GlobalItemList";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function Allergy() {
  const { userId } = useAuth();
  const [filter, setFilter] = useState({});
  const [allergy, setAllergyData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [allergiesNameOptions, setAllergiesNameOptions] = useState([]);
  const [allergiesTypeOptions, setAllergiesTypeOptions] = useState([]);

  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const fetchIdRef = useRef(0);
  const { t } = useTranslation();

  const {
    data = [],
    mutate: fetchAPIData,
    isLoading,
  } = useSWR(
    userId ? `Allergy${userId}` : null,
    async () => {
      const { data } = await ApiService.getAlleregies(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { sort } = filter;
    if (filter && filter.from > -1) {
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort);
      setAllergyData(filteredResult);
    }
  }, [filter, data]);

  const handleView = (item) => {
    setIsShow(true);
    setSelectedData(item);
    setIsAdd(false);
    setIsEdit(false);
  };

  const addOnclick = () => {
    setIsAdd(true);
    setSelectedData({});
    setIsShow(false);
    setIsEdit(false);
  };

  const getAllergiesName = async (value) => {
    try {
      const { data } = await ApiService.getAllergiesNames(value);

      const mappedData = data.map((item) => ({
        label: item?.field_value,
        value: item?.id,
      }));
      setAllergiesNameOptions(mappedData);
      return mappedData;
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const getAllergiesTypes = async () => {
    try {
      const { data } = await ApiService.getAllergiesTypes();

      const mappedData = data.map((item) => ({
        label: item?.field_value,
        value: item?.id,
      }));
      setAllergiesTypeOptions(mappedData);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    getAllergiesTypes();
  }, []);

  const handleEdit = async (item) => {
    let nameData = [];
    if (item?.allergytype) {
      nameData = await getAllergiesName(item?.allergytype);
    }
    setIsEdit(true);
    setSelectedData(item);
    setIsAdd(true);
    setIsShow(false);
  };
  const theme = useTheme();
  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.allergies.allergyType")}`,
        accessor: "allergytype_value",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.allergies.allergyName")}`,
        accessor: "allergyname_value",
        width: "12.5vw",
        Cell: ({ row }) => {
          return (
            <Typography
              sx={{
                padding: "5px",
                cursor: "pointer",
                fontSize: "12px",
                fontFamily:'verdana, sans-serif',
              }}
              onClick={() => handleView(row.original)}
            >
              {row.original?.allergytype_value === "Other"
                ? row.original?.allergyname
                : row.original?.allergyname_value}
            </Typography>
          );
        },
      },
      {
        Header: `${t("screens.allergies.reaction")}`,
        textAlign: "center",
        accessor: "allergyreaction",
        width: "12.5vw",
      },
      // {
      //   Header: "View",
      //   accessor: "view",
      //   width: "4vw",
      //   textAlign: "center",
      //   disableSortBy: true,
      //   Cell: ({ row }) => {
      //     return (
      //       <Button
      //         sx={{
      //           minWidth: "40px",
      //           background: "rgba(255, 133, 51, 0.20) !important",
      //           borderRadius: "10px",
      //           marginRight: "5px",
      //           width: "24px",
      //         }}
      //         onClick={() => handleView(row.original)}
      //       >
      //         <img
      //           alt="view"
      //           src={viewIcon}
      //           style={{
      //             color: "#000000",
      //             cursor: "pointer",
      //           }}
      //         />
      //       </Button>
      //     );
      //   },
      // },
      {
        Header: `${t("screens.formData.edit")}`,
        accessor: "edit",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              color="info"  
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleEdit(row.original)}
            >
               <EditIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.delete")}`,
        accessor: "delete",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
             color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => setAskConfirmation(row?.original)}
            >
                           <DeleteIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);


  const props = {
    label: `${t("screens.profile.allergies")}`,
    isLoading: isLoading,
    columns,
    columnsData: allergy,
    details: " There are no allergy details for this user.",
    addOnclick: addOnclick,
    fetchData,
  };

  const handleDelete = async () => {
    try {
      if (askConfirmation) {
        const { data } = await ApiService.deleteAllergies(
          userId,
          askConfirmation
        );
        if (data?.OK) {
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const viewProps = {
    label: `${t("screens.allergies.allergyInformation")}`,
    askConfirmation,
    setAskConfirmation,
    onDelete: handleDelete,
    isShow,
    detailsData: [
      [
        {
          name: `${t("screens.allergies.allergyType")}`,
          value: selectedData?.allergytype,
        },
        {
          name: `${t("screens.allergies.allergyName")}`,
          value: selectedData?.allergyname_value,
        },
        {
          name: `${t("screens.allergies.reaction")}`,
          value: selectedData?.allergyreaction,
        },
      ],
    ],
  };

  return (
    <Layout>
      <MainPageWrapper>
        <PawItemList {...props} />
        <PawDataView {...viewProps} />
        {isAdd && (
          <AllergiesForm
            setIsAdd={setIsAdd}
            isEdit={isEdit}
            data={selectedData}
            fetchAPIData={fetchAPIData}
            allergiesNameOptions={allergiesNameOptions}
            setAllergiesNameOptions={setAllergiesNameOptions}
            allergiesTypeOptions={allergiesTypeOptions}
            getAllergiesName={getAllergiesName}
          />
        )}
      </MainPageWrapper>
    </Layout>
  );
}

import { api, apiAxiosFormData } from "./axios";

const ApiService = {
  userLogin: (values) => api.post("login", values),
  personalInfoDelete: async (id) => {
    const { data } = await api.get(`profile/${id}/personalInfo`);
    return api.post(`profile/${id}/delete/personalInfo`, data[0]);
  },
  login: (values) => api.post("emergency_login", values),
  getSelfInfo: async (id) => api.get(`profile/member/${id}`),
  getMembersByUserId: async (id) => api.get(`/members/${id}`),
  getMembers: async () => {
    try {
      const { data } = await api.get("members");
      return { data };
    } catch (e) {
      console.log("e: ", e);
    }
  },
  getPersonalInfo: async (id) => {
    try {
      const { data } = await api.get(`profile/${id}/personalInfo`);
      return { data: data[0] };
    } catch (error) {}
  },
  primaryLanguageList: async () => await api.get("primarylanguage"),
  hairColorList: async () => await api.get("haircolor"),
  eyeColorList: async () => await api.get("eyecolor"),
  bloodTypeList: async () => await api.get("bloodtype"),
  identifyingMarksList: async () => await api.get("identifying_marks"),
  prostheticsList: async () => await api.get("prosthetics"),
  religiousList: async () => await api.get("religious"),
  ethnicityList: async () => await api.get("ethnicity"),
  maritalStatusList: async () => await api.get("marital_status"),
  getPersonalInformation: async (id) => {
    const res = await api.get(`/profile/${id}/personalInfo`);
    return res;
  },
  addPersonalInfo: async (id, body) =>
    await api.put(`profile/${id}/personalInfo`, body),
  editPersonalInfo: async (id, body) =>
    await api.post(`profile/${id}/personalInfo`, body),

  deletePersonalInfo: async (id, body) =>
    await api.post(`profile/${id}/delete/personalInfo`, body),

  relationsEmergency: async () => await api.get("relations"),
  stateList: async () => await api.get("states"),
  addEmergency: async (id, body) =>
    await api.put(`profile/${id}/emergencyContacts`, body),
  editEmergency: async (id, body) =>
    await api.post(`profile/${id}/emergencyContacts`, body),

  deleteEmergency: async (id, body) =>
    await api.post(`profile/${id}/delete/emergencyContacts`, body),
  getAllEmergencyUserId: async (id) => {
    const res = await api.get(`profile/${id}/emergencyContacts`);
    return res;
  },
  getPhysiciansAndTherapists: async (id) => {
    const { data } = await api.get(`profile/${id}/physiciansTherapists`);
    return { data };
  },
  getPhysicianTypes: async () => await api.get("physiciantypes"),
  addPhysiciansAndTherapists: async (id, body) =>
    await api.put(`profile/${id}/physiciansTherapists`, body),
  editPhysiciansAndTherapists: async (id, body) =>
    await api.post(`profile/${id}/physiciansTherapists`, body),

  deletePhysiciansAndTherapists: async (id, body) =>
    await api.post(`profile/${id}/delete/physiciansTherapists`, body),
  getMedicalInsurance: async (id) => {
    const { data } = await api.get(`profile/${id}/medicalInsurance`);
    return { data };
  },
  addMedicalInsurance: async (id, body) =>
    await api.put(`profile/${id}/medicalInsurance`, body),
  editMedicalInsurance: async (id, body) =>
    await api.post(`profile/${id}/medicalInsurance`, body),
  deleteMedicalInsurance: async (id, body) =>
    await api.post(`profile/${id}/delete/medicalInsurance`, body),
  getPrescriptionAndMidication: async (id) => {
    const { data } = await api.get(`profile/${id}/prescriptionsMedications`);
    return { data };
  },
  getPrescriptionTypes: async () => await api.get("pres_medicationtype"),
  getPresDosageTypes: async () => await api.get("pres_dosage"),
  getPresFrequencyTypes: async () => await api.get("pres_frequency"),
  getPresFrequency2: async () => await api.get("frequency2"),
  getPresPhysician: async () => await api.get("profile/physiciansTherapists"),
  addPrescription: async (id, body) =>
    await api.put(`profile/${id}/prescriptionsMedications`, body),
  editPrescription: async (id, body) =>
    await api.post(`profile/${id}/prescriptionsMedications`, body),
  deletePrescription: async (id, body) =>
    await api.post(`profile/${id}/delete/prescriptionsMedications`, body),

  getAlleregies: async (id) => {
    const { data } = await api.get(`profile/${id}/allergies`);
    return { data };
  },
  getAllergiesTypes: async () => await api.get("allergytype"),
  getAllergiesNames: async (id) => await api.get(`allergyname/${id}`),
  addAllergies: async (id, body) =>
    await api.put(`profile/${id}/allergies`, body),
  editAllergies: async (id, body) =>
    await api.post(`profile/${id}/allergies`, body),
  deleteAllergies: async (id, body) =>
    await api.post(`profile/${id}/delete/allergies`, body),

  getConditions: async (id) => {
    const { data } = await api.get(`profile/${id}/conditions`);
    return { data };
  },
  addConditions: async (id, body) =>
    await api.put(`profile/${id}/conditions`, body),
  editConditions: async (id, body) =>
    await api.post(`profile/${id}/conditions`, body),
  deleteConditions: async (id, body) =>
    await api.post(`profile/${id}/delete/conditions`, body),
  getSurgeriesTreatments: async (id) => {
    const { data } = await api.get(`profile/${id}/surgeriesTreatments`);
    return { data };
  },
  addSurgeriesTreatments: async (id, body) =>
    await api.put(`profile/${id}/surgeriesTreatments`, body),
  editSurgeriesTreatments: async (id, body) =>
    await api.post(`profile/${id}/surgeriesTreatments`, body),
  deleteSurgeriesTreatments: async (id, body) =>
    await api.post(`profile/${id}/delete/surgeriesTreatments`, body),
  getImmunizations: async (id) => {
    const { data } = await api.get(`profile/${id}/immunizations`);
    return { data };
  },
  // deleteFamilyHistory: async (id, body) => {
  //   const { data } = await api.get<PersonalInfoType[]>(`profile/${id}/personalInfo`);
  //   return api.post(`profile/delete/familyHistory`, body);
  // },

  deleteFamilyHistory: async (id, body) =>
    await api.post(`profile/${id}/delete/familyHistory`, body),

  getVaccineslist: async () => await api.get("vaccineslist"),
  addImmunizations: async (id, body) =>
    await api.put(`profile/${id}/immunizations`, body),
  editImmunizations: async (id, body) =>
    await api.post(`profile/${id}/immunizations`, body),
  deleteImmunizations: async (id, body) =>
    await api.post(`profile/${id}/delete/immunizations`, body),

  editFamilyHistory: async (id, body) =>
    await api.post(`profile/${id}/familyHistory`, body),

  addFamilyHistory: async (id, body) =>
    await api.put(`profile/${id}/familyHistory`, body),

  getFamilyHistory: async (id) => {
    const { data } = await api.get(`profile/${id}/familyHistory`);
    return { data };
  },
  getDocuments: async (id, params = null) => {
    const { data } = await api.get(`profile/${id}/documents${params ? params : ''}`);
    return { data };
  },
  uploadDocument: async (body) =>
    await apiAxiosFormData.post(`fileUpload`, body),
  addDocuments: async (id, body) =>
    await api.put(`profile/${id}/documents`, body),
  editDocuments: async (id, body) =>
    await api.post(`profile/${id}/documents`, body),
  deleteDocuments: async (id, body) =>
    await api.post(`profile/${id}/delete/documents`, body),
  getDocumentspdf: async (id) => {
    const { data } = await api.get(`profile/document/${id}`);
    return { data };
  },
  getHistory: async (id, page) => {
    return await api.get(`profile/history/${page}`);
  },
  getHistoryByUserId: async (userId, page) => {
    return await api.get(`profile/${userId}/history/${page}`);
  },
  getHistoryByDates: async (id, startDate, endDate) => {
    const { data } = await api.get(
      `profile/${id}/history/${startDate}/${endDate}`
    );
    return data;
  },
  checkActiveSession: async () => await api.get(`checkActiveSession`),
  getShippingInfo: async () => await api.get(`shippingInfo`),
  changePassword: async (body) => await api.post(`changePassword`, body),
  checkMembershipCard: async (body) =>
    await api.post(`checkMembershipCard`, body),
  checkCouponCode: async (code) => await api.get(`coupon/${code}`),
  checkEmailAlreadyExits: async (body) =>
    await api.post(`checkAvailabilityEmail`, body),
  userRegister: async (body) => await api.post(`userRegister`, body),
  accountInfo: async () => await api.get(`accountInfo`),
  getMembershipCards: async () => await api.get(`getMembershipCards`),
  getShippingInfo: async () => await api.get(`shippingInfo`),
  updateShippingInfo: async (body) => await api.put(`updateShippingInfo`, body),
  getBillingInfo: async () => await api.get(`billingInfo`),
  updateBillingInfo: async (body) => await api.put(`updateBillingInfo`, body),
  upgradeMember: async (body) => await api.post(`upgradeMember`, body),
  userRegisterTags: async (body) => await api.post(`userRegisterTags`, body),
  forgetPassword: async (body) => await api.post(`forgetPwd`, body),
  addMember: async (body) => await api.post(`addMember`, body),
  getRoles: async () => await api.get(`roles`),
  getMember: async (id) => api.get(`member/${id}`),
  updateMember: async (id, payload) => api.post(`member/${id}`, payload),
  setRecoveryPassword: async (payload) =>
    api.post(`password-recovery`, payload),
  checkAvailabilityEmail: async (payload) =>
    api.post(`checkAvailabilityEmail`, payload),
  getActivationkey: async (activationkey, uid) => {
    const res = await api.get(`/thankyou/${activationkey}/${uid}`);
    return res;
  }
};

export default ApiService;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isEqual } from "lodash";
import useSWR from "swr";

import "./GlobalItemList.css";
import { ReactComponent as ViewIcon } from "../../Assets/Images/viewIcon.svg";
import useAuth from "../../ContextProvider/AuthProvider";
import { filterAscDescData, formatDate, hexToRgba } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";
import PawDataView from "../Common/PawDataView.js";
import { ReactComponent as EditIcon } from "../../Assets/Images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/DeleteIcon.svg";
import EmergencyContactsForm from "../Forms/EmergencyContactsForm.js";
import ApiService from "../../Api/ApiServices.js";
import Layout from "../Side bar/Layout.js";
import FamilyHistoryForm from "../Forms/FamilyHistoryForm.js";
import moment from "moment";
import { MainPageWrapper } from "./GlobalItemList.js";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function FamilyMembers() {
  const { userId } = useAuth();
  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [familyMembersData, setFamilyMembersData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const fetchIdRef = useRef(0);
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    data = [],
    mutate: fetchAPIData,
    isLoading,
  } = useSWR(
    userId ? `FamilyMembers${userId}` : null,
    async () => {
      const { data } = await ApiService.getFamilyHistory(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("data: ", data);
    const { limit, from, sort } = filter;
    if (filter && filter.from > -1) {
      if (data?.length && limit) {
        setPageCount(Math.ceil(data?.length / limit));
      }
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort, from, limit);
      setFamilyMembersData(filteredResult);
    }
  }, [filter, data]);

  const handleView = (item) => {
    setIsShow(true);
    setSelectedData(item);
    setIsAdd(false);
    setIsEdit(false);
  };

  const addOnclick = () => {
    setIsAdd(true);
    setSelectedData({});
    setIsShow(false);
    setIsEdit(false);
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setSelectedData(item);
    setIsAdd(true);
    setIsShow(false);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.familyHistory.familyMember")}`,
        accessor: "family_member",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.familyHistory.deceased")}`,
        accessor: "deceased",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.formData.view")}`,
        accessor: "view",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
             color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleView(row.original)}
            >
               <ViewIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.edit")}`,
        accessor: "edit",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              color="info"
              sx={{
                minWidth: "40px",
                background:`${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleEdit(row.original)}
            >
              <EditIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.delete")}`,
        accessor: "delete",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
             color="info"
              sx={{
                minWidth: "40px",
                background:`${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => setAskConfirmation(row?.original)}
            >
               <DeleteIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

 

  const props = {
    label: `${t("screens.profile.familyHistory")}`,
    isLoading: isLoading,
    columns,
    columnsData: familyMembersData,
    details: " There are no Family History details for this user.",
    addOnclick: addOnclick,
    fetchData,
  };

  const handleDelete = async () => {
    try {
      if (askConfirmation) {
        const { data } = await ApiService.deleteFamilyHistory(
          userId,
          askConfirmation
        );
        if (data?.OK) {
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const viewProps = {
    label: `${t("screens.familyHistory.familyHistoryInformation")}`,
    askConfirmation,
    setAskConfirmation,
    onDelete: handleDelete,
    isShow,
    detailsData: [
      [
        {
          name: `${t("screens.familyHistory.familyMember")}`,
          value: selectedData?.family_member,
        },
        {
          name: `${t("screens.familyHistory.familyMemberOther")}`,
          value: selectedData?.family_mem_other,
        },
        {
          name: `${t("screens.familyHistory.deceased")}`,
          value: selectedData?.deceased ? selectedData?.deceased : "No",
        },
        {
          name: `${t("screens.familyHistory.dateofBirth")}`,
          value:
            selectedData?.date_of_birth &&
            formatDate(selectedData?.date_of_birth),
        },
        {
          name: `${t("screens.familyHistory.conditions")}`,
          value:
            selectedData?.conditions &&
            JSON?.parse(selectedData?.conditions)?.join(","),
        },
        {
          name: `${t("screens.familyHistory.status")}`,
          value: selectedData?.status,
        },
      ],
    ],
  };

  return (
    <Layout>
      <MainPageWrapper>
        <PawItemList {...props} />
        <PawDataView {...viewProps} />
        {isAdd && (
          <FamilyHistoryForm
            setIsAdd={setIsAdd}
            isEdit={isEdit}
            data={selectedData}
            fetchAPIData={fetchAPIData}
          />
        )}
      </MainPageWrapper>
    </Layout>
  );
}

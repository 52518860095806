import React, { memo, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import ApiService from "../../Api/ApiServices";
import {
  ADMIN_ROLE,
  LOGIN_USER_ROLE_KEY,
  POWER_USER_ROLE,
  USER_ROLE_KEY,
} from "../../Constant";
import useAuth from "../../ContextProvider/AuthProvider";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

const MemberDropDown = memo(function MemberDropDown() {
  const { setUserId, setUser, user } = useAuth();
  const [members, setMembers] = useState([]);
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const loginUserRole = localStorage.getItem(LOGIN_USER_ROLE_KEY);
  const { t, i18n } = useTranslation();

  const { data: memberlist = [], isLoading } = useSWR(
    `getMemberslist`,
    async () => {
      try {
        const { data } = await ApiService.getMembers();
        if (data?.members?.length) {
          const filteredMembers = data.members?.map((item) => {
            return {
              label: `${item?.user_fname} ${item?.user_lname}`,
              value: item?.user_id,
            };
          });
          setMembers(data?.members);
          return filteredMembers || [];
        }
        return [];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const handleMemberChange = async (e) => {
    const memberId = e;
    try {
      setUserId(memberId);
      const { data } = await ApiService.getSelfInfo(memberId);
      localStorage.setItem(USER_ROLE_KEY, data?.user_role);
      const userData = members?.find((x) => x?.user_id === memberId);
      setUser(userData);
    } catch (error) {}
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectMember = (value) => {
    handleMemberChange(value);
    setDropdownOpen(false);
  };

  return (
    <>
      {(loginUserRole === ADMIN_ROLE || loginUserRole === POWER_USER_ROLE) && (
        <div className="dropdown" ref={dropdownRef}>
          {!isLoading && !memberlist?.length ? (
            <div
              style={{
                background: "#d9edf7",
                color: "#31708f",
                padding: "10px 20px",
              }}
            >
              <span>
                {t("screens.rightSidebar.text.noMembershipAssign")}
                <Link to="/myFootprintID">
                  {" "}
                  {t("screens.rightSidebar.text.gotoMyFootprint")}
                </Link>
              </span>
            </div>
          ) : (
            <>
              {" "}
              <div
                // type="button"
                // className="btn btn-primary dropdown-toggle ng-binding btn-member"
                // data-bs-toggle="dropdown"
                // aria-expanded="true"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={toggleDropdown}
              >
                <Image className="text-center">
                  {user?.avatar ? (
                    <img src={user?.avatar} alt="logo" />
                  ) : (
                    <img src="/assets/images/profile.jpg" alt="logo" />
                  )}
                </Image>
                {/* Members
                <span className="caret"></span> */}
                <ExpandMoreIcon />
              </div>
              <ul
                className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}
                role="menu"
                id="dropdownMenuShow"
              >
                {memberlist?.length > 0 ? (
                  memberlist?.map((item, index) => (
                    <li key={index} className="ng-scope">
                      <a
                        href="/" target="_self"
                        onClick={() => selectMember(item.value)}
                        className=" ng-binding"
                      >
                        {item?.label}
                      </a>
                    </li>
                  ))
                ) : (
                  <li className="ng-scope">No Members Found</li>
                )}
              </ul>
            </>
          )}
        </div>
      )}
    </>
  );
});

export default MemberDropDown;

const Image = styled.div`
  img {
    width: 100%;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
`;

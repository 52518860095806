import {Grid } from "@mui/material";
import React from "react";

export default function ParagraphCard() {
  return (
    <div
      ng-controller="weekendTravelingCarousel"
      className="slick-slder-data weekend-traveling-section ng-scope"
    >
      <div className="swiper weekendSwiper swiper-fade swiper-initialized swiper-horizontal swiper-watch-progress swiper-backface-hidden">
        <div
          className="swiper-wrapper"
          id="swiper-wrapper-5496d11b7e2d2b70"
          aria-live="off"
          style={{ cursor: "grab" }}
        >
          <div
            className="swiper-slide ng-scope swiper-slide-prev"
            ng-repeat="(key,data ) in peaceData"
            role="group"
            aria-label="1 / 3"
          >
            <Grid container spacing={2} className="img-content" sx={{width:'auto',marginLeft:'auto'}}>
              <Grid item lg={3} md={4} sm={6}>
              <div className="home-control-img home-control-img-data">
                <img
                  ng-src="assets/images/friends.jpg"
                  className="img-carousel friends-image"
                  src="assets/images/friends.jpg"
                  alt=""
                />
                </div>
              </Grid>
              <Grid item lg={9} md={8} sm={6}>
              <div className="table-carousel-left">
                  <p className="table-carousel-inner ng-binding">
                    Have you ever had a problem while traveling for business or
                    pleasure? I have been in that situation before and I do not
                    want to be unprepared again. With FootprintlD, you well be
                    able to travel with peace of mind and have access to the
                    medical information that you need when you need it most
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import { Button } from "@mui/material";

import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { documentsValidation } from "./Validation";
import viewIcon from "../../Assets/Images/pdfIcon.png";
import { useTranslation } from "react-i18next";
import { hexToRgba } from "../../Utils";
import { useTheme } from "@mui/material/styles";
import { getCategoryDocument } from "../../../api/axios";

const defaultValue = {
  docum_date: "",
  docum_name: "",
  docum_file: "",
  category: "",
  sub_category:"",
};

export default function DocumentsForm({
  isEdit,
  data,
  setIsAdd,
  fetchAPIData,
  handleView,
  selectedCategory,
  selectedSubCategory,
  selectedCategoryData,
}) {
  console.log('selectedCategory: ', selectedCategory);
  console.log('data: ', data);
  
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const formikRef = useRef();
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        ...initialValues,
        ...data,
        emer_relation_id: data?.emer_relation_id?.toString(),
        // category: data?.sub_category_id ? `sub_${data?.sub_category_id}` : (data?.category_id ? `cat_${data?.category_id}` : selectedCategory ?? ""),
        category:data?.category_id ? `cat_${data?.category_id}` : selectedCategory ?? "",
       sub_category:data?.sub_category_id ? `sub_${data?.sub_category_id}` : selectedSubCategory ?? '',
      });
    } else {
      setInitialValues({...defaultValue,
        category: selectedCategory,
        sub_category:selectedSubCategory,
      });
    }
  }, [isEdit, data]);

  const handleOnChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      formikRef?.current?.setFieldValue("docum_file", file);
    }
  };

  const getCategoryDocumentSelect = async () => {
    try {
      const { data: selectData } = await getCategoryDocument();
      console.log("selectData: ", selectData);
      setCategoryData(selectData?.data);
    } catch (error) {
      setCategoryData([]);
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    getCategoryDocumentSelect();
  }, []);

  // getCategoryDocument
  const onSubmit = async (values) => {
    try {
      let fileName = "";
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const uploadDocumentResponse = await ApiService.uploadDocument(
          formData
        );
        const filePath = uploadDocumentResponse?.data?.src;
        if (filePath) {
          fileName = filePath.substring(filePath.lastIndexOf("files/") + 6);
        }
      }
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data.user_id,
          sub_category_id: values.sub_category?.includes('sub_') ? values.sub_category.replace('sub_','') : null,
          category_id: values.category?.includes('cat_') ? values.category.replace('cat_','') : null
        };
        if (fileName) {
          payload.docum_file = fileName;
        }

        const res = await ApiService.editDocuments(userId, payload);
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          // fetchAPIData();
        }
      } else {
        const payload = {
          ...values,          
          user_id: data.user_id,
          sub_category_id: values.sub_category?.includes('sub_') ? values.sub_category?.replace('sub_','') : null,
          category_id: values.category?.includes('cat_') ? values.category?.replace('cat_','') : null
        };
        if (fileName) {
          payload.docum_file = fileName;
          const { data } = await ApiService.addDocuments(userId, payload);
          if (data?.OK) {
            setOpen(true);
            setShowSuccessMessage(true);
            // fetchAPIData();
          }
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up("md")]: { padding: "20px 0" },
        [theme.breakpoints.down("md")]: { padding: "10px 0" },
      })}
    >
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={documentsValidation}
        validateOnMount={false}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting, setFieldValue }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box
                sx={{
                  borderBottom: "1px solid var(--radium-info-600)",
                  width: "100%",
                }}
              >
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? `${t("screens.documents.editDocuments")}`
                    : `${t("screens.documents.addDocuments")}`}
                </Typography>
              </Box>
              <Grid  container spacing={2} columns={12} direction="row">
              <Grid item  xl={3} lg={4} sm={6} xs={12} sx={{ marginTop: 0 }}>
                <FormGroup
                  name="category"
                  label={`${t("screens.documents.selectCategory")}`}
                  requiredField
                  labelClassName={{ marginBottom: "9px" }}
                >
                  <Select
                    {...getFieldProps("category")}
                    native
                    
                    id="grouped-native-select"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        padding: "5.5px 40px 5.5px 14px !important", // Extra padding for text
                        height: "20px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid var(--radium-info-600)",
                      },
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "var(--radium-info-600)",
                        },
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "var(--radium-info-600)",
                      },
                      "& .MuiSvgIcon-root": {
                        right: "8px", // Proper arrow positioning
                        pointerEvents: "none",
                      },
                    }}
                  >
                    <option aria-label="None" value="" label="Select Catgory" />
                    {categoryData?.length > 0 &&
                      categoryData?.map((cat) => (
                        <option key={cat?.id} value={`cat_${cat?.id}`}>
                          {cat?.category}
                        </option>
                      ))}
                  </Select>
                </FormGroup>
              </Grid>

                {categoryData.filter(o => o.id == values?.category?.replace('cat_', ''))?.length > 0 &&
                  <Grid item xl={3} lg={4}  sm={6} xs={12} sx={{ marginTop: 0 }}>
                    <FormGroup
                      name="sub_category"
                      label={`${t("screens.documents.selectSubCategory")}`}
                      requiredField
                      labelClassName={{ marginBottom: '9px' }}
                    >
                      <Select
                        {...getFieldProps("sub_category")}
                        native
                        defaultValue=""
                        id="grouped-native-select"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-input": {
                            padding: "5.5px 40px 5.5px 14px !important", // Extra padding for text to avoid overlapping with arrow
                            height: "20px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "white",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid var(--radium-info-600)",
                          },
                          "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "var(--radium-info-600)",
                            },
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "var(--radium-info-600)",
                          },
                          "& .MuiSvgIcon-root": {
                            right: "8px", // Keep arrow aligned properly
                            pointerEvents: "none",
                          },
                        }}
                      >
                        <option aria-label="None" value="all" label="All" />
                        {categoryData?.filter(o => o.id == values?.category?.replace('cat_', ''))?.map((cat) => (
                          <>
                            {cat?.sub_category?.length > 0 &&
                              cat?.sub_category?.map((sub) => (
                                <option key={sub?.id} value={`sub_${sub?.id}`}>{sub?.name}</option>
                              ))}
                          </>
                        ))}
                      </Select>
                    </FormGroup>
                  </Grid>
                }

                <Grid item  xs={12} lg={4} sm={6} xl={`${categoryData.filter(o=>o.id == values?.category?.replace('cat_', ''))?.length > 0 ? 2 : 3}`}  sx={{marginTop:0}}>
                <FormGroup
                      name="docum_date"
                      label={`${t("screens.documents.date")}`}
                      requiredField
                    >
                      <InputField
                        {...getFieldProps("docum_date")}
                        type="date"
                        inputProps={{ max: "9999-12-12" }}
                      />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} lg={4} sm={6} xl={3}  sx={{marginTop:0}}>
                <FormGroup
                      name="docum_name"
                      label={`${t("screens.documents.documentName")}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("docum_name")} />
                 </FormGroup>
                </Grid>
                <Grid item  xs={12} lg={4}  sm={6} xl={`${categoryData.filter(o=>o.id == values?.category?.replace('cat_', ''))?.length > 0 ? 3 : 3}`}  sx={{marginTop:0}} className="contained-button-file">
                <FormGroup
                      name="docum_file"
                      label={`${t("screens.documents.documentFile")}`}
                      requiredField
                    >
                      <input
                        id="contained-button-file"
                        name="docum_file"
                        type="file"
                        onChange={handleOnChange}
                      />
                    </FormGroup>
                </Grid>
              </Grid>
              {/* <div style={{ width: "100%" }}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={2} xs={12} sx={{margin:'auto',marginTop:0}}>
                    <FormGroup
                      name="category"
                      label={`${t("screens.documents.selectCategory")}`}
                      requiredField
                      labelClassName={{marginBottom: '9px'}}
                    >
                      <Select
                        {...getFieldProps("category")}
                        native
                        defaultValue=""
                        id="grouped-native-select"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-input": {
                            padding: "5.5px 14px !important",
                            height:' 20px',
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "white",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                               border: "1px solid var(--radium-info-600)",
                           },
                           "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "var(--radium-info-600)",
                            },
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "var(--radium-info-600)",
                          },
                        }}
                      >
                        <option aria-label="None" value="all" label="All"/>
                        {categoryData?.length > 0 &&
                          categoryData?.map((cat) => (
                            <>
                              <option value={`cat_${cat?.id}`}>{cat?.category}</option>
                            </>
                          ))}
                      </Select>
                    </FormGroup>
                  </Grid>
                  {categoryData.filter(o=>o.id == values?.category?.replace('cat_', ''))?.length > 0 &&
                  <Grid item sm={2} xs={12} sx={{margin:'auto',marginTop:0}}>
                  <FormGroup
                      name="sub_category"
                      label={`${t("screens.documents.selectSubCategory")}`}
                      requiredField
                    >
                      <Select
                        {...getFieldProps("sub_category")}
                        native
                        defaultValue=""
                        id="grouped-native-select"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-input": {
                            padding: "5.5px 14px !important",
                            height:' 20px',
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "white",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                               border: "1px solid var(--radium-info-600)",
                           },
                           "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "var(--radium-info-600)",
                            },
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "var(--radium-info-600)",
                          },
                        }}
                      >
                        <option aria-label="None" value="all" label="All"/>
                        {
                          categoryData?.filter(o=>o.id == values?.category?.replace('cat_', ''))?.map((cat) => (
                            <>
                              {cat?.sub_category?.length > 0 &&
                                cat?.sub_category?.map((sub) => (
                                  <option value={`sub_${sub?.id}`}>{sub?.name}</option>
                                ))}
                            </>
                          ))}
                      </Select>
                    </FormGroup>
                    </Grid>}
                  <Grid item sm={2} xs={12}>
                    <FormGroup
                      name="docum_date"
                      label={`${t("screens.documents.date")}`}
                      requiredField
                    >
                      <InputField
                        {...getFieldProps("docum_date")}
                        type="date"
                        inputProps={{ max: "9999-12-12" }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={2} xs={12}>
                    <FormGroup
                      name="docum_name"
                      label={`${t("screens.documents.documentName")}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("docum_name")} />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={2} xs={12} className="contained-button-file">
                    <FormGroup
                      name="docum_file"
                      label={`${t("screens.documents.documentFile")}`}
                      requiredField
                    >
                      <input
                        id="contained-button-file"
                        name="docum_file"
                        type="file"
                        onChange={handleOnChange}
                      />
                    </FormGroup>
                  </Grid>
                  {values?.id && (
                    <Grid item sm={1} xs={12}>
                      <Button
                        sx={{
                          minWidth: "40px",
                          background: `${hexToRgba(
                            theme.palette.info.main,
                            0.1
                          )} !important`,
                          borderRadius: "10px",
                          marginRight: "5px",
                          width: "24px",
                        }}
                        onClick={() => handleView(values)}
                      >
                        <img
                          alt="view"
                          src={viewIcon}
                          style={{
                            color: "#000000",
                            cursor: "pointer",
                          }}
                        />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </div>
          */}
            
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setIsAdd(false)}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.documentUpdated")}`
              : `${t("screens.successMessage.documentCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
            fetchAPIData();
          }}
        />
      )}
    </Box>
  );
}

import React, { useEffect } from "react";

function ResourcePg() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="article-main-sections" id="home-video-main">
      <h6 className="latest-news-style latest-news-title"> News</h6>
      <div
        className="resources-inner-sections ng-scope"
        ng-controller="resourcesData"
      >
        <div
          ng-repeat="(key,data ) in resourcesItems"
          className="article-inner-block border-top-orange"
        >
          <div>
            <h2 className="article-inner-title ng-binding">
              Elevating Concierge Services with Personal Portable Health Records
            </h2>
            <p className="article-inner-description ng-binding">
              In the digital age, where convenience and personalization
              converge, luxury travel seeks a new standard of excellence. At the
              heart of this evolution is the Personal Portable Health Record
              (PPHR), a tool that not only enriches the traveler's experience
              but also amplifies the allure of concierge services.
            </p>
          </div>
          <div className="article-inner-btn">
            <a
              href="https://www.linkedin.com/pulse/elevating-concierge-services-personal-portable-health-records-z3alc%3FtrackingId=F9GTSY8LrT4O%252F%252FOoRB5w%252Fw%253D%253D/?trackingId=F9GTSY8LrT4O%2F%2FOoRB5w%2Fw%3D%3D"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn" type="button">
                Read More
              </button>
            </a>
          </div>
        </div>
        <div
          ng-repeat="(key,data ) in resourcesItems"
          className="article-inner-block border-top-sky-blue"
        >
          <div>
            <h2 className="article-inner-title ng-binding">
              Empowering Health Literacy with FootprintID®
            </h2>
            <p className="article-inner-description ng-binding">
              Health Literacy Month shines a spotlight on the importance of
              understanding health information and making informed health
              decisions. It's a time to reflect on how we, as individuals and
              communities, navigate the complex world of healthcare. With the
              advent of digital health tools like FootprintID, the path to
              health literacy has become more accessible and empowering.
            </p>
          </div>
          <div className="article-inner-btn">
            <a
              href="https://www.linkedin.com/pulse/empowering-health-literacy-footprintid-footprintid-qqffc%3FtrackingId=wMNzrmkH3wdroFbE%252F%252BcBww%253D%253D/?trackingId=wMNzrmkH3wdroFbE%2F%2BcBww%3D%3D"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn" type="button">
                Read More
              </button>
            </a>
          </div>
        </div>
      </div>
      <div>
        <h6 className="latest-news-title">Blogs</h6>
        <div
          className="resources-inner-sections ng-scope"
          ng-controller="blogsData"
        >
          <div
            ng-repeat="(key,data ) in resourcesItems"
            className="article-inner-block border-top-green"
          >
            <div>
              <h2 className="article-inner-title ng-binding">
                Beth Tofel of FootprintID® on Her Inspiration, Entrepreneurship,
                and Keeping Employees Motivated
              </h2>
              <p className="article-inner-description ng-binding">
                In this captivating interview with ValiantCEO Magazine, Beth
                Tofel, President of FootprintID®, shares the personal story that
                sparked the creation of her innovative company.
              </p>
            </div>
            <div className="article-inner-btn">
              <a
                href="https://valiantceo.com/beth-tofel-footrpintid/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn" type="button">
                  Read More
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h6 className="latest-news-title">Helpful resources</h6>
        <div
          className="resources-inner-sections ng-scope"
          ng-controller="helpfulData"
        >
          <div
            ng-repeat="(key,data ) in resourcesItems"
            className="article-inner-block border-top-black"
          >
            <div>
              <h2 className="article-inner-title ng-binding">
                Travel experts share their predictions for 2023’s best bets
              </h2>
              <p className="article-inner-description ng-binding">
                Consumers are making their 2023 travel plans like there’s no
                tomorrow. Various reports show that everything from car rentals
                to domestic flights to international travel are taking advantage
                of Americans' desire to get back out and enjoy the post-pandemic
                world.
              </p>
            </div>
            <div className="article-inner-btn">
              <a
                href="https://www.consumeraffairs.com/news/travel-experts-share-their-predictions-for-2023s-best-bets-012323.html"
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn" type="button">
                  Read More
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourcePg;

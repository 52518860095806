import { useEffect } from "react";

import ReactGA from "react-ga4";

const usePageTracking = () => {

  useEffect(() => {
    if (process.env?.REACT_APP_MEASUREMENT_ID) {
      ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID,{
        gaOptions: {
          debug_mode: true,
        },
        gtagOptions: {
          debug_mode: true,
        },
      });
    // ReactGA.set({ userId: "" });
    ReactGA.send({ hitType: "pageview", page: window.location?.pathname + window.location.search });
    }
  }, [window.location]);
};

export default usePageTracking;

import axios from "axios";

console.log('process.env.REACT_APP_CMS_BACKEND_BASE_URL', process.env.REACT_APP_CMS_BACKEND_BASE_URL);

function getSubdomain() {
  const hostname = window.location.hostname; // Get the hostname (e.g., "stage.localhost")
  const parts = hostname.split('.'); // Split it by '.'

  if (parts.length > 1) {
      return parts.slice(0, parts.length - 2).join('.');
  }
  return process.env.REACT_APP_DEFAULT_DOMAIN; 
}

console.log('getSubdomain', getSubdomain());

export const api = axios.create({
  baseURL: process.env.REACT_APP_CMS_BACKEND_BASE_URL,
  //baseURL: "http://ec2-44-213-168-246.compute-1.amazonaws.com:8000/",
  headers: {
    hostname: getSubdomain(),
  },
});
// export const getAllBlogs = async () => {
//   const res = await api.get(`/resources/getAllBlogs`);
//   return res;
// };

// export const getAllBlogsByPartner = async () => {
//   const res = await api.get(`/resources/getAllBlogsByPartner`);
//   return res;
// };

// export const getContactUs = async () => {
//   const res = await api.get(`/contactUs/getAllContacts`);
//   return res;
// };

// export const getButtonByPartner = async () => {
//   const res = await api.get(`/button/getAllButtonByPartner`);
//   return res;
// };

// //home page
// export const getHomeHeader = async () => {
//   const res = await api.get(`/home/getAllHomeHeader`);
//   return res;
// };

// export const getHomeSlider1 = async () => {
//   const res = await api.get(`/home/getAllHomeSliderOne`);
//   return res;
// };

// export const getHomeSlider2 = async () => {
//   const res = await api.get(`/home/getAllHomeSliderTwo`);
//   return res;
// };

// export const getHomeParagraph = async () => {
//   const res = await api.get(`/home/getAllHomeParagraph`);
//   return res;
// };

// export const getHomeOverview = async () => {
//   const res = await api.get(`/home/getAllHomeService`);
//   return res;
// };
// export const getActivationkey = async (activationkey, uid) => {
//   const res = await api.get(`/thankyou/${activationkey}/${uid}`);
//   return res;
// };
//theme
export const setThemeSetting = async () => {
  const res = await api.get(`/partner/getDomainContent`);
  return res;
};
export const getCategoryDocument = async (params='') => await api.get(`/category/get-all-category${params}`);
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isEqual } from "lodash";
import useSWR from "swr";

import "./GlobalItemList.css";
import viewIcon from "../../Assets/Images/viewIcon.svg";
import useAuth from "../../ContextProvider/AuthProvider";
import { filterAscDescData, hexToRgba } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";
import PawDataView from "../Common/PawDataView.js";
import { ReactComponent as EditIcon } from "../../Assets/Images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/DeleteIcon.svg";
import ApiService from "../../Api/ApiServices.js";
import Layout from "../Side bar/Layout.js";
import ConditionsForm from "../Forms/ConditionsForm.js";
import { MainPageWrapper } from "./GlobalItemList.js";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function Conditions() {
  const { userId, user } = useAuth();
  const [filter, setFilter] = useState({});
  const [conditionData, setConditionData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const fetchIdRef = useRef(0);
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    data = [],
    mutate: fetchAPIData,
    isLoading,
  } = useSWR(
    userId ? `Conditions${userId}` : null,
    async () => {
      const { data } = await ApiService.getConditions(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { sort } = filter;
    if (filter && filter.from > -1) {
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort);
      setConditionData(filteredResult);
    }
  }, [filter, data]);

  const handleView = (item) => {
    console.log("item: ", item);
    setIsShow(true);
    setSelectedData(item);
    setIsAdd(false);
    setIsEdit(false);
  };

  const addOnclick = () => {
    setIsAdd(true);
    setSelectedData({});
    setIsShow(false);
    setIsEdit(false);
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setSelectedData(item);
    setIsAdd(true);
    setIsShow(false);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.conditions.condition")}`,
        accessor: "cond_info",
        width: "12.5vw",
        textAlign: "center",
      },
      {
        Header: `${t("screens.conditions.conditionNotes")}`,
        accessor: "cond_notes",
        width: "12.5vw",
        textAlign: "center",
      },
      // {
      //   Header: "View",
      //   accessor: "view",
      //   width: "4vw",
      //   textAlign: "center",
      //   disableSortBy: true,
      //   Cell: ({ row }) => {
      //     return (
      //       <Button
      //         sx={{
      //           minWidth: "40px",
      //           background: "rgba(255, 133, 51, 0.20) !important",
      //           borderRadius: "10px",
      //           marginRight: "5px",
      //           width: "24px",
      //         }}
      //         onClick={() => handleView(row.original)}
      //       >
      //         <img
      //           alt="view"
      //           src={viewIcon}
      //           style={{
      //             color: "#000000",
      //             cursor: "pointer",
      //           }}
      //         />
      //       </Button>
      //     );
      //   },
      // },
      {
        Header: `${t("screens.formData.edit")}`,
        accessor: "edit",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleEdit(row.original)}
            >
              <EditIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.delete")}`,
        accessor: "delete",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
             color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => setAskConfirmation(row?.original)}
            >
             <DeleteIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);


  const props = {
    label: `${t("screens.profile.conditions")}`,
    isLoading: isLoading,
    columns,
    columnsData: conditionData,
    details: " There are no conditions details for this user.",
    addOnclick: addOnclick,
    fetchData,
  };

  const handleDelete = async () => {
    try {
      if (askConfirmation) {
        const { data } = await ApiService.deleteConditions(
          userId,
          askConfirmation
        );
        if (data?.OK) {
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const viewProps = {
    label: `${t("screens.conditions.conditionsInformation")}`,
    askConfirmation,
    setAskConfirmation,
    onDelete: handleDelete,
    isShow,
    detailsData: [
      [
        {
          name: `${t("screens.conditions.condition")}`,
          value: selectedData?.cond_info,
        },
        {
          name: `${t("screens.conditions.conditionNotes")}`,
          value: selectedData?.cond_notes,
        },
      ],
    ],
  };

  return (
    <Layout>
      <MainPageWrapper>
        <PawItemList {...props} />
        <PawDataView {...viewProps} />
        {isAdd && (
          <ConditionsForm
            setIsAdd={setIsAdd}
            isEdit={isEdit}
            data={selectedData}
            fetchAPIData={fetchAPIData}
          />
        )}
      </MainPageWrapper>
    </Layout>
  );
}

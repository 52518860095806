import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isEqual } from "lodash";
import useSWR from "swr";

import "./GlobalItemList.css";
import useAuth from "../../ContextProvider/AuthProvider";
import { filterAscDescData, formatPhoneNumber, hexToRgba } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";
import PawDataView from "../Common/PawDataView.js";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/DeleteIcon.svg";
import ApiService from "../../Api/ApiServices.js";
import Layout from "../Side bar/Layout.js";
import MedicalInsuranceForm from "../Forms/MedicalInsuranceForm.js";
import { MainPageWrapper } from "./GlobalItemList.js";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as ViewIcon } from "../../Assets/Images/viewIcon.svg";
import { ReactComponent as EditIcon } from "../../Assets/Images/EditIcon.svg";

export default function MedicalInsurance() {
  const { userId } = useAuth();
  const [filter, setFilter] = useState({});
  const [medicalData, setmedicalData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const fetchIdRef = useRef(0);
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    data = [],
    mutate: fetchAPIData,
    isLoading,
  } = useSWR(
    userId ? `MedicalInsurance${userId}` : null,
    async () => {
      const { data } = await ApiService.getMedicalInsurance(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { sort } = filter;
    if (filter && filter.from > -1) {
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort);
      setmedicalData(filteredResult);
    }
  }, [filter, data]);

  const handleView = (item) => {
    console.log("item: ", item);
    setIsShow(true);
    setSelectedData(item);
    setIsAdd(false);
    setIsEdit(false);
  };

  const addOnclick = () => {
    setIsAdd(true);
    setSelectedData({});
    setIsShow(false);
    setIsEdit(false);
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setSelectedData(item);
    setIsAdd(true);
    setIsShow(false);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.medicalInsurance.memberName")}`,
        accessor: "ins_member",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.medicalInsurance.subscriberName")}`,
        accessor: "ins_subcriber",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.medicalInsurance.insuranceProvider")}`,
        accessor: "ins_provider",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.formData.view")}`,
        accessor: "view",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              color="info"
              sx={{
                minWidth: "40px",
                background:   `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleView(row.original)}
            >
               <ViewIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.edit")}`,
        accessor: "edit",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleEdit(row.original)}
            >
              <EditIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.delete")}`,
        accessor: "delete",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => setAskConfirmation(row?.original)}
            >    <DeleteIcon
            style={{
              fill: `${theme.palette.info.main}`, 
              cursor: "pointer",
            }}
            alt="view"
          />
            </Button>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);


  const props = {
    label: `${t("screens.profile.medicalInsurance")}`,
    isLoading: isLoading,
    columns,
    columnsData: medicalData,
    details: " There are noMedical Insurance details for this user.",
    addOnclick: addOnclick,
    fetchData,
  };

  const handleDelete = async () => {
    try {
      if (askConfirmation) {
        const { data } = await ApiService.deleteMedicalInsurance(
          userId,
          askConfirmation
        );
        if (data?.OK) {
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const viewProps = {
    label: `${t("screens.medicalInsurance.medicalInsuranceInformation")}`,
    askConfirmation,
    setAskConfirmation,
    onDelete: handleDelete,
    isShow,
    detailsData: [
      [
        {
          name: `${t("screens.medicalInsurance.memberName")}`,
          value: selectedData?.ins_member,
        },
        {
          name: `${t("screens.medicalInsurance.subscriberName")}`,
          value: selectedData?.ins_subcriber,
        },
        {
          name: `${t("screens.medicalInsurance.insuranceProviderName")}`,
          value: selectedData?.ins_provider,
        },
        {
          name: `${t("screens.medicalInsurance.insuranceProviderPhone")}`,
          value: selectedData?.ins_providerphone
            ? formatPhoneNumber(selectedData?.ins_providerphone)
            : "",
        },
        {
          name: `${t("screens.medicalInsurance.memberID")}`,
          value: `${selectedData?.ins_memberid}`,
        },
        {
          name: `${t("screens.medicalInsurance.insurancePlan")}`,
          value: selectedData?.ins_plan,
        },
        {
          name: `${t("screens.medicalInsurance.group")}`,
          value: selectedData?.ins_group,
        },
        {
          name: `${t("screens.medicalInsurance.effectiveDate")}`,
          value: selectedData?.ins_effective_date,
        },
      ],
    ],
  };

  return (
    <Layout>
      <MainPageWrapper>
        <PawItemList {...props} />
        <PawDataView {...viewProps} />
        {isAdd && (
          <MedicalInsuranceForm
            setIsAdd={setIsAdd}
            isEdit={isEdit}
            data={selectedData}
            fetchAPIData={fetchAPIData}
          />
        )}
      </MainPageWrapper>
    </Layout>
  );
}

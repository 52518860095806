import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isEqual } from "lodash";
import useSWR from "swr";

import "./GlobalItemList.css";
import { ReactComponent as ViewIcon } from "../../Assets/Images/viewIcon.svg";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  filterAscDescData,
  formatDate,
  formatPhoneNumber,
  hexToRgba,
} from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";
import PawDataView from "../Common/PawDataView.js";
import { ReactComponent as EditIcon } from "../../Assets/Images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/DeleteIcon.svg";
import ApiService from "../../Api/ApiServices.js";
import Layout from "../Side bar/Layout.js";
import PhysicianTherapistForm from "../Forms/PhysicianTherapistForm.js";
import { MainPageWrapper } from "./GlobalItemList";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function PhysicianTherapist() {
  const { userId } = useAuth();
  const [filter, setFilter] = useState({});
  const [PhysiciansAndTherapist, setPhysiciansAndTherapistData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const fetchIdRef = useRef(0);
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    data = [],
    mutate: fetchAPIData,
    isLoading,
  } = useSWR(
    userId ? `PhysiciansAndTherapist${userId}` : null,
    async () => {
      const { data } = await ApiService.getPhysiciansAndTherapists(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { sort } = filter;
    if (filter && filter.from > -1) {
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort);
      setPhysiciansAndTherapistData(filteredResult);
    }
  }, [filter, data]);

  const handleView = (item) => {
    setIsShow(true);
    setSelectedData(item);
    setIsAdd(false);
    setIsEdit(false);
  };

  const addOnclick = () => {
    setIsAdd(true);
    setSelectedData({});
    setIsShow(false);
    setIsEdit(false);
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setSelectedData(item);
    setIsAdd(true);
    setIsShow(false);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.physiciansTherapists.physicianType")}`,
        accessor: "physician_value",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.formData.firstName")}`,
        accessor: "phy_fname",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.formData.lastName")}`,
        accessor: "phy_lname",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.formData.view")}`,
        accessor: "view",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
             color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleView(row.original)}
            >
              <ViewIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.edit")}`,
        accessor: "edit",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
            color="info"
              sx={{
                minWidth: "40px",
                background:   `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleEdit(row.original)}
            >
             <EditIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.delete")}`,
        accessor: "delete",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => setAskConfirmation(row?.original)}
            >
              
              <DeleteIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

 

  const props = {
    label: `${t("screens.profile.physiciansTherapists")}`,
    isLoading: isLoading,
    columns,
    columnsData: PhysiciansAndTherapist,
    details: " There are no physician and therapist details for this user.",
    addOnclick: addOnclick,
    fetchData,
  };

  const handleDelete = async () => {
    try {
      if (askConfirmation) {
        const { data } = await ApiService.deletePhysiciansAndTherapists(
          userId,
          askConfirmation
        );
        if (data?.OK) {
          fetchAPIData();
        }
      }
    } catch (error) {}
  };

  const viewProps = {
    label: `${t("screens.physiciansTherapists.physicianTherapistInformation")}`,
    askConfirmation,
    setAskConfirmation,
    onDelete: handleDelete,
    isShow,
    detailsData: [
      [
        {
          name: `${t("screens.formData.fullName")}`,
          value: `${selectedData?.phy_fname} ${selectedData?.phy_lname}`,
        },
        {
          name: `${t("screens.formData.email")}`,
          value: selectedData?.phy_email,
        },
        {
          name: `${t("screens.physiciansTherapists.fax")}`,
          value: selectedData?.phy_fax
            ? formatPhoneNumber(selectedData?.phy_fax)
            : "",
        },
        {
          name: `${t("screens.formData.zipCode")}`,
          value: selectedData?.phy_zipcode,
        },
        {
          name: `${t("screens.formData.primaryPhone")}`,
          value: selectedData?.phy_primaryphone
            ? formatPhoneNumber(selectedData?.phy_primaryphone)
            : "",
        },
        {
          name: `${t("screens.physiciansTherapists.primaryPhysician")}`,
          value: selectedData?.primary_physician === 0 ? "No" : "Yes",
        },
        {
          name: `${t("screens.physiciansTherapists.primaryHospital")}`,
          value: selectedData?.phy_primary_hospital,
        },
      ],
      [
        {
          name: `${t("screens.formData.streetAddress")}`,
          value: selectedData?.phy_address1,
        },
        {
          name: `${t("screens.formData.address2")}`,
          value: selectedData?.phy_address2,
        },
        {
          name: `${t("screens.formData.city")}`,
          value: selectedData?.phy_city,
        },
        {
          name: `${t("screens.formData.state")}`,
          value: selectedData?.phy_state,
        },
        {
          name: `${t("screens.formData.zipCode")}`,
          value: selectedData?.phy_zipcode,
        },
        {
          name: `${t("screens.formData.country")}`,
          value: selectedData?.phy_country,
        },
        {
          name: `${t("screens.physiciansTherapists.physicianType")}`,
          value: selectedData?.physician_value,
        },
        {
          name: `${t("screens.physiciansTherapists.physicianOther")}`,
          value: selectedData?.physician_other || "-",
        },
        {
          name: `${t("screens.physiciansTherapists.lastAppointmentDate")}`,
          value: selectedData?.phy_last_appoin_date
            ? formatDate(selectedData?.phy_last_appoin_date)
            : "-",
        },
        {
          name: `${t("screens.physiciansTherapists.nextAppointmentDate")}`,
          value: selectedData?.phy_next_appoin_date
            ? formatDate(selectedData?.phy_next_appoin_date)
            : "-",
        },
      ],
    ],
  };

  return (
    <Layout>
      <MainPageWrapper>
        <PawItemList {...props} />
        <PawDataView {...viewProps} />
        {isAdd && (
          <PhysicianTherapistForm
            setIsAdd={setIsAdd}
            isEdit={isEdit}
            data={selectedData}
            fetchAPIData={fetchAPIData}
          />
        )}
      </MainPageWrapper>
    </Layout>
  );
}

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Menutool from "../Header/Menutool";
import useAuth from "../../ContextProvider/AuthProvider";
import { USER_ROLE_KEY } from "../../Constant";
import ProfileDropDown from "../Common/MyProfileDropdown";
import MemberDropDown from "../Common/MemberDropdown";
import { config } from "../../config";

export default function NewHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const tooltipRef = useRef();
  const { user } = useAuth();
  const role = localStorage.getItem(USER_ROLE_KEY);
  const { sidebar } = config;

  function handleClick(event) {
    setIsOpen(!isOpen);
    event.preventDefault();
  }
  function handleClickOutside(event) {
    if (
      ref?.current &&
      !ref?.current?.contains(event.target) &&
      !tooltipRef?.current?.contains(event.target)
    ) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      topmenu=""
      className="navbar-header-top navbar-header-shadow"
      ng-hide="hideHeader"
    >
      <header
        ng-controller="TopMenuController"
        id="home"
        className="header layout-site-header navbar navbar-inverse ng-scope"
      >
        <MainDiv
          className="container-main container-fluid header-foodts-sections"
        >
          {/* <div ng-if="!menu.show" className="navbar-header ng-scope">
            <a className="navbar-brand" href="/">
              <img
                alt="logo"
                className="img-fluid"
                src="/assets/images/headerlogo.png"
              />
            </a>
          </div> */}
            <HamburgerWrapper onClick={(event) => handleClick(event)} ref={ref}>
              <HamburgerIcon>
                <MenuItem>
                  <img
                    src={require("../../../Assets/Images/Navbar/hamburger-menu.png")}
                    width={30}
                    height={30}
                    alt=""
                  />
                </MenuItem>
              </HamburgerIcon>
            </HamburgerWrapper>
          <div style={{ display: "flex", gap: 10 }}>
            <div id="mainmenu" ng-controller="navDetails" className=" ng-scope">
              <ul className="navbar-nav">
                <MemberDropDown />
              </ul>
            </div>
            <div>
              <UserLabel>
                {user?.user_fname} {user?.user_lname}
              </UserLabel>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "grey",
                }}
              >
                {role}
              </div>
            </div>
          
            <ProfileDropDown />
          </div>
        </MainDiv>
      </header>
      <Menutool isOpen={isOpen} SidebarItems={sidebar} />
    </div>
  );
}
const MainDiv = styled.div`
justify-content: end;
 padding: 15px 25px ;
   @media (max-width: 890px) {
    justify-content: space-between;
  }
`;

const HamburgerWrapper = styled.div`
  display: none;
  @media (max-width: 890px) {
    display: block; 
  }
`;

const HamburgerIcon = styled.div`
  // background: #008fd3;
  align-items: center;
  text-align: center;
  // margin-top: 7px;
  margin-right: 10px;
  border-radius: 5px;
`;

const MenuItem = styled.div`
  // padding: 5px 10px;
  font-size: 14px;
  text-align: left;

  // :hover {
  //   background: #333;
  // }
`;

const UserLabel = styled.div`
   font-size: 16px;
  font-weight: 600;
   @media (max-width: 350px) {
     font-size: 12px; 
  }
`;
import "../../Assets/Styles/AboutUs/AboutUs.css";
import React, { useEffect } from "react";
import MeetTeam from "../../Components/AboutUs/MeetTeam";
import { Link } from "react-router-dom";

function AboutUS() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid ng-scope">
      <div className="why-footprint-component-sections">
        <section className="first-sections why-footprint-component">
          <div className="row row-flex">
            <div className="col-md-4">
              <p className="pace-mind-sections-title">
                Peace of mind{" "}
                <span className="text-orange-600">Every&nbsp;</span>
                <span className="text-sky-blue">time,</span>
                <span className="text-orange-600">Every</span>
                <div className="text-sky-blue">where</div>
              </p>
            </div>
            <div className="col-md-8">
              <div className="place-carry-copy">
                Why carry around a hard-copy file of your medical history when
                you can do better? FootprintID® makes it easier to keep track of
                your personal health data , reports and imaging and make sure it
                gets shared with the people who really need to see it.
              </div>
              <div className="btn-resources">     
                 <Link target="_self" to={"/resources"}>
                   <button className="btn-deep-orange">Explore Resources</button>
                  </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="third-sections">
          <h3 className="third-sections-title text-center">
            Our<span> Mission</span>
          </h3>
          <p className="third-inner-sections">
            Our mission is to make it easy to access your medical and health
            information when and how you need it. We accomplish this by
            providing members with a secure, simple and user friendly portal
            where they can store their health records and share them with other
            who can help them in times of need.
          </p>
        </section>
        <section className="four-sections ng-scope" ng-controller="howWorkData">
          <h3 className="four-sections-title text-center">
            How <span>FootprintID®</span> Works
          </h3>
          <div className="four-section-inner-block">
            <h5 className="four-inner-title">
              If you or your loved ones ever have an emergency, you want and
              need to be able to communicate with medical personnel. FootprintlD
              makes it easy: It's simple:
            </h5>
          </div>
          <div className="how-work-work-data">
            <div
              className="how-we-work-card ng-scope"
              ng-repeat="(key,data ) in informationData"
            >
              <div className="work-style ng-binding"> 1</div>
              <img
                ng-src="assets/svg/populate.svg"
                className="how-work-img"
                src="assets/svg/populate.svg"
                alt=""
              />
              <div className="how-we-work-label ng-binding">
                Populate your info
              </div>
            </div>
            <div
              className="how-we-work-card ng-scope"
              ng-repeat="(key,data ) in informationData"
            >
              <div className="work-style ng-binding"> 2</div>
              <img
                ng-src="assets/images/downloadApp.jpg"
                className="how-work-img"
                src="assets/images/downloadApp.jpg"
                alt=""
              />
              <div className="how-we-work-label ng-binding">
                Update your documents
              </div>
            </div>
            <div
              className="how-we-work-card ng-scope"
              ng-repeat="(key,data ) in informationData"
            >
              <div className="work-style ng-binding"> 3</div>
              <img
                ng-src="assets/images/updateDocument.jpg"
                className="how-work-img"
                src="assets/images/updateDocument.jpg"
                alt=""
              />
              <div className="how-we-work-label ng-binding">
                Download the mobile app
              </div>
            </div>
          </div>
        </section>
        <section className="five-sections">
          <h3 className="five-sections-title text-center">
            Meet the <span>Team</span>
          </h3>
          <div className="description-block">
            The FOOOTPRINTID® team is dedicated to innovating and providing
            simple, easy-to-use feature and solutions for accessing and managing
            health information. Having convenient access to health data is
            essential for both emergencies and routine healthcare. This kind of
            innovation can greatly improve the overall healthcare experience and
            help individuals and families make more informed decisions about
            their well-being
          </div>
          <MeetTeam />
        </section>
        <section className="six-sections text-center">
          <div className="need-us-title">
            <p>
              There when you <span className="text-skyblue-600">need us</span>
            </p>
          </div>
          <div className="btn-resources">
             <Link target="_self" to={"/contactus"}>
             <button className="btn-deep-orange">Get in Touch</button>
             </Link>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AboutUS;

import React, { useEffect, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { allergiesValidation } from "./Validation";
import TextArea from "../Common/TextArea";
import { useTranslation } from "react-i18next";

const defaultValue = {
  allergytype: "",
  allergytype_value: "",
  allergyname: "",
  allergyname_value: "",
  allergyreaction: "",
};

export default function AllergiesForm({
  isEdit,
  data,
  setIsAdd,
  fetchAPIData,
  allergiesNameOptions,
  setAllergiesNameOptions,
  allergiesTypeOptions,
  getAllergiesName,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const { t } = useTranslation();

  console.log("data: ", data);
  const handleInitialData = async () => {
    if (isEdit) {
      setInitialValues({
        ...initialValues,
        ...data,
        allergyname_value: data?.allergyname_value?.toString(),
      });
    } else {
      setInitialValues(defaultValue);
    }
  };

  useEffect(() => {
    handleInitialData();
  }, [isEdit, data]);

  const onSubmit = async (values) => {
    try {
      if (values?.allergyname) {
        values.allergyname_value = allergiesNameOptions.find((data) => {
          if (data?.value === values.allergyname) {
            return data.label;
          }
        })?.label;
      }
      if (values?.allergytype) {
        values.allergytype_value = allergiesTypeOptions.find((data) => {
          if (data?.value === values.allergytype) {
            return data.label;
          }
        })?.label;
      }
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data.user_id,
        };
        const res = await ApiService.editAllergies(userId, payload);
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      } else {
        const { data } = await ApiService.addAllergies(userId, values);
        if (data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Box sx={(theme) => ({
      [theme.breakpoints.up('md')]: { padding: '20px' },
      [theme.breakpoints.down('md')]: { padding: '10px' }
    })}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={allergiesValidation}
        validateOnMount={false}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid var(--radium-info-600)", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? `${t("screens.allergies.editAllergies")}`
                    : `${t("screens.allergies.addAllergies")}`}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={4} xs={12}>
                    <FormGroup
                      name="allergytype"
                      label={`${t("screens.allergies.allergyType")}`}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("allergytype")}
                        name="allergytype"
                        onChange={(e, item) => getAllergiesName(item?.value)}
                        isOnChange
                        options={allergiesTypeOptions}
                        placeholder=""
                      />
                    </FormGroup>
                  </Grid>
                  {values?.allergytype && (
                    <Grid item xs={12} sm={4}>
                      {allergiesTypeOptions.find(
                        (key) => key.value === values.allergytype
                      )?.label === "Other" ? (
                        <FormGroup
                          name="allergyname"
                          label={`${t("screens.allergies.allergyName")}`}
                        >
                          <InputField {...getFieldProps("allergyname")} />
                        </FormGroup>
                      ) : (
                        <FormGroup
                          name="allergyname"
                          label={`${t("screens.allergies.allergyName")}`}
                        >
                          <AutoComplete
                            {...getFieldProps("allergyname")}
                            name="allergyname"
                            options={allergiesNameOptions}
                            placeholder=""
                          />
                        </FormGroup>
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={4} xs={12}>
                    <FormGroup
                      name="allergyreaction"
                      label={`${t("screens.allergies.reaction")}`}
                    >
                      <TextArea
                        rows={2}
                        {...getFieldProps("allergyreaction")}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
              {/* </Grid> */}
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setIsAdd(false)}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.allergyUpdated")}`
              : `${t("screens.successMessage.allergyCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
          }}
        />
      )}
    </Box>
  );
}

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Menutool from "../Header/Menutool";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TOKEN_KEY } from "../../Frontend/Constant";
import { ReactComponent as AboutusIcon } from "../../Assets/Images/aboutus.svg";
import { ReactComponent as ForbusinessIcon } from "../../Assets/Images/business.svg";
import { ReactComponent as IndividualsIcon } from "../../Assets/Images/individuals.svg";
import { ReactComponent as Resources } from "../../Assets/Images/resources.svg";
import { ReactComponent as Contactus } from "../../Assets/Images/contactus.svg";
import { ReactComponent as MenuUser } from "../../Assets/Images/menuUser.svg";

import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

export default function Header({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const tooltipRef = useRef();
  const theme = useTheme();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);

  function handleClick(event) {
    setIsOpen(!isOpen);
    event.preventDefault();
  }

  const handleClickOutside = (event) => {
    if (
      ref?.current &&
      !ref?.current?.contains(event.target) &&
      !tooltipRef?.current?.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleClickOutsideDropdown = (event) => {
    
    const isMenuItemClick = event.target.closest('.btn-outline-primary');
    if (isMenuItemClick) {
      return;
    }

    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMobileDropdownOpen(false);
      const dropdownMenu = document.getElementById("dropdownMenu");
      if (dropdownMenu) {
        dropdownMenu.classList.remove("show");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  const currentPageURL = window.location.pathname;

  const loginOption = [
    {
      label: "Existing User LogIn",
      link: "/members",
    },
    {
      label: "Become a Member",
      link: "/register",
    },
    {
      label: "Emergency Login",
      link: "/er",
    },
  ];
  const navData = [
    {
      title: "About Us",
      icon: (
        <AboutusIcon
          style={{
            stroke: `${theme.palette.info.main}`,
            cursor: "pointer",
          }}
        />
      ),
      link: "/aboutus",
    },
    {
      title: "For Businesses",
      icon: (
        <ForbusinessIcon
          style={{
            fill: `${theme.palette.info.main}`,
            cursor: "pointer",
          }}
        />
      ),
      link: "/forbusiness",
    },
    {
      title: "For Individuals",
      icon: (
        <IndividualsIcon
          style={{
            fill: `${theme.palette.info.main}`,
            cursor: "pointer",
          }}
        />
      ),
      link: "/for-individuals",
    },
    {
      title: "Resources",
      icon: (
        <Resources
          style={{
            fill: `${theme.palette.info.main}`,
            cursor: "pointer",
          }}
        />
      ),
      link: "/resources",
    },
    {
      title: "Contact Us",
      icon: (
        <Contactus
          style={{
            fill: `${theme.palette.info.main}`,
            cursor: "pointer",
          }}
        />
      ),
      link: "/contactus",
    },
  ];

  const emergencyLoginUserOption = [
    {
      label: "Contact Us",
      link: "/contactus",
    },
    {
      label: "Logout",
      link: "/members",
      onClick: () => {
        localStorage.clear();
        window.location.href = "/members";
      },
    },
  ];

  const token = localStorage.getItem(TOKEN_KEY);
  const checkToken = token && token !== "null" ? token : "";
  const isLogin =
    !!checkToken && window?.location?.pathname === "/emergencyMedicalHistory";
  const listOptions = isLogin ? emergencyLoginUserOption : loginOption;

  const mobileToggleDropdown = () => {
    setMobileDropdownOpen(!mobileDropdownOpen);
  };

  const toggleDropdown = () => {
    const dropdownMenu = document.getElementById("dropdownMenu");
    if (dropdownMenu) {
      dropdownMenu.classList.toggle("show");
    }
  };

  const handleMenuItemClick = (item) => {
    if (item?.onClick) {
      item.onClick();
    } else {
      setTimeout(() => {
        setMobileDropdownOpen(false);
        const dropdownMenu = document.getElementById("dropdownMenu");
        if (dropdownMenu) {
          dropdownMenu.classList.remove("show");
        }
      }, 100);
    }
  };

  return (
    <div topmenu="" className="navbar-header-top" ng-hide="hideHeader">
      <header
        ng-controller="TopMenuController"
        id="home"
        className="header layout-site-header navbar navbar-inverse ng-scope"
      >
        <div className="container-main container-fluid header-foodts-sections">
          <div ng-if="!menu.show" className="navbar-header ng-scope">
            <a className="navbar-brand" href="/">
              <img
                alt="logo"
                className="img-fluid"
                src={data?.logo ? data?.logo : "/assets/images/headerlogo.png"}
              />
            </a>
          </div>
          <div style={{ display: "flex" }}>
            <div
              id="mainmenu"
              ng-controller="navDetails"
              className="collapse navbar-collapse ng-scope"
            >
              <ul className="navbar-nav">
                {navData.map((item, index) => (
                  <li
                    className={`ng-scope ${
                      currentPageURL === item?.link ? "active" : ""
                    }`}
                    key={index}
                  >
                    <Link to={item.link} target="_self" className="ng-binding">
                      {item?.icon && item?.icon}
                      {item.title}
                    </Link>
                  </li>
                ))}
                <div className="btn-group pull-right">
                  <button
                    type="button"
                    className="btn btn-default dropdown-toggle top-menu-button"
                    onClick={() => toggleDropdown()}
                  >
                    <div className={`header-profile-container`}>
                      <MenuUser
                        style={{
                          fill: `${theme.palette.info.main}`,
                          cursor: "pointer",
                        }}
                      />
                      Sign In/Sign Up
                    </div>
                  </button>
                  <ul
                    className="dropdown-menu top-menu-items"
                    role="menu"
                    id="dropdownMenu"
                  >
                    {listOptions?.map((item) => {
                      return (
                        <li className="ng-scope">
                          <Link target="_self" to={item?.link}>
                            <button
                              className="btn btn-outline-primary"
                              onClick={() => handleMenuItemClick(item)}
                            >
                              {item?.label}
                            </button>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </ul>
            </div>
            <HamburgerWrapper onClick={(event) => handleClick(event)} ref={ref}>
              <HamburgerIcon>
                <MenuItem>
                  <img
                    src={require("../../Assets/Images/Navbar/hamburger-menu.png")}
                    width={30}
                    height={30}
                    alt=""
                  />
                </MenuItem>
              </HamburgerIcon>
            </HamburgerWrapper>
           <Box component='div' className="user-info" ref={dropdownRef}>
              <button
                type="button"
                className="btn-default dropdown-toggle ng-binding show btn-header"
                data-bs-toggle="dropdown"
                aria-expanded="true"
                onClick={() => mobileToggleDropdown()}
              >
               <MenuUser
                        style={{
                          fill: `${theme.palette.info.main}`,
                          cursor: "pointer",
                        }}
                      />
              </button>
              <ul
                className={`dropdown-menu dropdown-menu-item top-menu-items ${
                  mobileDropdownOpen ? "show" : ""
                }`}
                role="menu"
                id="dropdownMenu"
              >
                {listOptions.map((item) => {
                  return (
                    <li className="ng-scope" ng-if="!menu.emergency">
                      <Link target="_self" to={item?.link}>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => handleMenuItemClick(item)}
                        >
                          {item?.label}
                        </button>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Box>
          </div>
        </div>
      </header>
      <Menutool isOpen={isOpen} />
    </div>
  );
}

const HamburgerWrapper = styled.div`
  display: none;
  @media (max-width: 1200px) {
    display: block;
  }
`;

const HamburgerIcon = styled.div`
  // background: #008fd3;
  align-items: center;
  text-align: center;
  margin-top: 7px;
  margin-right: 10px;
  border-radius: 5px;
  :active {
    background-color: #e6e6e6;
    border-color: #adadad;
  }
`;

const MenuItem = styled.div`
  // padding: 5px 10px;
  font-size: 14px;
  text-align: left;
`;

export const TOKEN_KEY = "footprint_token";
export const USERID_KEY = "footprint_user_id";
export const PAWPRINT_PERMISSION_KEY = "footprint_permission";
export const PAWPRINT_USER_ROLE_KEY = "footrint_User_role";
export const PET_OWNER_ID_KEY = "footowner_id";
export const WORKSPACEID_KEY = "workspace_id";
export const MEMBERSHIP_NUMBER_KEY = "membership_id";
export const ACTIVE_SIDEBAR = "active_sidebar";
export const USER_ROLE_KEY = "User_role";
export const LOGIN_USER_ROLE_KEY = "login_user_role";
export const LOGIN_TYPE = "login_type";

export const ADMIN_ROLE = "Administrator";
export const STANDARD_USER_ROLE = "Standard User";
export const POWER_USER_ROLE = "Power User";
export const STORE_LANGUAGE_KEY = "settings.lang";

export const USER = "User";
export const GENERAL_AUTHORIZED_USER = "General Authorized User";
export const ADMIN = "Admin";
export const VIEW_ONLY_USER = "View Only User";
export const SUPER_ADMIN_USER = "SuperUser";

export const CMS_ROUTES = [
  "/",
  "/for-individuals",
  "/members",
  "/er",
  "/forbusiness",
  "/aboutus",
  "/resources",
  "/contactus",
  "/register",
  "/terms-conditions",
  "/forget-password",
  "/user/reset",
  "/subuser/reset",
  "/success-page",
  "/user/VerifyAccount",
  "/subuser/VerifyAccount",
  "/admin/VerifyAccount",
  "/password-recovery",
  "/emergencyMedicalHistory",
  "/thankyou",
  "/privacy-policy",
  "/auth"
];

import React, { useEffect } from "react";
import HomeSection1 from "../../Components/Home/HomeSection1/HomeSection1";
import HomeSection2 from "../../Components/Home/HomeSection2/HomeSection2";
import HomeSection3 from "../../Components/Home/HomeSection3/HomeSection3";
import HomeSection4 from "../../Components/Home/HomeSection4/HomeSection4";
import "../../App.css";
import HomeSection5 from "../../Components/Home/HomeSection5/HomeSection5";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ng-scope">
      <HomeSection1 />
      <div id="home-video-main">
        <HomeSection2 />
        <HomeSection5/>
        <HomeSection3 />
        <HomeSection4 />
      </div>
    </div>
  );
}

import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { H1 } from "../../../Assets/Styles/GlobleStyles/TextStyles";


export default function HomeSection5() {

  const Title = styled(H1)`
  font-size: 65px;
  line-height: 112.4%;
  text-align: center;
  color: var(--radium-info-600);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  padding-bottom: 50px;

  span {
    color: var(--blue-color-400);
  }

  @media (max-width: 1100px) {
    font-size: 45px;
    padding-bottom: 28px;
  }
  @media (max-width: 975px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 895px) {
    font-size: 38px;
  }
  @media (max-width: 855px) {
    letter-spacing: -0.01em;
    font-size: 35px;
  }
  @media (max-width: 715px) {
    font-size: 30px;
  }
`;
  return (
    <>
    <Title>
          <b>
            <span>Featured</span> Partner
          </b>
        </Title>
    <section
      className="home-video legado-section content-offset container-fluid border-radius-12"
      data-stellar-background-ratio="0.2"
    >
      <div className="video-section row flex-row">
        <div className="col-md-12 col-sm-12">
            <div>
          <p className="video-letter legado-video-text">
          Legado Protect 
          </p>
         
            </div>
          <p className="video-letter-2 legado-video-text">
          Legado simplifies legacy and estate planning by combining digital convenience with attorney- reviewed assurance for complete peace of mind. Free from confusing legal terms and uncomfortable attorney fact finders, 
          </p>
          <p className="video-letter-2 legado-video-text">
          Legado created a simple technology platform with a 7-step process to create a comprehensive plan tailored to your needs. Loved ones including pets can benefit from our affordable estate planning solutions that will provide clarity and a celebration of life. Leaving a legacy is continual and ongoing,
          </p>
          <p className="video-letter-2 legado-video-text">
          Legado is here to help make that planning process easier for everyone.
          </p>
          <Box
            sx={{display:'flex'}}
          >
             <a target="_blank" href="https://hellolegado.com/" style={{display:'contents'}} alt='_blank'>
            <button className="btn btn-deep-orange ng-binding">
            See More
            </button>
          </a>
          </Box>
        </div>
        <div className="legadoBox-section">
          <img
                  className="img-carousel"
                  src="assets/images/Legado.svg"
                  alt=""
                />
          </div>
        {/* <div className="col-md-6 col-sm-12 video-div order-1">
            <div>sadfasfa</div>    
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: { display: "none" },
            })}
          >
            <button className="btn-deep-orange">Get in Touch</button>
          </Box>
        </div> */}
      </div>
    </section>
    </>
  );
}

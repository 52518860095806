import axios from "axios";

function getSubdomain() {
  const hostname = window.location.hostname; // Get the hostname (e.g., "stage.localhost")
  const parts = hostname.split('.'); // Split it by '.'

  if (parts.length > 1) {
      return parts.slice(0, parts.length - 2).join('.');
  }
  return process.env.REACT_APP_DEFAULT_DOMAIN; 
}
const REACT_APP_BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL
const instance = axios.create({
  baseURL: REACT_APP_BACKEND_BASE_URL,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
    hostname: getSubdomain(),
  },
});
export default instance;

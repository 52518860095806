import React from "react";
import styled from "styled-components";
import Slider1 from "../HomeSection2/slider1/Slider1";
import { H1 } from "../../../Assets/Styles/GlobleStyles/TextStyles";

export default function HomeSection2() {
  return (
    // main div
    <Wrapper>
      <ContentWrapper>
        <Title>
          <b>
            <span>Peace of Mind.</span> Every time, everywhere.
          </b>
        </Title>

        <Slider1 />
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #ffffff;
  overflow: hidden;
  margin: 0 auto;
`;
const ContentWrapper = styled.div`
  padding: 50px 0px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 35px 0px;
  }
  @media (max-width: 435px) {
    padding: 20px 0px;
  }
`;
const Title = styled(H1)`
  font-size: 65px;
  line-height: 112.4%;
  text-align: center;
  color: var(--radium-info-600);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  padding-bottom: 50px;

  span {
    color: var(--blue-color-400);
  }

  @media (max-width: 1100px) {
    font-size: 45px;
    padding-bottom: 28px;
  }
  @media (max-width: 975px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 895px) {
    font-size: 38px;
  }
  @media (max-width: 855px) {
    letter-spacing: -0.01em;
    font-size: 35px;
  }
  @media (max-width: 715px) {
    font-size: 30px;
  }
`;

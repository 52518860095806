import React, { useEffect, memo } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";

import InputField from "../Common/InputField";
import FormGroup from "../Common/FormGroup";
import SelectField from "../Common/AutoComplete";
import { usStates } from "./constant";
import { Body, Column, Header, } from "../../Css";
import ShippingInformation from "./ShippingInformation";
import PhoneInput from "../Common/PhoneInput";
import { useTheme } from "@mui/material";
import { hexToRgba } from "../../Frontend/Utils";

const Wrapper = styled.div`
  font-family: sans-serif;
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--text-primary-800);
  margin-bottom: 50px;
  @media (max-width: 650px) {
    margin-bottom: 30px;
  }
`;

const Country = styled.div`
  .react-tel-input .form-control {
    border-radius: 5px;
    border: 1px solid gray;
    height: 41px !important;
    width: 100%;
    background: white !important;
  }

  .react-tel-input .flag-dropdown {
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: transparent !important;
    border-radius: 3px 0 0 3px;
  }

  input:-internal-autofill-selected {
    background-image: none !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: fieldtext !important;
  }
    .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background: transparent !important;
    }
`;

function BillingInformation({
  onPrevious,
  setValueinFormData,
  setInitialValue,
}) {
  const { getFieldProps, touched, values, setFieldValue } = useFormikContext();
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
    touched.firstnameB = false;
    touched.streetAddB = false;
    touched.CityB = false;
    touched.zipcodeB = false;
    touched.lastnameB = false;
    touched.stateB = false;
  }, []);

  // We're now handling setValueinFormData in ComponentDidMount to avoid unnecessary re-renders
  useEffect(() => {
    if (setValueinFormData) {
      setValueinFormData();
    }
  }, []);

  return (
    <div className="container" style={{ gridTemplateColumns: "90%" }}>
      <Wrapper>
        <Header style={{backgroundColor: hexToRgba(theme.palette.info.main,0.10)}}>Billing Information</Header>

        <Body>
          <Column>
            <FormGroup
              label="First Name"
              name="firstnameB"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="firstnameB"
                {...getFieldProps("firstnameB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Last Name"
              name="lastnameB"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="lastnameB"
                {...getFieldProps("lastnameB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Address 1"
              name="streetAddB"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="streetAddB"
                {...getFieldProps("streetAddB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Address 2"
              name="addressB"
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="addressB"
                {...getFieldProps("addressB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="City"
              name="CityB"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="CityB"
                {...getFieldProps("CityB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="State / Province / Region"
              name="stateB"
              labelClassName="margin-top"
            >
              <SelectField
                {...getFieldProps("stateB")}
                name="stateB"
                options={usStates}
                placeholder="Select State"
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Zip Code"
              name="zipcodeB"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="zipcodeB"
                {...getFieldProps("zipcodeB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Phone Number"
              name="billing_phone_number"
              requiredField
              labelClassName="margin-top"
            >
              <Country>
                <PhoneInput
                  dialCodeName="billing_phone_code"
                  name="billing_phone_number"
                  onChange={(value, country) => {
                    setFieldValue("billing_phone_code", country.dialCode);
                  }}
                />
              </Country>
            </FormGroup>
          </Column>
        </Body>
      </Wrapper>
      <ShippingInformation
        onPrevious={onPrevious}
        setInitialValue={setInitialValue}
        setValueinFormData={setValueinFormData}
      />
    </div>
  );
}

export default memo(BillingInformation);

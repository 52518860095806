import React from "react";

import { useFormikContext } from "formik";
import { get } from "lodash";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneInput = ({
  name,
  dialCodeName,
  onChange,
  variant = "rounded",
  ...rest
}) => {
  const { errors, touched, setFieldValue, values, setFieldTouched, onBlur } =
    useFormikContext();
  const error = get(errors, name);
  const fieldTouched = get(touched, name);
  const value = get(values, name);
  const dialCode = get(values, dialCodeName);

  return (
    <ReactPhoneInput
      value={`${dialCode}${value}`}
      {...rest}
      inputProps={{
        name,
        required: true,
        autoFocus: false,
      }}
      inputStyle={{
        paddingTop: "10px",
        height: "16px",
        borderColor: error && fieldTouched ? "#DC3838" : " var(--text-primary-800) !important",
      }}
      buttonStyle={{
        backgroundColor: "transparent",
        borderColor: error && fieldTouched ? "#DC3838" : " var(--text-primary-800) !important",
      }}
      country={"us"}
      enableSearch
      searchPlaceholder="Enter Phone Number"
      onChange={(value, country) => {
        setFieldValue(name, value.slice(country.dialCode.length));
        onChange?.(value, country);
      }}
      onBlur={() => setFieldTouched(name, true)}
    />
  );
};

export default PhoneInput;

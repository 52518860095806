import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "../Login/login.css";
import HttpCommon from "../../Utils/http-common";
import styled from "styled-components";
import swal from "sweetalert";
import { Form, Formik } from "formik";
import { ErLoginAccountValidation } from "../../From/Validation";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import PasswordInput from "../Common/FormGroup/PasswordInput";
import { LOGIN_TYPE, TOKEN_KEY, USERID_KEY } from "../../Frontend/Constant";
import InputMask from "react-input-mask";

const defaultValue = {
  username: "",
  password: "",
};

function Login() {
  const formRef = useRef();

  const [initialValue, setInitialValue] = useState(defaultValue);

  useEffect(() => {
    if (localStorage.getItem("password") !== null) {
      setInitialValue({
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      });
    }
  }, []);

  //send data
  const handleSubmit = async (Values, { setFieldError, setSubmitting }) => {
    try {
      const res = await HttpCommon.post("/emergency_login", Values);
      if (res.data.EmergencyLogin) {
        const user_id = res.data.user?.[0]?.id;
        const token = res.data.token;
        localStorage.setItem(TOKEN_KEY, token);
        localStorage.setItem(USERID_KEY, user_id);
        localStorage.setItem(LOGIN_TYPE, "emergency");
        window.location.href = "/emergencyMedicalHistory";
      } else {
        if (res.data.err === "L_LOGIN__INCORRECT_MEMBERSHIP_NO_CREDENTIALS") {
          setFieldError("password", "Invalid login credential");
          setSubmitting(false);
        }
      }
    } catch (err) {
      console.log("error: ", err);
      if (err.code === "ERR_NETWORK") {
        swal("Network Error!", "Failed to connect server.", "error");
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main">
        <div className="container">
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={ErLoginAccountValidation}
            // enableReinitialize={true}
            innerRef={formRef}
          >
            {({ getFieldProps, values, isSubmitting, errors }) => (
              <Form>
                <h1>Emergency Login</h1>
                <FormGroup
                  label="Membership #"
                  name="username"
                  className="loginField emergency-login"
                  svgPath={require("../../Assets/Images/user.png")}
                >
                  <InputMask
                    mask="99-999-999"
                    maskChar="_"
                    {...getFieldProps("username")}
                  >
                    {(inputProps) => (
                      <InputField
                        type="text"
                        name="username"
                        className="textarea"
                        placeholder="__-___-___"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </FormGroup>
                <FormGroup
                  label="Access Code"
                  name="password"
                  className="loginField emergency-login"
                  svgPath={require("../../Assets/Images/lock.png")}
                >
                  <PasswordInput {...getFieldProps("password")} />
                </FormGroup>

                {isSubmitting ? (
                  <button
                    className="btn1"
                    style={{ display: "grid" }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <Loader />
                  </button>
                ) : (
                  <button
                    className="btn12"
                    style={{ margin: "15px 0px" }}
                    type="submit"
                  >
                    Log In
                  </button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default Login;

const Loader = styled.div`
  justify-self: center;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

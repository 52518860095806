import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme,
} from "@mui/material/styles";
import styled from "styled-components";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Login from "./Components/Login/Login";
import EmergencyLogin from "./Components/EmergencyLogin/Login";
import AboutUS from "./Pages/AboutUs/AboutUS";
import BusinessComponent from "./Pages/Business Page/BusinessComponent";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Forindividuals from "./Pages/For individuals/Forindividuals";
import Home from "./Pages/Home/Home";
import ResourcePg from "./Pages/ResourcePage/ResourcePg";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import SuccessPage from "./Pages/ForgetPassword/SuccessPage";
import Register from "./Pages/Registration/Register";
import TermCondition from "./Pages/TermCondition/TermCondition";
import usePageTracking from "./Utils/usePageTracking";
import AuthHandler from "./Components/Auth/AuthHandler";

//FrontEnd

import { AuthProvider } from "./Frontend/ContextProvider/AuthProvider/AuthProvider";
import UserInformation from "./Frontend/Pages/UserInformation/UserInformation";
import { CMS_ROUTES, LOGIN_TYPE, TOKEN_KEY } from "./Frontend/Constant";
import EmergencyContact from "./Frontend/Components/Item-List/EmergencyContact";
import EmergencyMedicalHistory from "./Frontend/Components/Item-List/EmergencyMedicalHistory";
import PhysicianTherapist from "./Frontend/Components/Item-List/PhysicianTherapist";
import MedicalInsurance from "./Frontend/Components/Item-List/MedicalInsurance";
import History from "./Frontend/Components/Item-List/History";
import PrescriptionAndMedication from "./Frontend/Components/Item-List/Prescription&Medication";
import Allergy from "./Frontend/Components/Item-List/Allergy";
import Conditions from "./Frontend/Components/Item-List/Conditions";
import SurgeryandTreatment from "./Frontend/Components/Item-List/Surgery&Treatment";
import Immunizations from "./Frontend/Components/Item-List/Immunization";
import FamilyMembers from "./Frontend/Components/Item-List/FamilyMember";
import Documents from "./Frontend/Components/Item-List/Document";
import AccountInformation from "./Frontend/Components/MyProfile/AccountInformation/AccountInformation";
import ChangePasswordForm from "./Frontend/Components/MyProfile/ChangePassword";
import UpdateShippingInformation from "./Frontend/Components/MyProfile/UpdateShippingInfo";
import UpdateBillingInformation from "./Frontend/Components/MyProfile/UpdateBillingInfo";
import BuyAdditionalMembership from "./Frontend/Components/MyProfile/BuyAdditionalMembership/BuyAdditionalMembership";
import DocumentFile from "./Frontend/Components/Item-List/DocumentFile";
import EditMemberProfile from "./Frontend/Components/MyProfile/AccountInformation/EditMemberProfile";
import Thankyou from "./Pages/Thankyou";
import RecoverPassword from "./Pages/RecoverPassword/RecoverPassword";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import React from "react";
import { setThemeSetting } from "./api/axios";
import useSWR from "swr";
import { Box, CircularProgress } from "@mui/material";
import DomainNotFound from "./Frontend/Components/MyProfile/BuyAdditionalMembership/DomenError/ContentNotFound";

function App() {
  usePageTracking();
  const token = localStorage.getItem(TOKEN_KEY);
  const loginType = localStorage.getItem(LOGIN_TYPE);
  const checkToken = token && token !== "null" ? token : "";
  const isNotLogin =
    CMS_ROUTES.includes(window?.location?.pathname) || !checkToken;

  const { data: themeColor, isLoading } = useSWR(
    `themecolorapply`,
    async () => {
      try {
        const { data } = await setThemeSetting();
        return data.data || null;
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );
  console.log('themeColor: ', themeColor);

  const theme = unstable_createMuiStrictModeTheme({
    typography: {
      fontFamily: `Omnes, sans-serif`,
    },
    palette: {
      primary: {
        // main:"#3e846f",
        main: themeColor?.primary_color ? themeColor?.primary_color : "#3e846f",
        text: "#FF0000",
        safeLightAccentHsl:'#f7eee7'
      },
      secondary: {
        main: themeColor?.secondary_color
          ? themeColor?.secondary_color
          : "#d032ff",
        // main: '#d032ff',
        second: "#000000",
      },
      info: {
        main: themeColor?.tertiary_color
          ? themeColor?.tertiary_color
          : "#ff33cc",
        // main:'#ff33cc'
      },
    },
  });

  React.useEffect(() => {
    document.documentElement.style.setProperty(
      "--blue-color-400",
      theme.palette.primary.main
    );
    document.documentElement.style.setProperty(
      "--blue-color-800",
      theme.palette.primary.text
    );
    document.documentElement.style.setProperty(
      "--text-primary-800",
      theme.palette.secondary.second
    );
    document.documentElement.style.setProperty(
      "--radium-orange-600",
      theme.palette.secondary.main
    );
    document.documentElement.style.setProperty(
      "--radium-info-600",
      theme.palette.info.main
    );
    document.documentElement.style.setProperty(
      "--primary-BackgroundColor-600",
      theme.palette.primary.safeLightAccentHsl
    );
    
  }, [
    theme.palette.primary.main,
    theme.palette.info.main,
    theme.palette.secondary.main,
    theme.palette.primary.text,
  ]);
  return (
    <>
     
      
          {isLoading ? (
            <Box
              sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{ color: "var(--radium-info-600)" }} />
            </Box>
          ) : (
            themeColor === undefined || !themeColor? 
              <DomainNotFound/>
               : 
            <ThemeProvider theme={theme}>
              <Router>
                {(isNotLogin ||
                  loginType === "emergency" ||
                  window?.location?.pathname?.split("/")?.[1] ===
                    "password-recovery") && <Header data={themeColor} />}
                <Content>
                  <Routes>
                    <Route exact path="/" element={<Home />}></Route>
                    <Route exact path="/auth" element={<AuthHandler />}></Route>
                    <Route
                      exact
                      path="/for-individuals"
                      element={<Forindividuals />}
                    ></Route>
                    <Route exact path="/members" element={<Login />}></Route>
                    <Route
                      exact
                      path="/er"
                      element={<EmergencyLogin />}
                    ></Route>
                    <Route
                      exact
                      path="/forbusiness"
                      element={<BusinessComponent />}
                    ></Route>
                    <Route exact path="/aboutus" element={<AboutUS />}></Route>
                    <Route
                      exact
                      path="/resources"
                      element={<ResourcePg />}
                    ></Route>
                    <Route
                      exact
                      path="/contactus"
                      element={<ContactUs />}
                    ></Route>
                    <Route
                      exact
                      path="/register"
                      element={<Register />}
                    ></Route>
                    <Route
                      exact
                      path="/privacy-policy"
                      element={<PrivacyPolicy />}
                    ></Route>
                    <Route
                      exact
                      path="/password-recovery/:activationkey/:id"
                      element={<RecoverPassword />}
                    ></Route>
                    <Route
                      exact
                      path="/thankyou/:activationkey/:uid"
                      element={<Thankyou />}
                    ></Route>
                    <Route
                      exact
                      path="/terms-conditions"
                      element={<TermCondition />}
                    ></Route>
                    <Route
                      exact
                      path="/forget-password"
                      element={<ForgetPassword />}
                    ></Route>
                    <Route
                      exact
                      path="/success-page"
                      element={<SuccessPage />}
                    ></Route>
                  </Routes>
                  {/* -------------------------Frontend----------------------------- */}
                  <AuthProvider>
                    <Routes>
                      <Route
                        exact
                        path="/emergencyMedicalHistory"
                        element={<EmergencyMedicalHistory />}
                      ></Route>
                      <Route
                        exact
                        path="/user-information"
                        element={<UserInformation />}
                      />
                      <Route
                        exact
                        path="/emergency-contact"
                        element={<EmergencyContact />}
                      />
                      <Route
                        exact
                        path="/physician-therapist"
                        element={<PhysicianTherapist />}
                      />
                      <Route
                        exact
                        path="/medical-insurance"
                        element={<MedicalInsurance />}
                      />
                      <Route
                        exact
                        path="/prescription-medications"
                        element={<PrescriptionAndMedication />}
                      />
                      <Route exact path="/history" element={<History />} />
                      <Route exact path="/allergy" element={<Allergy />} />
                      <Route
                        exact
                        path="/conditions"
                        element={<Conditions />}
                      />
                      <Route
                        exact
                        path="/surgeries-treatments"
                        element={<SurgeryandTreatment />}
                      />
                      <Route
                        exact
                        path="/immunizations"
                        element={<Immunizations />}
                      />
                      <Route
                        exact
                        path="/family-history"
                        element={<FamilyMembers />}
                      />
                      <Route exact path="/document" element={<Documents />} />
                      <Route
                        exact
                        path="/documents/:id"
                        element={<DocumentFile />}
                      />
                      <Route
                        exact
                        path="/members/:id"
                        element={<EditMemberProfile />}
                      />
                      <Route
                        exact
                        path="/myFootprintID"
                        element={<AccountInformation />}
                      />
                      <Route
                        exact
                        path="/settings/changePassword"
                        element={<ChangePasswordForm />}
                      />
                      <Route
                        exact
                        path="/settings/shippingInfo"
                        element={<UpdateShippingInformation />}
                      />
                      <Route
                        exact
                        path="/settings/billingInfo"
                        element={<UpdateBillingInformation />}
                      />
                      <Route
                        exact
                        path="/settings/upgradeMembers"
                        element={<BuyAdditionalMembership />}
                      />
                    </Routes>
                  </AuthProvider>
                </Content>
                {(isNotLogin ||
                  loginType === "emergency" ||
                  window?.location?.pathname?.split("/")?.[1] ===
                    "password-recovery") && <Footer />}
              </Router>
            </ThemeProvider>
          )}
      
  
    </>
  );
}
const Content = styled.div`
  ${
    "" /* position: absolute;
  margin-top: 10%; */
  }
`;

export default App;

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { TOKEN_KEY, USERID_KEY, LOGIN_TYPE, USER_ROLE_KEY, LOGIN_USER_ROLE_KEY } from '../../Frontend/Constant';
import { api } from "../../Frontend/Api/axios";

const AuthHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const secretKey = searchParams.get('secretKey');

  useEffect(() => {
    const handleAuth = async () => {
      try {
        if (token && email && secretKey) {
          const response = await api.post('/lookup-user', {
            email: email,
            subdomain: window.location.hostname.split('.')[0],
            secretKey: secretKey,
            token: token
          });
          
          if (response.data.token) {
            const user_id = response.data.user?.id;
            const token = response.data.token;
            localStorage.setItem(TOKEN_KEY, token);
            localStorage.setItem(USERID_KEY, user_id);
            localStorage.setItem(LOGIN_TYPE, "user_login");
            
            if (response?.data?.user?.user_role !== "Administrator") {
              window.location.href = "/user-information";
            } else {
              const { data } = await api.get(`/members/${user_id}`);
              for (var i = 0; i < data.members.length; i++) {
                if (data.members[i].relation === "Self") {
                  const { data: userInfo } = await api.get(`/self-info/${data?.members?.[i]?.user_id}`);
                  localStorage.setItem(USER_ROLE_KEY, userInfo?.user_role);
                  localStorage.setItem(LOGIN_USER_ROLE_KEY, userInfo?.user_role);
                  window.location.href = "/user-information";
                  return;
                }
              }
              window.location.href = "/myFootprintID";
            }
          } else {
            navigate('/members');
          }
        } else {
          // If required parameters are missing, redirect to login
          navigate('/members');
        }
      } catch (error) {
        console.error('Authentication error:', error);
        navigate('/members');
      }
    };

    handleAuth();
  }, [token, email, secretKey, navigate]);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AuthHandler; 
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isEqual } from "lodash";
import useSWR from "swr";

import "./GlobalItemList.css";
import viewIcon from "../../Assets/Images/pdfIcon.png";
import useAuth from "../../ContextProvider/AuthProvider";
import PawDataView from "../Common/PawDataView.js";
import { filterAscDescData, formatDate, hexToRgba } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Box, Button, MenuItem, Select } from "@mui/material";
import { ReactComponent as EditIcon } from "../../Assets/Images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/DeleteIcon.svg";
import ApiService from "../../Api/ApiServices.js";
import Layout from "../Side bar/Layout.js";
import DocumentsForm from "../Forms/DocumentsForm.js";
import { MainPageWrapper } from "./GlobalItemList";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { getCategoryDocument } from "../../../api/axios.js";
import { useLocation, useNavigate } from "react-router-dom";

export default function Documents() {
  const { userId } = useAuth();

  const [filter, setFilter] = useState({});
  const [documentsData, setDocumentsData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const fetchIdRef = useRef(0);
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    data = [],
    mutate: fetchAPIData,
  } = useSWR(
    userId && selectedCategory !== null ? `Documentdata${userId}` : null,
    async () => {
      setIsLoading(true)
      let params='';
      if(selectedCategory) {
        params += `?category=${encodeURIComponent(selectedCategory)}`;
      }
      if(selectedSubCategory) {
        params += `${params !== '' ? '&' : '?'}sub_category=${encodeURIComponent(selectedSubCategory)}`;
      }
      try {
        const { data } = await ApiService.getDocuments(userId, params);
        setIsLoading(false)
        setDocumentsData(data);
        return data || [];  
      } catch (e) {
        console.log('===> error ', e);  
        return []
      }  
      
    },
    {
      revalidateOnFocus: false,
    }
  );

  const getCategoryDocumentSelect = async () => {
    try {
      setIsLoading(true)
      let params='';
      if(selectedCategory) {
        params += `?category=${encodeURIComponent(selectedCategory)}`;
      }
      if(selectedSubCategory) {
        params += `${params !== '' ? '&' : '?'}sub_category=${encodeURIComponent(selectedSubCategory)}`;

      navigate(`/document${params}`);
      }
      const { data: selectData } = await getCategoryDocument(params);
      setCategoryData(selectData?.data);
    } catch (error) {
      setCategoryData([]);
      console.log("error: ", error);
    }finally{
      setIsLoading(false)
    }
  };

  useEffect(() => {    
    fetchAPIData();
  }, [selectedCategory,selectedSubCategory]);

  useEffect(() => {
    getCategoryDocumentSelect();
  }, []);
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (location.pathname === "/document" && params) {
      const categoryFromUrl = params.get("category");
      const subCategoryFromUrl = params.get("sub_category");
  
      setSelectedCategory(categoryFromUrl || null);
      setSelectedSubCategory(subCategoryFromUrl || null);
    } else {
      setSelectedCategory(null);
      setSelectedSubCategory(null);
    }
  }, [location?.search]);

  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);
    setSelectedSubCategory(""); 
    navigate(`?category=${encodeURIComponent(newCategory)}`);
  };

  const handleSubCategoryChange = (event) => {
    const newSubCategory = event.target.value;
    setSelectedSubCategory(newSubCategory);
    navigate(`?category=${encodeURIComponent(selectedCategory)}&sub_category=${encodeURIComponent(newSubCategory)}`);

  };

  const selectedCategoryData = categoryData?.find(
    (cat) => cat?.category === selectedCategory
  );

  const selectedSubCategoryData = selectedCategoryData?.sub_category?.find(
    (cat) => cat?.name === selectedSubCategory
  );


  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
  }, []);

  useEffect(() => {
    const { sort } = filter;
    if (filter && filter.from > -1) {
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort);
      setDocumentsData(filteredResult);
    }
  }, [filter, data]);

  const handleView = (item) => {
    window.open(`/documents/${item?.id}`, "_blank");
    setIsAdd(false);
    setIsEdit(false);
  };

  const addOnclick = () => {
    setIsAdd(true);
    setSelectedData({});
    setIsShow(false);
    setIsEdit(false);
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setSelectedData(item);
    setIsAdd(true);
    setIsShow(false);
  };

  const getCategoryName = (columnValue) => {
    let document = documentsData.find((doc) => doc?.id === columnValue);
  
    let category, sub_category, dataToReturn = '';
    if (document?.category_id) {
      category = categoryData?.find((cat) => cat?.id === document?.category_id);
      if (category) {
        dataToReturn = category?.category;
      }
    }
    
    if (document?.sub_category_id) {
      sub_category = category?.sub_category?.find((cat) => cat?.id === document?.sub_category_id);
      if (sub_category) {
        if (dataToReturn) {
          dataToReturn += ' -> ' + sub_category?.name;
        } else {
          dataToReturn = sub_category?.name;
        };
      }
    }

    return dataToReturn
  }

  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.documents.date")}`,
        accessor: "docum_date",
        width: "12.5vw",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: `${t("screens.documents.documentName")}`,
        accessor: "docum_name",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: `Category`,
        accessor: "id",
        textAlign: "center",
        width: "12.5vw",
        Cell: ({ value }) => {
          return getCategoryName(value)
          },
      },
      {
        Header: `${t("screens.documents.documentView")}`,
        textAlign: "center",
        accessor: "docum_file",
        width: "12.5vw",
        Cell: ({ row }) => {
          return (
            <Button
             color="info"
              sx={{
                minWidth: "40px",
                background:   `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleView(row.original)}
            >
              <img
                alt="view"
                src={viewIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                }}
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.edit")}`,
        accessor: "edit",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              color="info"
              sx={{
                minWidth: "40px",
                background:   `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleEdit(row.original)}
            >
              <EditIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.delete")}`,
        accessor: "delete",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              color="info"
              sx={{
                minWidth: "40px",
                background:   `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => setAskConfirmation(row?.original)}
            >
             <DeleteIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, documentsData, categoryData]);

  const props = useMemo(() => ({
    label: `${t("screens.profile.documents")}`,
    isLoading: isLoading,
    columns,
    columnsData: documentsData,
    details: " There are no documents details for this user.",
    addOnclick: addOnclick,
    fetchData,
    isFormComponent: true,
    FormComponent: isAdd ? () => (
      <DocumentsForm
        setIsAdd={setIsAdd}
        isEdit={isEdit}
        data={selectedData}
        fetchAPIData={fetchAPIData}
        handleView={handleView}
        selectedCategoryData={selectedCategoryData}
        selectedCategory={selectedCategoryData?.id && `cat_${selectedCategoryData?.id}`}
        selectedSubCategory={selectedSubCategoryData?.id && `sub_${selectedSubCategoryData?.id}`}
      />
    ) : null,
  }), [isLoading, documentsData, fetchData, addOnclick, categoryData]);

  const handleDelete = async () => {
    try {
      if (askConfirmation) {
        const { data } = await ApiService.deleteDocuments(
          userId,
          askConfirmation
        );
        if (data?.OK) {
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const viewProps = {
    label: `${t("screens.profile.documents")}`,
    askConfirmation,
    setAskConfirmation,
    onDelete: handleDelete,
    isShow,
  };
  const filterSelect = () => {
    return (
    <Box sx={{display:'flex',gap:'10px',width:'100%'}}>
      <div style={{ width: 'calc(100% + 80px)' }}>
        <div style={{ marginBottom: '3px', fontSize: '14px', fontWeight: 500 }}>
          Category
        </div>
        <Select
          value={selectedCategory || ''}
          onChange={handleCategoryChange}
          native
          id="grouped-native-select"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-input": {
              padding: "5.5px 40px 5.5px 14px !important", // Extra padding for arrow
              height: "20px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
            "& .MuiOutlinedInput-root": {
              display: "flex",
              alignItems: "center",
              "&.Mui-focused fieldset": {
                borderColor: "white",
              },
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #494141",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #494141",
            },
            "& .MuiSvgIcon-root": {
              right: "8px", // Adjust arrow position
              pointerEvents: "none",
              cursor: "pointer"
            },
          }}
        >
          <option aria-label="None" value="All" label="All" />
          {categoryData?.length > 0 &&
            categoryData?.map((cat) => (
              <option value={cat?.category} key={cat?.category}>
                {cat?.category}
              </option>
            ))}
        </Select>
      </div>

      {selectedCategory && selectedCategoryData?.sub_category?.length > 0 && (
      <div style={{ width: '100%' }}>
      <div style={{ marginBottom: '3px', fontSize: '14px', fontWeight: 500 }}>
        SubCategory
      </div>
      <Select
        value={selectedSubCategory}
        onChange={handleSubCategoryChange}
        native
        id="sub-category-select"
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-input": {
            padding: "5.5px 40px 5.5px 14px !important", // Increase right padding
            height: "20px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          },
          "& .MuiOutlinedInput-root": {
            display: "flex",
            alignItems: "center",
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #494141",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #494141",
          },
          "& .MuiSvgIcon-root": {
            right: "8px", 
            pointerEvents: "none",
            cursor: "pointer"
          },
        }}
      >
        <option aria-label="None" value="" label="All" />
        {selectedCategoryData?.sub_category?.map((sub) => (
          <option value={sub?.name} key={sub?.id}>
            {sub?.name}
          </option>
        ))}
      </Select>
    </div>
    
      )}
    </Box>
    )
 }
  return ( 
    <Layout>
      <MainPageWrapper>
        <PawItemList {...props} filterSelect={filterSelect()} />
        <PawDataView {...viewProps} />

      </MainPageWrapper>
    </Layout>
  );
}

import React, { useRef, useState } from "react";

import { Autocomplete, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormikContext } from "formik";
import { get } from "lodash";

function AutoComplete({
  id,
  options,
  label,
  sx,
  onBlur,
  onChange,
  onInputChange,
  textFieldRef,
  required,
  onKeyPressRef,
  errorMessage,
  inputSx,
  textFieldStyle,
  placeholder,
  name,
  boxStyle,
  otherType,
  isOnChange,
  handleCountryChange,
  ...params
}) {
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const otherTypeRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { errors, touched, values, setFieldValue } =
    useFormikContext();
  const error = get(errors, name);
  const fieldTouched = get(touched, name);
  const value = get(values, name);

  const onValueChange = (e, value) => {
    if (value?.value === otherType) {
      setIsOtherSelected(true);
      setTimeout(() => {
        setFieldValue(name, "");
        if (otherTypeRef.current) {
          otherTypeRef.current.value = "";
        }
      }, 0);
    } else {
      setFieldValue(name, value?.value);
      setIsOtherSelected(false);
    }
    if (isOnChange) {
      onChange(e, value);
    }
  };
  const closeDropdown = () => setOpen(false);
  const openDropdown = () => setOpen(true);

  const defValue = value ? options.find((item) => item.value === value) : "";
  return (
    <>
      <Box sx={{ ...BoxStyle, ...boxStyle }}>
        <Autocomplete
          freeSolo={isOtherSelected}
          id={id}
          {...params}
          name={name}
          open={open}
          onOpen={openDropdown}
          onClose={closeDropdown}
          options={options}
          value={otherType ? value : defValue}
          disableClearable
          onChange={onValueChange}
          noOptionsText={isOtherSelected ? "" : "No items found"}
          onInputChange={(event, newInputValue) => {
            if (otherType && (otherType === newInputValue || isOtherSelected)) {
              setFieldValue(name, newInputValue);
            }
            onInputChange && onInputChange(event, newInputValue);
          }}
          sx={{
            // padding: "0px",
            "& .MuiInputBase-root": {
              padding: "0px",
              paddingLeft: "10px",
              color: "black",
              height: '28px'
            },
            "& .MuiAutocomplete-root": {
              "& .MuiOutlinedInput-root": {
                "& .MuiAutocomplete-input": {
                  padding: "0px"
                },
              },
            },
            "& .MuiAutocomplete-endAdornment":{
              right:"16px",
              zIndex: 1,
            },
            "& .MuiIconButton-root": {
              zIndex: 1,
              marginRight: "-10px",
            },
            "& .MuiButtonBase-root": {
              backgroundColor: "transparent !important",
            },
            "& .MuiOutlinedInput-input": {
              position: "relative",
              zIndex: 1,
              border:0,
              padding:"0 !important",
            },
            ...sx,
          }}
          renderInput={(params) => {
            const inputProps = params.inputProps;
            inputProps.autoComplete = "off";
            return (
              <TextField
                {...params}
                // labelId=""
                inputRef={otherTypeRef}
                label=""
                name={name}
                placeholder={isOtherSelected ? "Type new" : placeholder}
                onChange={(event) => {
                  const newValue = event.target.value;
                  const existingState = options.find(
                    (state) => state.value === newValue
                  );

                  // only force close dropdown when input is updated but is not on focus
                  if (document.activeElement !== otherTypeRef.current) {
                    if (event.target.name === "country") {
                      onChange(event, existingState);
                    }
                    // eslint-disable-next-line no-extra-boolean-cast
                    if (!!existingState) {
                      setFieldValue(name, existingState.value);
                    }

                    closeDropdown();
                  }
                }}
                onBlur={onBlur}
                error={fieldTouched && error}
                FormHelperTextProps={{
                  sx: {
                    position: "absolute",
                    bottom: "-18px",
                    left: "6px",
                  },
                }}
                sx={{
                  width: { sm: 145, md: 340, xs: 130, lg: 340 },
                  height: 30,
                  textAlign: "left",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  "& input:-webkit-autofill": {
                    WebkitBoxShadow: "0 0 0 100px #FFF inset !important",
                    height: "30px",
                    padding: "0px !important",
                  },
                  ...textFieldStyle,
                }}
              />
            );
          }}
        />
        
      </Box>
    </>
  );
}

export default AutoComplete;

const BoxStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--radium-info-600)",
    borderRadius: "6px",
    height: "38px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "#FFF",
  },
  "&:hover fieldset": {
    borderColor: "var(--radium-info-600)",
  },
  "& .MuiInputBase-root": {
    height: "38px",
    color: "black",
  },
  "& .MuiFormControl-root": {
    // padding: "0px 10px",
    height: "35px",
    color: "black",
    width: "100%",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--radium-info-600)",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "var(--radium-info-600)",
    },
  },
  "& .MuiSelect-select": {
    padding: "0px 10px",
    height: "38px",
    color: "black",
    alignItems: "center",
    justifyContent: "left",
    display: "flex",
  },
  "@media (max-width: 600px)": {
    width: "99%", 
  },
};

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isEqual } from "lodash";
import useSWR from "swr";

import "./GlobalItemList.css";
import { ReactComponent as ViewIcon } from "../../Assets/Images/viewIcon.svg";
import useAuth from "../../ContextProvider/AuthProvider";
import { filterAscDescData, formatDate, hexToRgba } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";
import PawDataView from "../Common/PawDataView.js";
import { ReactComponent as EditIcon } from "../../Assets/Images/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/DeleteIcon.svg";
import ApiService from "../../Api/ApiServices.js";
import Layout from "../Side bar/Layout.js";
import SurgeryandTreatmentForm from "../Forms/SurgeriesTreatmentsForm.js";
import { MainPageWrapper } from "./GlobalItemList.js";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function SurgeryandTreatment() {
  const { userId } = useAuth();
  const [filter, setFilter] = useState({});
  const [surgeryandTreatmentData, setSurgeryandTreatmentData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const fetchIdRef = useRef(0);
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    data = [],
    mutate: fetchAPIData,
    isLoading,
  } = useSWR(
    userId ? `SurgeryAndTreatment${userId}` : null,
    async () => {
      const { data } = await ApiService.getSurgeriesTreatments(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { sort } = filter;
    if (filter && filter.from > -1) {
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort);
      setSurgeryandTreatmentData(filteredResult);
    }
  }, [filter, data]);

  const handleView = (item) => {
    console.log("item: ", item);
    setIsShow(true);
    setSelectedData(item);
    setIsAdd(false);
    setIsEdit(false);
  };

  const addOnclick = () => {
    setIsAdd(true);
    setSelectedData({});
    setIsShow(false);
    setIsEdit(false);
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setSelectedData(item);
    setIsAdd(true);
    setIsShow(false);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.surgeriesTreatments.surgeryDate")}`,
        textAlign: "center",
        accessor: "surgery_date",
        width: "12.5vw",
        Cell: ({ value }) => {
          try {
            return formatDate(value);
          } catch (error) {
            // If an error occurs during parsing, return "-"
            return "-";
          }
        },
      },
      {
        Header: `${t("screens.surgeriesTreatments.surgeryProcedureType")}`,
        textAlign: "center",
        accessor: "surgery_type",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.prescriptionMedication.physicianName")}`,
        textAlign: "center",
        accessor: "physician_name",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.formData.view")}`,
        accessor: "view",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
             color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleView(row.original)}
            >
               <ViewIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.edit")}`,
        accessor: "edit",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
             color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleEdit(row.original)}
            >
              <EditIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.delete")}`,
        accessor: "delete",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
             color="info"
              sx={{
                minWidth: "40px",
                background: `${hexToRgba(theme.palette.info.main, 0.10)} !important`,
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => setAskConfirmation(row?.original)}
            >
              <DeleteIcon
                style={{
                  fill: `${theme.palette.info.main}`, 
                  cursor: "pointer",
                }}
                alt="view"
              />
            </Button>
          );
        },
      },
    ];
  }, [t]);



  console.log("surgeryandTreatmentData: ", surgeryandTreatmentData);
  const props = {
    label: `${t("screens.profile.surgeriesTreatments")}`,
    isLoading: isLoading,
    columns,
    columnsData: surgeryandTreatmentData,
    details: " There are no Surgeries & Treatments for this user.",
    addOnclick: addOnclick,
    fetchData,
  };

  const handleDelete = async () => {
    try {
      if (askConfirmation) {
        const { data } = await ApiService.deleteSurgeriesTreatments(
          userId,
          askConfirmation
        );
        if (data?.OK) {
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const viewProps = {
    label: `${t("screens.surgeriesTreatments.surgeriesTreatmentsInformation")}`,
    askConfirmation,
    setAskConfirmation,
    onDelete: handleDelete,
    isShow,
    detailsData: [
      [
        {
          name: `${t("screens.surgeriesTreatments.surgeryDate")}`,
          value: selectedData?.surgery_date
            ? formatDate(selectedData?.surgery_date)
            : "-",
        },
        {
          name: `${t("screens.surgeriesTreatments.surgeryProcedureType")}`,
          value: selectedData?.surgery_type || "_",
        },
        {
          name: `${t("screens.surgeriesTreatments.postComplication")}`,
          value: selectedData?.surgey_notes || "_",
        },
        {
          name: `${t("screens.surgeriesTreatments.IVCFilter")}`,
          value: selectedData?.ivc_filter ? "Yes" : "No",
        },
        {
          name: `${t("screens.surgeriesTreatments.IVCDate")}`,
          value: `${
            selectedData?.ivc_filter_cal
              ? formatDate(selectedData?.ivc_filter_cal)
              : "-"
          }`,
        },
        {
          name: `${t("screens.surgeriesTreatments.implantableDevice")}`,
          value: selectedData?.sergery_impan ? "Yes" : "N0",
        },
        {
          name: `${t("screens.surgeriesTreatments.implantableDevicesDate")}`,
          value: `${
            selectedData?.implan_cal
              ? formatDate(selectedData?.implan_cal)
              : "-"
          }`,
        },
        {
          name: `${t("screens.surgeriesTreatments.implantableDeviceNotes")}`,
          value: selectedData?.impan_notes || "_",
        },
        {
          name: `${t("screens.surgeriesTreatments.anthesiaComplication")}`,
          value: selectedData?.surgery_anes || "_",
        },
        {
          name: `${t("screens.surgeriesTreatments.chronicAnticogulation")}`,
          value: selectedData?.sergery_chronic ? "Yes" : "No",
        },
        {
          name: `${t(
            "screens.surgeriesTreatments.chronicAnticoagulationDate"
          )}`,
          value: `${
            selectedData?.chronic_cal
              ? formatDate(selectedData?.chronic_cal)
              : "-"
          }`,
        },
        {
          name: `${t("screens.surgeriesTreatments.HxHIT")}`,
          value: selectedData?.sergery_hxhit ? "Yes" : "No",
        },
        {
          name: `${t("screens.surgeriesTreatments.HxHITDate")}`,
          value: `${
            selectedData?.hxhit_cal ? formatDate(selectedData?.hxhit_cal) : "-"
          }`,
        },
        {
          name: `${t("screens.surgeriesTreatments.HxHITNote")}`,
          value: selectedData?.hxhit_notes || "_",
        },
      ],
    ],
  };

  return (
    <Layout>
      <MainPageWrapper>
        <PawItemList {...props} />
        <PawDataView {...viewProps} />
        {isAdd && (
          <SurgeryandTreatmentForm
            setIsAdd={setIsAdd}
            isEdit={isEdit}
            data={selectedData}
            fetchAPIData={fetchAPIData}
          />
        )}
      </MainPageWrapper>
    </Layout>
  );
}

import * as React from 'react';
import styled from "styled-components";

function DomainNotFound() {
  return <>
    <Main>
      <div>
        <img alt="" src="/assets/images/contentNotFound.png" />
      </div>
      <h3><b>Domain Not Found</b></h3>
      </Main>
  </>
}
export default DomainNotFound;
const Main = styled.div`
     min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
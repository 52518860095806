import React, { useEffect, useCallback } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";

import InputField from "../Common/InputField";
import FormGroup from "../Common/FormGroup";
import SelectField from "../Common/AutoComplete";
import { usStates } from "./constant";
import { Body, Check, Column, Header } from "../../Css";
import PhoneInput from "../Common/PhoneInput";
import { useTheme } from "@mui/material";
import { hexToRgba } from "../../Frontend/Utils";

const Wrapper = styled.div`
  font-family: sans-serif;
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--text-primary-800);
`;

const Country = styled.div`
  .react-tel-input .form-control {
    border-radius: 5px;
    border: 1px solid gray;
    height: 41px !important;
    width: 100%;
    background: white !important;
  }

  .react-tel-input .flag-dropdown {
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: transparent !important;
    border-radius: 3px 0 0 3px;
  }

  input:-internal-autofill-selected {
    background-image: none !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: fieldtext !important;
  }
     .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background: transparent !important;
    }
`;

function ShippingInformation({
  onPrevious,
  setInitialValue,
  setValueinFormData,
}) {
  const { getFieldProps, values, touched, setFieldValue } = useFormikContext();
  console.log("values: ", values);
  const theme = useTheme();

  useEffect(() => {
    touched.fName = false;
    touched.lName = false;
    touched.address_line_1 = false;
    touched.admin_area_2 = false;
    touched.billing_postal_code = false;
    touched.admin_area_1 = false;
  }, []);

  const handleCheck = useCallback(() => {
    if (!values.infoChecked) {
      setInitialValue({
        ...values,
        fName: values?.firstnameB,
        lName: values?.lastnameB,
        address_line_1: values?.streetAddB,
        address_line_2: values?.addressB,
        admin_area_2: values?.CityB,
        billing_postal_code: values?.zipcodeB,
        admin_area_1: values?.stateB,
        infoChecked: true,
        shipping_phone_number: values?.billing_phone_number,
      });
    } else {
      setInitialValue({
        ...values,
        fName: "",
        lName: "",
        address_line_1: "",
        address_line_2: "",
        admin_area_2: "",
        billing_postal_code: "",
        admin_area_1: "",
        infoChecked: false,
        shipping_phone_code: "",
      });
    }
  }, [values, setInitialValue]);

  useEffect(() => {
    setValueinFormData();
  }, []);

  return (
    <div>
      <Wrapper>
        <Header style={{backgroundColor: hexToRgba(theme.palette.info.main,0.10)}}>Shipping Information</Header>
        <Check>
          <input
            type="checkbox"
            onChange={handleCheck}
            checked={values.infoChecked}
          ></input>
          Same as Billing address?
        </Check>
        <Body>
          <Column>
            <FormGroup
              label="First Name"
              name="fName"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="fName"
                {...getFieldProps("fName")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Last Name"
              name="lName"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="lName"
                {...getFieldProps("lName")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Street address"
              name="address_line_1"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="address_line_1"
                {...getFieldProps("address_line_1")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Address 2"
              name="address_line_2"
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="address_line_2"
                {...getFieldProps("address_line_2")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="City"
              name="admin_area_2"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="admin_area_2"
                {...getFieldProps("admin_area_2")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="State / Province / Region"
              name="admin_area_1"
              labelClassName="margin-top"
            >
              <SelectField
                {...getFieldProps("admin_area_1")}
                name="admin_area_1"
                options={usStates}
                placeholder="Select State"
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Zip Code"
              name="billing_postal_code"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="billing_postal_code"
                {...getFieldProps("billing_postal_code")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Phone Number"
              name="shipping_phone_number"
              requiredField
              labelClassName="margin-top"
            >
              <Country>
                <PhoneInput
                  dialCodeName="shipping_phone_code"
                  name="shipping_phone_number"
                  onChange={(value, country) => {
                    setFieldValue("shipping_phone_code", country.dialCode);
                  }}
                />
              </Country>
            </FormGroup>
          </Column>
        </Body>
        <div className="shipping-btn">
          <button className="btn btn-primary" onClick={onPrevious}>
            Back
          </button>
          <button className="btn btn-primary" type="next">
            Next
          </button>
        </div>
      </Wrapper>
    </div>
  );
}

export default ShippingInformation;

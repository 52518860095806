import React, { useEffect, useMemo } from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../Registration/Register.css";
import { Form, Formik } from "formik";
import { RegisterValidation } from "../../From/Validation";
import NewAccountRegister from "./NewAccountRagister";
import BillingInformation from "../../Components/register/BilingInformation";
import PaymentInfo from "../../Components/register/paymentinfo";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import ApiService from "../../Frontend/Api/ApiServices";

function Register() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef();
  const formData = useRef();

  const [steps, setSteps] = useState(0);

  const stripe = useStripe();
  const elements = useElements();
  // const { stripe, elements } = useStripe();
  const defaultValue = {
    firstname: "",
    email: "",
    password: "",
    phoneNumber: "",
    lastname: "",
    confirmpassword: "",
    code: "",
    firstnameB: "",
    streetAddB: "",
    CityB: "",
    zipcodeB: "",
    lastnameB: "",
    stateB: "",
    card: "",
    expiry: "",
    securityCode: "",
    fName: "",
    lName: "",
    address_line_1: "",
    address_line_2: "",
    admin_area_2: "",
    billing_postal_code: "",
    admin_area_1: "",
    nfoChecked: false,
    cardnumber: "",
    hasMembershipNumber: false,
    membershipNumber: "",
    isValidMembershipNumber: false,
    numberOfMembers: "1",
    isBuyMembership: false,
    isAlreadyMembership: false,
    totalAmmount: 40,
    accessCode: "",
    shipping_phone_number: "",
    billing_phone_number: "",
    isAlreadyMemSubmitted: false,
  };
  const [initialValue, setInitialValue] = useState(defaultValue);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const registerUser = async (values, paymentMethod) => {
    try {
      const newUser = {
        account: {
          user_fname: values?.firstname,
          user_lname: values?.lastname,
          user_email: values?.email,
          user_password: values?.password,
          members: values?.numberOfMembers,
          couponCode: values?.referralCode,
        },
        shippingInfo: {
          firstName: values?.fName,
          lastName: values?.lName,
          address1: values?.address_line_1,
          address2: values?.address_line_2,
          city: values?.admin_area_2,
          state: values?.admin_area_1,
          zipCode: values?.billing_postal_code,
          phoneNumber: values?.shipping_phone_number,
        },
        billingInfo: {
          expDateMonth: "",
          expDateYear: "",
          address1: values?.streetAddB,
          address2: values?.addressB,
          city: values?.CityB,
          firstName: values?.firstnameB,
          lastName: values?.lastnameB,
          phoneNumber: values?.billing_phone_number,
          state: values?.stateB,
          zipCode: values?.zipcodeB,
        },
        paymentMethod: paymentMethod?.paymentMethod,
      };
      const res = await ApiService.userRegister(newUser);
      console.log("res: ", res);
      if (res?.data?.err) {
        swal("Error!", res?.data?.err, "error");
        return;
      }
      swal({
        title: "Account successfully created!",
        text: "You need to verify email address. Check your email!",
        icon: "success",
      }).then(() => {
        navigate("/members");
      });
    } catch (err) {
      if (err.code === "ERR_NETWORK") {
        swal("Network Error!", "Failed to connect server.", "error");
      } else if (err?.response?.data?.code === 500) {
        swal("Network Error!", err?.response?.data?.message, "error");
      }
    }
  };
  const handleSubmit = async () => {
    setSteps(steps + 1);
    window.scrollTo(0, 580);
  };

  useEffect(() => {
    return () => {
      if (!isFormSubmitted) {
        const data = {
          ...formData?.current?.values,
        };
        delete data?.password;
        delete data?.confirmpassword;
        ReactGA.gtag("event", "new_user_checkout", {
          ...data,
          status: "NotSubmitted",
        });
      }
    };
  }, []);

  const setValueinFormData = () => {
    if (formRef?.current) {
      formData.current = formRef?.current;
    }
  };

  const onSubmit = async (values, { setFieldError }) => {
    if (steps === 0) {
      handleSubmit(values, { setFieldError });
    } else if (steps === 2) {
      try {
        if (values?.hasMembershipNumber && values?.isValidMembershipNumber) {
          await registerUser(values, "", { setFieldError });
        } else {
          if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            alert("NOT LOADED");
            return;
          }
          const cardNumberElement = elements.getElement(CardNumberElement);
          // const result = await stripe.createToken(cardNumberElement);
          const result = await stripe.createPaymentMethod({
            type: "card",
            card: cardNumberElement,
            billing_details: {
              name: "",
            },
          });
          await registerUser(values, result, { setFieldError });
        }
      } catch (err) {
        console.error(err, err.message);
      }
    } else {
      setSteps(steps + 1);
    }
  };

  const getStatedItem = useMemo(() => {
    switch (steps) {
      case 0:
        return (
          <NewAccountRegister
            setInitialValue={setInitialValue}
            setValueinFormData={setValueinFormData}
            onNext={handleSubmit}
          />
        );
      case 1:
        return (
          <BillingInformation
            onPrevious={() => setSteps(steps - 1)}
            setInitialValue={setInitialValue}
            setValueinFormData={setValueinFormData}
          />
        );
      case 2:
        return (
          <PaymentInfo
            onPrevious={() => setSteps(steps - 1)}
            setValueinFormData={setValueinFormData}
          />
        );
      default:
        return <></>;
    }
  }, [steps]);

  return (
    <Wrapper>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={RegisterValidation[steps]}
        innerRef={formRef}
        validateOnChange={false}
        validateOnBlur={true}
      >
        <Form>{getStatedItem}</Form>
      </Formik>
    </Wrapper>
  );
}

export default Register;

const Wrapper = styled.div`
  font-family: sans-serif;
  padding: 20px 0 100px 0px;
  text-align: center;
  min-height: 63vh;
  @media (max-width: 650px) {
    // padding: 140px 0 100px 0px;
  }
`;

import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

// account
export const LoginAccountValidation = Yup.object({
  username: Yup.string()
    .email(`Invalid User format`)
    .required("User is required"),
  password: Yup.string().required("Invalid Password"),
});

export const ErLoginAccountValidation = Yup.object({
  // username: Yup.string()
  //   // .email(`Please enter membership number`)
  //   // .required("Please enter membership number"),
  // password: Yup.string().required("Please enter access code"),
});

// register
export const RegisterValidation = [
  Yup.object({
    firstname: Yup.string().required("Please enter first name"),
    lastname: Yup.string().required("Please enter lastname "),
    email: Yup.string()
      .email("Invalid format")
      .required("Please enter email address"),
    password: Yup.string()
      .min(6, "Password should be at least 6 characters long")
      .minLowercase(1, "Password should contain at least one lowercase letter")
      .minUppercase(1, "Password should contain at least one uppercase letter")
      .minNumbers(1, "Password should contain at least one number")
      .minSymbols(1, "Password should contain at least one special character")
      .required("Please enter password"),
    confirmpassword: Yup.string()
      .required("Please enter your confirm password")
      .min(6, "Password must be a minimum length of 6 characters")
      .oneOf([Yup.ref("password"), ""], "Password must match"),
  }),
  Yup.object({
    firstnameB: Yup.string().required("Please enter first name"),
    streetAddB: Yup.string().required("Please enter street address"),
    CityB: Yup.string().required("Please enter city"),
    zipcodeB: Yup.string().required("Please enter zipcode"),
    lastnameB: Yup.string().required("Please enter last name"),
    stateB: Yup.string().required(" Please enter street address"),
    fName: Yup.string().required("Please enter first name"),
    address_line_1: Yup.string().required("Please enter address 1"),
    admin_area_2: Yup.string().required("Please enter city"),
    billing_postal_code: Yup.string().required("Please enter zipcode"),
    lName: Yup.string().required("Please enter last name"),
    admin_area_1: Yup.string().required(
      "Please enter state / province / region"
    ),
    shipping_phone_number: Yup.string()
      .trim()
      .required("Please enter phone number")
      .min(10, "Please enter valid phone number"),
    billing_phone_number: Yup.string()
      .trim()
      .required("Please enter phone number")
      .min(10, "Please enter valid phone number"),
  }),
  Yup.object({}),
];

// Payment
export const PaymentValidation = Yup.object({
  firstnameB: Yup.string().required("Please enter first name"),
  streetAddB: Yup.string().required("Please enter street address"),
  CityB: Yup.string().required("Please enter city"),
  zipcodeB: Yup.string().required("Please enter zipcode"),
  lastnameB: Yup.string().required("Please enter last name"),
  stateB: Yup.string().required(" Please enter street address"),
});
export const ConditionsForm = Yup.object({});
export const conditionsValidation = Yup.object({});
export const personalInfoFormValidation = Yup.object({});
export const allergiesValidation = Yup.object({});

export const ChangePasswordFormValidation = Yup.object().shape({
  user_fname: Yup.string().trim().required("screens.errorMessages.firstName"),
  user_lname: Yup.string().trim().required("screens.errorMessages.lastName"),
  oldPwd: Yup.string()
    .required("screens.errorMessages.oldPassword")
    .min(8, "screens.errorMessages.minPassword"),
  newPwd: Yup.string()
    .min(8, "screens.errorMessages.minPassword")
    .matches(/[a-z]/, "screens.errorMessages.loweCasePassword")
    .matches(/[A-Z]/, "screens.errorMessages.upperCasePassword")
    .matches(/\d/, "screens.errorMessages.numberPassword")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "screens.errorMessages.specialCharPassword"
    )
    .required("screens.errorMessages.newPassword"),
  con_newpwd: Yup.string()
    .required("screens.errorMessages.confirmPassword")
    .oneOf([Yup.ref("newPwd"), ""], "screens.errorMessages.matchPassword"),
});

export const updateShippingInfoValidation = Yup.object({
  firstName: Yup.string().required("screens.errorMessages.firstName"),
  lastName: Yup.string().required("screens.errorMessages.lastName"),
  address1: Yup.string().required("screens.errorMessages.streetAddress"),
  city: Yup.string().required("screens.errorMessages.city"),
  zipCode: Yup.string().required("screens.errorMessages.zipCode"),
  state: Yup.string().required("screens.errorMessages.state"),
  phoneNumber: Yup.string().required("screens.errorMessages.phoneNumber"),
});

export const BuyAdditionalMembershipValidation = [
  Yup.object({}),
  Yup.object({
    firstNameB: Yup.string().required("screens.errorMessages.firstName"),
    streetAddB: Yup.string().required("screens.errorMessages.streetAddress"),
    CityB: Yup.string().required("screens.errorMessages.city"),
    zipCodeB: Yup.string().required("screens.errorMessages.zipCode"),
    lastNameB: Yup.string().required("screens.errorMessages.lastName"),
    stateB: Yup.string().required("screens.errorMessages.stateProvinceRegion"),
    fName: Yup.string().required("screens.errorMessages.firstName"),
    address_line_1: Yup.string().required(
      "screens.errorMessages.streetAddress"
    ),
    admin_area_2: Yup.string().required("screens.errorMessages.city"),
    billing_postal_code: Yup.string().required("screens.errorMessages.zipCode"),
    lName: Yup.string().required("screens.errorMessages.lastName"),
    admin_area_1: Yup.string().required(
      "screens.errorMessages.stateProvinceRegion"
    ),
    shipping_phone_number: Yup.string().required(
      "screens.errorMessages.phoneNumber"
    ),
    billing_phone_number: Yup.string().required(
      "screens.errorMessages.phoneNumber"
    ),
  }),
  Yup.object({}),
];

export const SetPasswordValidation = Yup.object({
  pwd: Yup.string()
    .min(8, "Password must be atleast 8 characters")
    .matches(/[a-z]/, "Password should contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password should contain at least one uppercase letter")
    .matches(/\d/, "Password should contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password should contain at least one special character"
    )
    .required("Please enter new password "),
  conf_pwd: Yup.string()
    .required("Please enter confirm password")
    .oneOf([Yup.ref("pwd"), ""], "Password does not match"),
});

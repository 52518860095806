import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HomeSection3() {
  const images = [
    "assets/images/generali.png",
    "assets/images/tripMateLogo2.png",
    "assets/images/healthee.png",
    "assets/images/Legado.svg",
    "assets/images/healthyHabits.png",
    "assets/images/crunchBase.png",
  ];

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="swiper-button-prev"
        ng-click="prevSlide()"
        onClick={onClick}
      ></div>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="swiper-button-next"
        ng-click="nextSlide()"
        onClick={onClick}
      ></div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 565,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="organization-section">
      <div className="organization-section-block">
        <h2 className="organization-title">You're In Good Company</h2>
        <p className="organization-dec">
          These organizations provide FootprintID® and peace of mind.
        </p>
        <div className="organization-img-section">
          <Slider {...settings}>
            {images.map((image, i) => (
              <div key={i} className="image-section">
                <img alt="" className="img-fluid" src={image} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
